<template>
  <div class="entire-div">
    <register-form></register-form>
  </div>
</template>

<script>
import RegisterForm from './Profile/RegisterForm';
import navbar from '../components/Navbar';
import { BarLoader, ClipLoader } from '@saeris/vue-spinners';
import axios from 'axios';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { constants } from 'crypto';
import Multiselect from 'vue-multiselect';
import api from '../api';
import internetAvailable from 'internet-available';
import isOnline from 'is-online';

import Footer from '../components/Footer';

export default {
  components: {
    RegisterForm,
    Multiselect,
    navbar,
    Footer,
  },

  name: 'Login',
  data() {
    return {
      senha: '',
      email: '',
      items: [],
      destino: '',
      origem: '',
      optionsOrigem: [],
      optionsDestino: [],
      loadMore: true,
      usuario: '',

      disabled: false,

      loading: false,
      color: 'green',
      px: '300px',
    };
  },

  mounted() {
  },

  methods: {
    teste() {
      if (this.email && this.senha) {
        this.disabled = true;

        this.loading = true;

        const payload = {
          usuario: this.email,
          senha: this.senha,
        };

        let config = {
          headers: {
            token: this.$store.state.session.token,
            'Content-Type': 'application/json',
          },
        };
        const abortController = new AbortController();
        const signal = abortController.signal;
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const body = {
          usuario: this.email,
          senha: this.senha,
        };

        this.axios
          .post(`${api}/usuarios/login`, body, myHeaders, signal)
          .then((response) => {
            if (response.data.token) {

              const config2 = {
                headers: {
                  Authorization: `Bearer ${response.data.token}`,
                },
              };

              this.axios
                .get(`${api}/usuarios/perfil`, config2)
                .then((response) => {

                  var payloadInside = {
                    id: response.data.pessoa.id,
                    usuario: response.data.pessoa.nome,
                    token: response.data.token,
                  };

                  this.$store.dispatch('login', payloadInside);

                  this.$notify({
                    message: 'Usuário logado!',
                    type: 'success',
                    timeout: 2000,
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    showClose: false,
                    closeOnClick: false,
                  });
                  this.$router.push('/');
                });
            } else {
              this.$notify({
                message: 'Usuário logado!',
                type: 'success',
                timeout: 2000,
                horizontalAlign: 'center',
                verticalAlign: 'top',
                showClose: false,
                closeOnClick: false,
              });
            }
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: 'danger',
              timeout: 2000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false,
            });
          })
          .finally(() => {
            this.disabled = false;

            this.$router.push('/login');

            this.loading = false;
          });
      } else {
        this.$notify({
          message: 'Os campos são obrigatórios!',
          type: 'danger',
          timeout: 2000,
          horizontalAlign: 'center',
          verticalAlign: 'top',
          showClose: false,
          closeOnClick: false,
        });
      }
    },
    detalhes() {
      if (this.$store.state.session.auth == true) {
        this.$router.push('/');
      } else {
        this.$router.push('/login');
      }
    },

    buscaViagens() {
      this.axios
        .get(
          `${api}/viagens/busca/?cidade_origem=${this.origem}&cidade_destino=${this.destino}`
        )
        .then((response) => {
          response.data.map((res) => {
            this.items.push(res);
          });
          //  this.items.push(response.data)
        });
    },
  },
  login() {
    if (this.email && this.senha) {
      this.disabled = true;

      this.loading = true;

      const payload = {
        usuario: this.email,
        senha: this.senha,
      };

      let config = {
        headers: { token: this.$store.state.session.token },
      };
      //   fetch(`${baseURL}/usuarios/login`, { method: 'POST', body: body, headers: myHeaders, signal: signal })

      this.axios
        .post(`${baseURL}/usuario/login`, payload, config)
        .then((response) => {
          if (response.data.auth) {
            this.$store.state.session.auth = response.data.auth;
            this.$store.state.session.token = response.data.token;

            var payloadInside = {
              id: response.data.clinicData.ID,
              usuario: response.data.clinicData.USERNAME,
              token: response.data.token,
            };

            this.$store.dispatch('login', payloadInside);

            this.$notify({
              message: 'Usuário logado!',
              type: 'success',
              timeout: 2000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false,
            });

            let config = {
              headers: { token: response.data.token },
            };

            axios
              .get('/funcionario', config)
              .then((response) => {
                response.data.forEach((funcionario) => {
                  if (funcionario.USUARIO == this.usuario) {
                    this.$store.state.session.nome =
                      funcionario.NOME + ' ' + funcionario.SOBRENOME;
                  }
                });
              })
              .catch((error) => {
              })
              .finally(() => {
                this.$router.push('/guiaexterna');
              });
          } else {
            this.$notify({
              message: 'Usuário logado!',
              type: 'success',
              timeout: 2000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message,
            type: 'danger',
            timeout: 2000,
            horizontalAlign: 'center',
            verticalAlign: 'top',
            showClose: false,
            closeOnClick: false,
          });
        })
        .finally(() => {
          this.disabled = false;

          this.$router.push('/guiaexterna');

          this.loading = false;
        });
    } else {
      this.$notify({
        message: 'Os campos são obrigatórios!',
        type: 'danger',
        timeout: 2000,
        horizontalAlign: 'center',
        verticalAlign: 'top',
        showClose: false,
        closeOnClick: false,
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.entire-div {
  position: relative;
  margin-top: 54px;
  background-color: #2a3550  !important;
  width: 100%;
  height: 100%;
  min-height: 92.2vh;
  // padding-bottom: 7vh; /* Footer height */
  display: block;
  justify-content: center;
  z-index: 0;
}
</style>
