<template>
  <div class="entire-div">
    <p class="header">Dúvidas Frequentes</p>
    <div class="central-div">
      <p class="question" v-onclick="">Como faço para comprar passagem?</p>
      <p class="answer">
        Em nossa plataforma, faça a busca das opções disponíveis informando o
        local de origem/destino e data da viagem. Selecione a opção desejada do
        tipo de embarcação e categoria de passagem. Se ainda não possuir
        cadastro no site é necessário preencher o formulário completo para
        efetuar a primeira compra. Informe os dados do(s) passageiro(s) e os
        dados do cartão de crédito/débito e boleto para finalizar. Aguarde
        resposta do processamento e se for aprovado pela operadora será enviada
        uma mensagem de “compra realizada com sucesso”, com informações do
        pedido e logo em seguida imprima seu ticket/voucher.
      </p>

      <p class="question">Sou menor de idade, posso comprar passagem online?</p>
      <p class="answer">
        As compras online são permitidas somente para maiores de 18 anos. Caso
        seja necessário a compra para menores de idade, é obrigatório que seja
        feito em conjunto com alguma outra compra de passagem de adulto. Para
        mais informações sobre viagem de menores desacompanhados dirija-se ao
        local da embarcação e procure pela bilheteria.
      </p>

      <p class="question">
        Quais as formas de pagamento aceitas para compra de passagem pelo site?
      </p>
      <p class="answer">
        A compra online pode ser feita com cartão de crédito ou via boleto
        bancário até 3 dias antes da partida da embarcação, para compensação e
        baixa do boleto junto ao estabelecimento bancário.
      </p>

      <p class="question">Quantas passagens posso comprar de cada vez?</p>
      <p class="answer">
        Cada cliente cadastrado tem direito a comprar 8 passagens via site para
        o mesmo trecho selecionado, podendo ser de uma única vez ou em partes.
      </p>

      <p class="question">Posso usar cartão de crédito de outra pessoa?</p>
      <p class="answer">
        É fortemente recomendado que a compra de passagem seja feita com o
        próprio cartão do cliente cadastrado no site, para evitar problema no
        processamento da compra (não autorização) pela operadora do cartão, uma
        vez que todo processo de compra passa por um sistema de análise de
        fraude complexo. Caso tenha sucesso na compra, é necessário que seja
        apresentado o documento válido com foto e o cartão de crédito do titular
        durante o embarque.
      </p>

      <p class="question">É possível remarcar minha passagem?</p>
      <p class="answer">
        Sim, é possível. Esse procedimento pode ser feito nos pontos de venda
        autorizados da NAVEGAM ou diretamente na bilheteria da agência
        responsável pela embarcação em até no máximo 24h antes do embarque. Pode
        ocorrer diferença de valor a ser pago caso haja mudança na categoria da
        passagem. Também não há garantia de remarcação para o mesmo local da
        passagem atual, pois fica sujeito a disponibilidade de vagas na nova
        embarcação. O prazo de remarcação é de até 90 dias após a data de
        embarque.
      </p>

      <p class="question">Como faço para cancelar minha compra?</p>
      <p class="answer">
        Conforme estabelecido por lei, o consumidor possui o direito de
        arrependimento no prazo de 07 dias em caso de compra de passagem pela
        internet, haja visto ser efetuada fora do estabelecimento comercial.
        Este prazo deve ser, obrigatoriamente, até 24h antes da data e horário
        de partida da embarcação. Para o caso de já ter ocorrido o embarque, o
        cancelamento poderá ser feito mediante desconto de 70% do valor pago
        para encargos administrativos e ‘no show’. O consumidor poderá fazer a
        remarcação sem custos ou desconto até o prazo de 90 dias após a data de
        embarque. Para a passagem comprada online ou nos pontos de venda da
        NAVEGAM, não haverá devolução da tarifa de serviço.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}
.btn2-central2 {
  /* // margin-right: 8px; */
  background-color: #009aa0;
  border: none;
  outline: none;
  padding: 8px 12px;
  color: white;
  border-radius: 4px;
  width: 150px;
  position: absolute;
  left: 42%;
  cursor: pointer;

  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:active {
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
}

.central-div {
  margin: 0 auto;
  /* margin-left: 2rem; */
  width: 75%;
  padding: 1rem;
  border-radius: 6px;
}
.header {
  text-align: center;
  color: #2a3550;
  margin-top: 15vh;
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.question {
  color: #2a3550;
  margin-top: 6vh;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-bottom: 2px solid #2a3550;
  padding: 5px;
}
.answer {
  /* display: none; */
  /* position: fix; */
  letter-spacing: 0.8px;
  line-height: 1.5rem;
}
p {
  margin-top: 1rem;
  text-align: justify;
  /* text-indent: 2em; */
  line-height: 1.2em;
  font-family: "Roboto", sans-serif;
  color: #626262;
}
.entire-div {
  height: 100%;
  min-height: 83vh;
  padding-bottom: 9vh;
  background-color: #e5e5e5;
}
</style>
