<template>
  <div class="entire2-div">
    <h1 class="section-title page-title">RESUMO DA NAVEGAM</h1>
    <div class="central2-div">
      <p class="text">
        A plataforma da Navegam é um portal de vendas de
        <span style="font-weight: bold">passagens fluviais</span> na Amâzonia!
        Atualmente oferecemos viagens com destinos aos municipios do estado do
        Amazonas e, aos poucos, vamos expandir para outros estados da Região
        Norte do País.
      </p>
    </div>
    <section class="video-container">
      <iframe
        style="align-self: center"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/TzARqovZQ9M"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <span class="video-description">
        NAVEGAM - Soluções em Mobilidade na Amazônia
      </span>
    </section>
    <!-- <div class="blue-space" /> -->
    <h1 class="section-title" style="background-color: #2a3550">OBJETIVO</h1>
    <p class="text">
      A Navegam busca trazer tecnologia e inovação para o transporte fluvial.
      Nosso objetivo é que você, viajante, tenha:
    </p>
    <section class="cards">
      <div class="card-container">
        <img :src="IconComodidade" alt="" />
        <h2 class="card-title">COMODIDADE</h2>
        <p>
          Com a Navegam agora você pode comprar a sua passagem de barco sem sair
          de casa! Através do nosso site ou WhatsApp.
        </p>
      </div>
      <div class="card-container">
        <img :src="IconSeguranca" alt="" />
        <h2 class="card-title">SEGURANÇA</h2>
        <p>
          Compre agora a sua passagem de barco com muito mais segurança e ainda
          poderá contar com o nosso suporte.
        </p>
      </div>
      <div class="card-container">
        <img :src="IconAgilidade" alt="" />
        <h2 class="card-title">AGILIDADE</h2>
        <p>
          Agora você nao precisa ficar esperando na fila para adquirir a sua
          passagem. Além de seu embarque também ser bem mais rápido!
        </p>
      </div>
    </section>
    <p class="text after-card-section">
      Queremos levar o porto cada vez mais pertinho de você, e te apresentar as
      novas formas de comprar passagens de barco!
    </p>
    <h1 class="section-title" style="background-color: #009aa0">
      A NAVEGAM PRESENTE
    </h1>
    <section>
      <div class="presences-container">
        <div class="presences">
          <img :src="LogoCreativeStartups" />
          <div class="presences-text">
            <p>A NAVEGAM esteve presente na SAMSUNG CREATIVE STARTUPS</p>
            <p>Realizado em 2019</p>
            <a
              href="https://news.samsung.com/br/samsung-creative-startups-divulga-12-startups-selecionadas-para-a-4a-rodada-do-programa-de-aceleracao"
              target="_blank"
              ><button class="saiba-mais" type="submit">SAIBA MAIS</button></a
            >
          </div>
        </div>
        <div class="presences">
          <img :src="LogoFeiraDigital" />
          <div class="presences-text">
            <p>
              A Startup também participou da 2ª feira do Polo Digital de Manaus.
            </p>
            <p>Realizado em 2019</p>
            <a
              href="https://cieam.com.br/2-feira-polo-digital-de-manaus"
              target="_blank"
              ><button class="saiba-mais">SAIBA MAIS</button></a
            >
          </div>
        </div>
      </div>
    </section>
    <h1 class="section-title">NOSSOS PARCEIROS</h1>
    <section class="partners">
      <a href="http://www.gruporedeamazonica.com.br/" target="_blank"
        ><img :src="LogoRedeAmazonica"
      /></a>
      <a href="https://ppa.org.br/" target="_blank"><img :src="LogoPPA" /></a>
    </section>
  </div>
</template>
<script>
// importação das imagens
import LogoCreativeStartups from "../assets/img/logo-creative-startups.jpg";
import LogoFeiraDigital from "../assets/img/logo-feira-polo-digital.png";
import LogoRedeAmazonica from "../assets/img/logo-rede-amazonica.png";
import LogoPPA from "../assets/img/logo-ppa.png";

// importação dos ícones
import IconComodidade from "../assets/icons/icon-comodidade.svg";
import IconSeguranca from "../assets/icons/icon-seguranca.svg";
import IconAgilidade from "../assets/icons/icon-agilidade.svg";

// importação do vídeo
import VinhetaNavegam from "../assets/videos/vinheta-navegam.mp4";

export default {
  data() {
    return {
      // images
      LogoCreativeStartups,
      LogoFeiraDigital,
      LogoRedeAmazonica,
      LogoPPA,
      // icons
      IconComodidade,
      IconSeguranca,
      IconAgilidade,
      // video
      VinhetaNavegam
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.entire2-div {
  height: 100%;
  min-height: 71vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  /* padding-bottom: 7vh; */
}

.page-title {
  margin-top: 15vh !important;
}

.central2-div {
  margin: 0 auto;
  /* margin-left: 2rem; */
  width: 800px;
  background-color: transparent;
  padding: 1rem;
  border-radius: 6px;
}
.section-title {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 20px;
  width: 40%;
  padding: 0.75rem;
  background-color: #2a3550;

  letter-spacing: 1px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;

  &:nth-child(2n + 1) {
    background-color: #009aa0;
  }
}
.text {
  margin: 0 auto;
  text-align: justify;
  text-indent: 2em;
  line-height: 1.2em;
  color: #626262;
  font-family: "Roboto", sans-serif;
}

.video-container {
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .video-description {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;

    z-index: 1;
    margin-top: 10px;
  }
}

.blue-space {
  background-color: #5e98a6;
  width: 100vw;
  height: 300px;
  margin-top: -250px;
}

.cards {
  margin-top: 30px;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 40px;

  p.text {
    margin-bottom: 30px;
  }

  .card-container {
    font-family: "Roboto", sans-serif;
    color: #37415d;
    width: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    background-color: #e8e8e8;

    > img {
      width: 100px;
    }
    h2 {
      font-weight: 700;
      font-size: 1.2rem;
    }
    p {
      margin-top: 15px;
      line-height: 20px;
      font-weight: 500;
      text-align: justify;
      white-space: wrap;
    }
  }
}

p.text.after-card-section {
  margin: 30px auto;
}

.presences-container {
  width: 50%;
  margin: 0 auto;

  .presences {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    margin-bottom: 50px;

    img {
      width: 300px;
      box-shadow: 0px 5px 7px 3px #00000040;
    }
    p {
      width: 250px;
      color: #1b2035;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      line-height: 20px;

      margin-bottom: 20px;
    }
    button {
      background-color: #313f61;
      border: none;
      outline: none;
      padding: 8px 12px;
      color: white;
      border-radius: 4px;
      width: 150px;
      left: 42%;
      cursor: pointer;

      &:hover {
        background-color: rgba(49, 63, 97, 0.9);
      }
      &:active {
        transform: translate(0px, 1px);
        transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
      }
    }
  }
}

.btn2-central2 {
  /* margin-right: 8px; */
  background-color: #009aa0;
  border: none;
  outline: none;
  padding: 8px 12px;
  color: white;
  border-radius: 4px;
  width: 150px;
  position: absolute;
  left: 45%;
  cursor: pointer;

  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:active {
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
}

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  margin-bottom: 10vh;

  img {
    width: 250px;
  }
}
</style>