<template>
  <div id="app">
    <google-map />
  </div>
</template>

<script>
import GoogleMap from '../components/GoogleMap';

export default {
  name: 'App',
  components: {
    GoogleMap
  }
};
</script>
<style scoped>
#app {
  height: 100%;
}
</style>