<template>
  <div class="entireMap" id="mapinit">
    <p class="text-header">
      Escolha uma embarcação e clique para ver suas passagens disponíveis.
    </p>
    <div class="div-aux">
      <div class="main-panel-ship">
        <div style="position: relative" class="div-search">
          <div class="form__group field">
            <input
              @change="searchShip()"
              v-model="searchShip2"
              type="input"
              class="form__field"
              placeholder="Pesquisar embarcações"
              name="name"
              id="name"
              required
            />
            <label for="name" class="form__label">Procurar embarcações</label>
            <label
              v-if="this.searchShip2 != ''"
              style="
                position: absolute;
                bottom: 10px;
                right: 8px;
                cursor: pointer;
                color: white;
                font-size: 14px;
              "
              @click="clean"
              >X</label
            >
          </div>
          <!-- {{ this.searchShip }} -->
        </div>
        <gmap-map
          ref="map"
          class="map"
          :center="this.center"
          :zoom="this.zoom"
          :scrollwheel="false"
        >
          <gmap-marker
            :v-if="dataFetched"
            :title="m.infos.label"
            :key="index"
            :icon="{
              url: IconPinShip,
              scaledSize: google && new google.maps.Size(50, 50),
            }"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="showShipInformation(m)"
          ></gmap-marker>
        </gmap-map>
        <p
          v-if="this.copyMarkers.length == 0 && this.flagSearch == false"
          style="
            font-family: 'OpenSans-Bold', sans-serif;
            color: white;
            text-align: center;
          "
        >
          Não há embarcação com esse nome
        </p>
        <div class="ship-group" v-else>
          <button
            @click="resetShips()"
            style="
              cursor: pointer;
              background-color: #009aa0;
              border: none;
              border-radius: 0.2rem;
              padding: 0.5rem 2rem;
              margin-bottom: 0.5rem;
              color: white;
              font-family: 'OpenSans-SemiBold', sans-serif;
            "
          >
            Mostrar todas as embarcações
          </button>
          <div
            class="list-ship"
            v-for="(m, index) in copyMarkers"
            :key="index"
            @click="showShipOnlyInformation(m)"
            @dblclick="resetShips()"
          >
            <img :src="m.img" class="shipImg" />
            <p>{{ m.infos.label }}</p>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="shipInformation"
      class="card"
      width="760px"
      height="340px"
      @opened="afterOpen()"
      style="overflow: auto !important"
    >
      <div
        ref="modal"
        class="modal"
        style="
          display: flex;
          align-items: center;
          position: relative;
          padding: 1rem;
          color: #35415e;
        "
      >
        <img :src="IconShip" />
        <label style="margin-left: 1rem; font-family: 'OpenSans-Bold'"
          >Informações</label
        >
        <label
          style="position: absolute; right: 1rem; cursor: pointer"
          @click="hiddeModal()"
          >X</label
        >
      </div>
      <div
        style="
          display: flex;
          margin-left: 2.5rem;
          color: #35415e;
          margin-bottom: 2rem;
        "
        class="modal-content"
      >
        <img
          :src="this.shipInformation.img"
          class="ship-img"
          style="width: 270px; height: 200px; border-radius: 8px"
        />
        <div style="margin: 2rem">
          <div style="display: flex; align-items: center; padding: 1rem">
            <img :src="IconShip" />
            <div style="display: flex; flex-direction: column">
              <label style="margin-left: 1rem; font-family: 'OpenSans-Bold'"
                >Embarcação: {{ this.shipInformation.label }}</label
              >
              <label style="margin-left: 1rem; font-family: 'OpenSans-Regular'"
                >Identificador: {{ this.shipInformation.plate }}</label
              >
            </div>
          </div>
          <div style="display: flex; padding: 1rem">
            <img :src="IconPin" />
            <label style="margin-left: 1rem; font-family: 'OpenSans-Bold'"
              >Localização: {{ this.shipInformation.currentPosition }}</label
            >
          </div>
        </div>
      </div>
      <button
        v-if="showButton"
        class="button-modal"
        @click="detalhesRedirect()"
      >
        MAIS DETALHES
      </button>
      <button v-if="showBack" class="button2-modal" @click="initialState()">
        MOSTRAR MENOS
      </button>
      <div
        v-if="flag"
        style="
          overflow-x: auto;
          background-color: #edf1f4;
          box-shadow: 0px 3px 16px #0a4b6d14;
          margin: 1rem;
          color: #35415e;
        "
      >
        <p
          style="
            font-family: 'OpenSans-Bold';
            margin-left: 0px;
            padding: 1rem 0.5rem 2rem;
          "
        >
          Lista de próximas viagens
        </p>
        <table v-if="flag" class="table-purchase-info">
          <thead>
            <tr>
              <th>Origem</th>
              <th>Destino</th>
              <th>Saída</th>
              <th>Hora</th>
              <th>Valor (R$)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, index) in this.shipRoute" :key="index">
              <td>{{ s.origem }}</td>
              <td>{{ s.destino }}</td>
              <td>{{ formatData(s.dt_saida) }}</td>
              <td>
                {{ formataHora(s.dt_saida.split(" ").splice(-1)[0]) }}
              </td>
              <td>R${{ s.valor }}</td>
              <td>
                <button class="purchase" @click="purchaseAction(s, index)">
                  COMPRAR
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="!flag2"
        style="
          margin: 8px;
          padding: 5px 8px;
          text-align: center;
          color: #35415e;
        "
      >
        <span class="highlight">Não há passagens disponíveis no momento</span>
      </div>
    </modal>
  </div>
</template>
<script>
import api from "../api";
import { gmapApi } from "vue2-google-maps";
import IconPinShip from "@/assets/icons/icon_pin_ship.svg";
import IconShip from "@/assets/icons/icon_ship.svg";
import IconPin from "@/assets/icons/icon_pin2.svg";
import config from "@/config";
import axios from "axios";
import IconSearch from "@/assets/icons/icon_search.svg";
window.$ = window.jQuery = require("jquery");

export default {
  name: "GoogleMap",
  computed: { google: gmapApi },
  data() {
    return {
      shipInformation: {},
      staticShipIcon:
        "https://st4.depositphotos.com/18657574/21818/v/1600/depositphotos_218183030-stock-illustration-ship-vector-icon-isolated-transparent.jpg",
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: -3.0189028, lng: -63.983149 },
      markers: [],
      shipValor: [],
      shipRoute: [],
      places: [],
      searchShip2: "",
      currentPlace: null,
      dataFetched: false,
      flag: false,
      flag2: true,
      falgOption: false,
      flagSearch: false,
      showButton: true,
      showBack: false,
      selected: "",
      copyMarkers: [],
      IconPinShip,
      IconShip,
      IconPin,
      IconSearch,
      zoom: 6
    };
  },
  async beforeMount() {
    await this.geolocate();
    await this.setMakers();
  },
  async mounted() {},

  methods: {
    detalhesRedirect() {
      var x = document.getElementsByClassName("v--modal-box v--modal");
      x[0].style.overflow = "auto";
      this.showButton = false;
      this.showBack = true;
      var id = this.shipInformation.id;
      this.axios
        .get(`${api}/embarcacoes/${id}/viagens`)
        .then(response => {
          if (response.data.length !== 0) {
            this.flag = true;
          } else this.flag2 = false;
          this.shipRoute = [];
          response.data.map(element => {
            this.shipRoute.push({
              valor: element.valor_alimentacao + element.valor_transporte,
              dt_saida: element.dt_saida,
              destino: element.cidade_destino,
              origem: element.cidade_origem,
              id: element.id
            });
          });
        })
        .catch(error => {
          if (error.response.data.message == "Auth failed")
            this.$store.dispatch("logout");

          this.$router.push("/login");
        });
      this.$nextTick(() => {
        $(x[0]).ready(function() {
          setTimeout(function() {
            var height = $(x[0]).height();
            $(x[0]).animate({ scrollTop: "300px" }, "speed");
          }, 500);
        });
      });
    },
    clean() {
      this.searchShip2 = "";
      this.searchShip();
    },
    searchShip() {
      for (var i = 0; i < this.copyMarkers.length; i++) {
        if (
          this.searchShip2.toUpperCase() ==
          this.copyMarkers[i].infos.label.toUpperCase()
        ) {
          var element = this.copyMarkers[i];
          this.copyMarkers = [];
          this.copyMarkers.push({
            id: element.id,
            vid: element.vid,
            position: {
              lat: parseFloat(element.position.lat),
              lng: parseFloat(element.position.lng)
            },
            img: element.img,
            infos: {
              label: element.infos.label,
              plate: element.infos.plate,
              currentPosition: element.infos.currentPosition
            }
          });
          this.flagSearch = true;
        }
      }
      if (this.flagSearch == false && this.searchShip2 != "") {
        this.copyMarkers = [];
      }
      if (element == undefined && this.copyMarkers.length != 0) {
        this.flagSearch = false;
      }
      if (this.searchShip2 == "") {
        this.copyMarkers = [];
        for (var i = 0; i < this.markers.length; i++) {
          this.copyMarkers.push({
            id: this.markers[i].id,
            vid: this.markers[i].vid,
            position: {
              lat: parseFloat(this.markers[i].position.lat),
              lng: parseFloat(this.markers[i].position.lng)
            },
            img: this.markers[i].img,
            infos: {
              label: this.markers[i].infos.label,
              plate: this.markers[i].infos.plate,
              currentPosition: this.markers[i].infos.currentPosition
            }
          });
        }
      }
    },
    purchaseAction(object, index) {
      var dados = {
        id: object.id,
        key: index
      };
      this.$store.dispatch("viagem", dados);
      if (this.$store.state.session.auth == true) {
        this.$router.push({
          name: "purchase",
          params: { id: this.shipRoute.id, key: index }
        });
      } else {
        this.$router.push({
          name: "SignUp",
          params: { id: this.shipRoute.id }
        });
      }
    },
    hiddeModal() {
      this.$modal.hide("shipInformation");
    },
    resetShips() {
      this.markers = this.copyMarkers;
      this.zoom = 6;
      this.center = {
        lat: -3.0189028,
        lng: -63.983149
      };
    },
    showShipOnlyInformation(m) {
      this.zoom -= 2;
      this.selected = m.infos.label;
      if (this.selected != "") {
        if (this.selected == "SAGRADO CORAÇÃO") this.selected += " ";
        for (var i = 0; i < this.copyMarkers.length; i++) {
          if (this.copyMarkers[i].infos.label == this.selected) {
            this.markers = [];
            this.markers.push({
              id: this.copyMarkers[i].id,
              vid: this.copyMarkers[i].vid,
              position: {
                lat: parseFloat(this.copyMarkers[i].position.lat),
                lng: parseFloat(this.copyMarkers[i].position.lng)
              },
              img: this.copyMarkers[i].img,
              infos: {
                label: this.copyMarkers[i].infos.label,
                plate: this.copyMarkers[i].infos.plate,
                currentPosition: this.copyMarkers[i].infos.currentPosition
              }
            });
            this.center = {
              lat: this.markers[0].position.lat,
              lng: this.markers[0].position.lng
            };
            this.$refs.map.$mapPromise.then(map => {
              map.panTo(this.center);
              this.zoom += 5;
            });
          }
        }
        if (this.selected == "SAGRADO CORAÇÃO ")
          this.selected = "SAGRADO CORAÇÃO";
      } else {
        this.markers = this.copyMarkers;
      }
    },
    initialState() {
      this.showButton = true;
      this.showBack = false;
      this.flag = false;
      this.flag2 = true;
      // this.shipRoute = [];
      //resetar os estados das variaveis
    },
    async showShipInformation(el) {
      this.shipInformation = {};
      await this.setShipInformation(el);
      this.show();
    },
    afterOpen(e) {
      var x = document.getElementsByClassName("v--modal-box v--modal")[0];
      if (window.innerWidth <= 760) {
        x.style.width = "100%";
        x.style.left = "0%";
        x.style.overflow = "auto";
        // x.style.height = '';
      } else {
        x.style.width = "760px";
      }
    },
    calculateWidth(e) {
      this.responsive = window.innerWidth <= 768;
    },
    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    setShipInformation(el) {
      this.shipInformation["id"] = el.id;
      this.shipInformation["vid"] = el.vid;
      this.shipInformation["label"] = el.infos.label;
      this.shipInformation["currentPosition"] = el.infos.currentPosition;
      this.shipInformation["plate"] = el.infos.plate;
      this.shipInformation["img"] = el.img;
    },
    show() {
      this.initialState();
      this.$modal.show("shipInformation");
    },
    setMakers() {
      this.axios
        .get(`${api}/frete/veiculos`)
        .then(response => {
          response.data.data.map(element => {
            this.markers.push({
              id: element.description.id,
              vid: element.vehicleId,
              position: {
                lat: parseFloat(element.latitude),
                lng: parseFloat(element.longitude)
              },
              img:
                element !== undefined &&
                element !== null &&
                element.description !== undefined &&
                element.description !== null &&
                element.description.imagem_url !== undefined &&
                element.description.imagem_url !== null
                  ? element.description.imagem_url
                  : this.IconShip,
              infos: {
                label: element.label,
                plate: element.plate,
                currentPosition: element.address
              }
            });
          });
        })
        .catch(err => {});
      this.dataFetched = true;
      this.flagOption = true;
      this.copyMarkers = this.markers;
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    formatData(data) {
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);
        const hora = data.substring(12, 21);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Bold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Light.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}
.map {
  position: absolute;
  width: 73%;
  height: 470px;
  right: 1rem;

  @media only screen and (max-width: 1400px) {
    width: 70%;
  }
  @media only screen and (max-width: 1260px) {
    width: 65%;
  }
  @media only screen and (max-width: 1090px) {
    width: 58%;
  }
}
.entireMap {
  min-height: 80vh;
  padding-bottom: 10vh;
  padding-top: 10vh;
  position: relative;
  background-color: #2a3550;
}
p {
  margin-left: 30px;
}
th {
  font-weight: bold;
}
th,
td {
  padding: 8px 10px 8px;
}
a {
  margin-left: 0;
  margin-right: 0;
  margin: auto;
}
a:link,
a:visited {
  text-decoration: gray;
}

a:hover {
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.div-search {
  position: relative;
  .searchInput {
    padding: 8px 5px;
    border: none;
    outline: none;
    background-color: rgb(211, 211, 211);
    display: block;
    width: 90%;
    font-family: "OpenSans-Regular", sans-serif;
    // color: black;
    // position: relative;
    // height: 50%;
  }
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
}
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;

.form__group {
  font-family: "OpenSans-Regular", sans-serif;
  position: relative;
  padding: 15px 0 0;
  // margin-top: 10px;
  margin-bottom: 1rem;
  margin-left: 18px;
  width: 100%;
}

.form__field {
  font-family: "OpenSans-Regular", sans-serif;
  width: 90%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  // font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 18px;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 18px;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 18px;
    color: $primary;
    // font-weight: 700;
  }
  padding-bottom: 6px;
  // font-weight: 700;
  border-width: 3px;
  // border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
    padding-right: 20px;
  }
}
.searchImg {
  position: absolute;
  right: 10%;
  bottom: 8px;
  width: 26px;
  height: 26px;
}
.ship-group {
  overflow-y: auto;
  // background-color: #35415e;
  // border-radius: 8px;
  padding: 4px 20px;
  // border: 1px solid #35415e;
  color: #35415e;
  width: 100%;
}

.div-aux {
  background-color: #35415e;
  border-radius: 8px;
  border: 1px solid #35415e;
  position: relative;
  width: 70%;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1rem;
}
.text-header {
  color: white;
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 24px;
  text-align: center;
  // margin-bottom: 2rem;
  margin-top: 1vh;
}
.main-panel-ship {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 470px;
  // padding-top: 2vh;
  .shipImg {
    width: 60px;
    height: 60px;
    border-radius: 7px;
  }
}
.main-panel {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 470px;
  padding-top: 2vh;
  .shipImg {
    width: 60px;
    height: 60px;
    border-radius: 7px;
  }
}
gmap-marker {
  img {
    border-radius: 50%;
  }
}

.list-ship {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  background-color: white;
  // flex-direction: row;
  align-items: center;
  border-radius: 7px;
  // align-self: center;
  // align-content: center;
  p {
    margin-left: 1rem;
    font-size: 13px;
    font-family: "OpenSans-ExtraBold", sans-serif;
  }
}

#search_input {
  // background-image: url('../assets/icon_search.png');
  // background-repeat: no-repeat;
  // background-position: 10px 15px;
  width: 50%;
  font-size: 16px;
  padding: 18px 25px 15px 40px;
  border: 1px solid #ddd;
  margin: 12vh 25%;
  position: fixed;
  z-index: 1;
  border-radius: 8px;
}

.purchase {
  width: 120px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  padding: 12px 6px;
  background-color: #009aa0;
  border: none;
  border-radius: 6px;
  color: white;
  // margin-bottom: 1.5rem;
  cursor: pointer;
}

.table-purchase-info {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #009aa0;
}

.table-purchase-info > tbody {
  text-align: left;
}

.card {
  // margin-top: 30px;
  font-family: "OpenSans-Regular", sans-serif;
  display: flex;
  color: #404040;
  // position: relative;
  .button-modal {
    width: 70%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding: 8px 6px;
    background-color: #009aa0;
    border: none;
    border-radius: 6px;
    color: white;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  .button2-modal {
    width: 70%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding: 8px 6px;
    background-color: white;
    border: 1px solid #009aa0;
    border-radius: 6px;
    color: #009aa0;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  .highlight {
    font-weight: 600;
    // color: #252525;
    font-family: "OpenSans-Bold", sans-serif;
  }
}

.modal-button {
  display: block;
  margin: 1rem auto;
  background-color: #344675;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0.3rem 1rem;
  border: 2px solid #344675;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: white;
  width: 100%;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  #search_input {
    margin: 18vh 25%;
    font-size: 14px;
    padding: 12px 25px 10px 20px;
  }
}
@media only screen and (max-width: 600px) {
  #search_input {
    margin: 18vh 25%;
    font-size: 14px;
    padding: 12px 25px 10px 20px;
  }
}
@media only screen and (max-width: 720px) {
  .modal {
    font-size: 0.8em;
  }
  .modal-content {
    margin-left: 0rem !important;
    margin-bottom: 1rem !important;
    // flex-direction: column;
    align-items: center;
    object-fit: fill;
    font-size: 0.7em;
    // width: 70% !important;
    .ship-img {
      width: 9rem !important;
    }
  }
  .v--modal-overlay[data-modal="shipInformation"] {
    overflow: scroll !important;
  }
}
@media only screen and (max-width: 900px) {
  .text-header {
    font-size: 1rem;
  }
  .map {
    width: 100% !important;
    right: 0rem !important;
  }
  .main-panel-ship {
    width: 0rem;
  }
  .ship-group {
    display: none;
  }
  .div-search {
    display: none;
  }
}
</style>
