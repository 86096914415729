<template>
  <div class="main-container">
    <div class="userArea">
      <div class="leftSide">
        <h2 class="userArea-title">Meu Perfil</h2>
        <div class="content topSide">
          <div class="leftSide-content">
            <h3 class="content-title">DADOS DA CONTA</h3>
            <div>
              <div id="email-input">
                <span>Email</span>
                <div class="align-components">
                  <input
                    type="text"
                    id="email-input"
                    :value="user.email"
                    :disabled="this.isActive == true ? false : true"
                  />
                  <button @click="show()" id="alterarSenha-btn">
                    Alterar senha
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="rightSide-content">
            <img
              src="https://www.willierippleevents.com/wp-content/uploads/2016/01/Dummy-Profile-Picture-300x300.jpg"
            />
            <span>Foto de Perfil</span>
          </div>
        </div>
        <h3 class="content-title">DADOS PESSOAIS</h3>
        <div class="content bottomSide">
          <div class="input-components">
            <div id="input-name">
              <span>Nome completo</span>
              <input
                type="text"
                :placeholder="user.nome"
                v-model="user.nome"
                :disabled="this.isActive == true ? false : true"
              />
            </div>
            <div>
              <span>Nacionalidade</span>
              <select
                v-model="user.nacionalidade"
                :disabled="this.isActive == true ? false : true"
              >
                <option
                  v-for="(item, index) in nacionalidades"
                  :key="index"
                  :value="capitalizeFirstLetter(item)"
                >
                  {{ capitalizeFirstLetter(item) }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-components expanded-inputs">
            <div id="input-rg">
              <span>RG</span>
              <input
                type="text"
                class="input-small"
                :placeholder="user.rg"
                maxlength="8"
                v-mask="'########'"
                v-model="user.rg"
                :disabled="this.isActive == true ? false : true"
              />
            </div>
            <div id="input-cpf">
              <span>CPF</span>
              <input
                type="text"
                class="input-small"
                :placeholder="user.cpf"
                maxlength="14"
                v-mask="'###.###.###-##'"
                v-model="user.cpf"
                :disabled="this.isActive == true ? false : true"
              />
            </div>
            <div>
              <span>Gênero</span>

              <select
                class="input-small"
                v-model="user.genero"
                :disabled="this.isActive == true ? false : true"
              >
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
                <option value="O">Não Especificado</option>
              </select>
            </div>

            <div>
              <span>Data de nascimento</span>
              <input
                type="date"
                class="input-small date"
                :placeholder="user.dt_nascimento"
                v-model="user.dt_nascimento"
                :disabled="this.isActive == true ? false : true"
              />
            </div>
          </div>
          <div class="input-components" style="align-items: stretch">
            <div
              style="
                justify-content: space-between;
                display: flex;
                width: 20rem;
                flex-direction: row;
              "
            >
              <div>
                <span>DDD</span>
                <input
                  class="input-small"
                  type="text"
                  v-mask="'(##)'"
                  :placeholder="user.ddd"
                  v-model="user.ddd"
                  :disabled="this.isActive == true ? false : true"
                />
              </div>
              <div>
                <span>Telefone</span>
                <input
                  class="input-small"
                  type="text"
                  v-mask="'#####-####'"
                  :placeholder="user.telefone"
                  v-model="user.telefone"
                  :disabled="this.isActive == true ? false : true"
                />
              </div>
            </div>
            <div class="align-components" style="margin-bottom: 0rem">
              <button
                class="btn-cancelar"
                v-if="this.isActive"
                @click="
                  () => {
                    this.isActive = !this.isActive;
                  }
                "
              >
                Cancelar
              </button>
              <button
                style="display: flex; align-items: center; width: max-content"
                @click="
                  () => {
                    this.isActive == true
                      ? (updateUserData(user), (this.isActive = !this.isActive))
                      : (this.isActive = !this.isActive);
                  }
                "
              >
                <b-icon
                  :icon="this.isActive ? 'check2' : 'pencil'"
                  style="margin-right: 0.4rem"
                ></b-icon>
                {{
                  this.isActive == false ? "Editar Dados" : "Salvar Alterações"
                }}
              </button>
            </div>
          </div>
        </div>
        <!-- <img :src="visibility" style="width: 320px; height: 320px" /> -->
      </div>
    </div>
    <modal
      name="modal-alterar-senha"
      @opened="afterOpen()"
      width="400"
      height="auto"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2>ALTERAR SENHA</h2>
        </div>
        <div class="modal-body">
          <div style="position: relative">
            <span>Senha Atual</span>
            <input
              :type="isVisible ? 'text' : 'password'"
              v-model="userPassword.atual"
            />
            <b-icon
              class="iconVisible"
              :icon="this.isVisible ? 'eye' : 'eye-slash'"
              @click="
                () => {
                  this.isVisible = !this.isVisible;
                }
              "
            ></b-icon>
          </div>
          <div style="position: relative">
            <span>Nova senha </span>
            <input
              :type="isVisible2 ? 'text' : 'password'"
              v-model="userPassword.newPassword"
            />
            <b-icon
              class="iconVisible"
              :icon="this.isVisible2 ? 'eye' : 'eye-slash'"
              @click="
                () => {
                  this.isVisible2 = !this.isVisible2;
                }
              "
            ></b-icon>
          </div>
          <div style="position: relative">
            <span>Confirmar senha</span>
            <input
              :type="isVisible3 ? 'text' : 'password'"
              v-model="userPassword.confirmPassword"
            />
            <b-icon
              class="iconVisible"
              :icon="this.isVisible3 ? 'eye' : 'eye-slash'"
              @click="
                () => {
                  this.isVisible3 = !this.isVisible3;
                }
              "
            ></b-icon>
          </div>
        </div>
        <div class="modal-footer">
          <button id="btn-voltar" @click="hiddeModal()">Voltar</button>
          <button @click="changeUserPassword()">Salvar</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import api from "../../api";
import Swal from "sweetalert2";
import nacionalidades from "../../utils/nacionalidades.json";
import visible from "@/assets/icons/person_icon.svg";
import unvisible from "@/assets/unvisibility.png";

export default {
  data() {
    return {
      isChangeButton: false,
      user: {
        nome: "",
        email: "",
        dt_nascimento: "",
        // cep: "",
        // rua: "",
        cpf: "",
        rg: "",
        telefone: "",
        ddd: "",
        genero: "",
        nacionalidade: ""
      },
      isActive: false,
      countries: [],
      userPassword: {
        atual: "",
        newPassword: "",
        confirmPassword: ""
      },
      nacionalidades,
      isVisible: false,
      isVisible2: false,
      isVisible3: false,
      visible,
      unvisible
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    hiddeModal() {
      this.$modal.hide("modal-alterar-senha");
    },
    show() {
      this.$modal.show("modal-alterar-senha");
    },
    afterOpen() {
      var x = document.getElementsByClassName("v--modal-box v--modal")[0];
      x.style.borderRadius = "10px";
      if (document.body.offsetWidth < 768) {
        x.style.width = "100%";
        x.style.left = "auto";
      }
    },
    formataData(data) {
      if (data) {
        const dia = data.substring(0, 2);
        const mes = data.substring(5, 3);
        const ano = data.substring(6, 10);

        data = `${ano}-${mes}-${dia}`;
      }
      return data;
    },
    formatData(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    changeUserPassword() {
      if (
        this.userPassword.atual == "" ||
        this.userPassword.newPassword == ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Preencha os todos os campos.",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      const body = {
        senha: this.userPassword.atual,
        nova_senha: this.userPassword.newPassword
      };

      const config3 = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`,
          "Content-Type": "application/json"
        }
      };

      this.axios
        .patch(`${api}/usuarios/perfil/senha`, body, config3)
        .then(response => {
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Senha Alterada com sucesso!",
              showConfirmButton: false,
              timer: 1500
            });
            this.hiddeModal();
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Email ou senha incorretos",
            showConfirmButton: false,
            timer: 1500
          });
        });
    },
    updateUserData(user) {
      if (user.nome == "" || user.rg == "" || user.dt_nascimento == "") {
        Swal.fire({
          icon: "error",
          title: "Campos obrigatórios",
          text: "Nome, RG e data de nascimento são campos obrigatórios.",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      let userAux = {
        nome: user.nome,
        sexo: user.genero.toUpperCase(),
        nacionalidade: this.capitalizeFirstLetter(user.nacionalidade),
        dt_nascimento: user.dt_nascimento,
        telefone: user.ddd + user.telefone, //remover espacos em branco e caracteres n alfa numericos
        email: user.email,
        rg: user.rg,
        cpf: user.cpf
      };
      userAux.dt_nascimento = userAux.dt_nascimento;
      userAux.telefone = userAux.telefone.replace(/[^0-9]+/g, "");
      userAux.cpf = userAux.cpf.replace(/[^0-9]+/g, "");
      const body = userAux;

      const config3 = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`,
          "Content-Type": "application/json"
        }
      };

      this.axios
        .patch(`${api}/usuarios/perfil`, body, config3)
        .then(response => {
          Swal.fire({
            icon: "success",
            title: "Dados Atualizados com sucesso!",
            showConfirmButton: false,
            timer: 1500
          });
          this.$store.state.session.token = response.data.token;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Não foi possível atualizar seus dados.",
            showConfirmButton: false,
            timer: 1500
          });
        });
    }
  },
  // created() {
  //   translate("Ik spreek Engels", { to: "en" }).then(res => {
  //   });
  // },
  mounted() {
    this.nacionalidades = this.nacionalidades.nacionalidade.sort((a, b) => {
      return a.localeCompare(b);
    });
    //ordem alfabetica considerando acentos.
    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };

    this.axios.get(`${api}/usuarios/perfil`, config2).then(response => {
      const data = response.data;
      this.user.nome = data.pessoa.nome;
      this.user.email = data.complemento_pessoa.email;
      this.user.dt_nascimento = data.pessoa.dt_nascimento;
      this.user.rg = data.complemento_pessoa.rg;
      this.user.cpf = data.complemento_pessoa.cpf;
      this.user.ddd = data.complemento_pessoa.telefone.substring(0);
      this.user.telefone = data.complemento_pessoa.telefone.substring(2);
      this.user.genero = data.pessoa.sexo;
      this.user.nacionalidade = data.pessoa.nacionalidade;
    });

    console.log(this.user);
    this.axios.get("https://restcountries.eu/rest/v2/all").then(response => {
      response.data.map(country => {
        if (country.demonym != "") {
          this.countries.push(country.demonym);
        }
      });
    });
  }
};
</script>
<style lang="scss" scoped>
.main-container {
  margin-top: 22vh;
  margin-bottom: 5rem;
  /* height: 70vh;   */
}

.iconVisible {
  position: absolute;
  font-size: 24px;
  bottom: 0.4rem;
  right: 0.6rem;
  color: #626262;
  cursor: pointer;
}

select:disabled,
input:disabled {
  color: #626262 !important;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.expanded-inputs {
  input {
    width: 10.5rem !important;
  }
}

.userArea {
  display: flex;
  position: relative;
  justify-content: center;
}

.date {
  font-family: "Roboto", sans-serif;
  height: 36.52px;
}
.content {
  display: flex;
  justify-content: space-between;
  input {
    padding: 0.6rem 0.5rem;
    background: #f0f0f0;
    border: 1px solid #626262;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
  }
  button {
    padding: 0.6rem 1.2rem;
    background: #009aa0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    cursor: pointer;
  }
}
.content-title {
  font-family: Roboto, sans-serif;
  margin-bottom: 0.8rem;
}

.input-components {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-end;

  select {
    padding: 0.6rem 0.5rem;
    background: #f0f0f0;
    border: 1px solid #626262;
    border-radius: 4px;
    outline: 0;
    width: 180px;
  }
  .input-small {
    width: 150px;
  }
}
#alterarSenha-btn {
  background-color: #313f61;
}

.align-components {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  input {
    margin-right: 1rem;
    width: 280px;
  }

  .btn-cancelar {
    background-color: #d8dadf;
    color: #626262;
    font-weight: 500;
    margin-right: 2rem;
  }
}

.topSide {
  align-items: flex-start;
}
.bottomSide {
  flex-direction: column;
  span {
    font-family: Roboto, sans-serif;
    /* font-size: 20px; */
    color: #626262;
    font-weight: 500;
  }
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    input {
      width: 100%;
    }
  }
  #input-name {
    width: 75%;
    input {
      width: 90%;
    }
  }
}
.userArea-title {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 2.25rem;
  color: #2a3550;
  /* line-height: 56px; */
  position: absolute;
  top: -4rem;
}
.leftSide {
  width: 700px;
  padding: 1.5rem;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  span {
    margin-bottom: 0.5rem;
    color: #626262;
  }
  h3 {
    color: #626262;
  }
}
.rightSide {
  div {
    width: 356px;
    height: 124px;
    background-color: #f0f0f0;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    padding: 1.2rem;
    color: #626262;
    span {
      font-family: Roboto, sans-serif;
      font-size: 20px;
      color: #626262;
      font-weight: 500;
    }
  }
}

.bottom {
  position: relative;
  button {
    position: absolute;
    bottom: 0.8rem;
    right: 1rem;
  }
}

.rightSide-content2 {
  display: flex;
  width: 220px;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    input {
      width: 100%;
    }
  }
  span {
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
}
.rightSide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
  }
  span {
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
}
.leftSide-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    span {
      font-family: Roboto, sans-serif;
      font-weight: 500;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  .modal-header {
    h2 {
      font-family: Roboto, sans-serif;
      font-size: 20px;
      color: #626262;
      margin-left: 1rem;
      padding: 1rem;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1.2rem;
    border-top: 1px solid #d8dadf;
    border-bottom: 1px solid #d8dadf;
    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.8rem;
      width: 60%;
      span {
        font-family: Roboto, sans-serif;
        color: #626262;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.6rem 0.5rem;
        border-radius: 5px;
        outline: 0;
        border: 1px solid #626262;
        font-size: 14px;
      }
    }
  }

  .modal-footer {
    padding: 1.2rem;
    align-self: flex-end;
    #btn-voltar {
      padding: 0.5rem 2rem;
      background: #d8dadf;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: none;
      color: #626262;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }
    button {
      padding: 0.5rem 2rem;
      background: #009aa0;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: none;
      color: white;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>