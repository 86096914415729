<template>
  <div class="entire-div">
    <h3 class="component-title">Atualização do seu perfil</h3>
    <div class="component-body">
      <div class="logo-user">
        <img class="logo" :src="IconUser" />
      </div>

      <div class="form-pessoal">
        <div class="group group-basico">
          <div class="form-username">
            <span class="label">Nome:</span>
            <input
              v-model="model.username"
              type="name"
              required
              placeholder="Nome"
            />
          </div>
          <div class="form-sexo">
            <span class="label">Sexo:</span>
            <select class="form-sexo" v-model="model.genero">
              <option value>Selecione</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
              <option value="outros">Outros</option>
            </select>
          </div>
        </div>
        <div class="group group-contato">
          <div class="form-email">
            <span class="label">Email:</span>
            <input
              v-model="model.email"
              type="name"
              required
              placeholder="exemplo@gmail.com"
            />
          </div>

          <div class="form-telefone">
            <span class="label">Telefone:</span>
            <input
              v-model="model.telefone"
              type="phone"
              placeholder="(12) 34567-8900"
              v-mask="'(##) #####-####'"
            />
          </div>
        </div>

        <div class="group group-dados">
          <div class="form-rg">
            <span class="label">Rg:</span>
            <input
              id="input-5"
              v-model="model.rg"
              required
              placeholder="1234567-8"
              v-mask="'#######-#'"
            />
          </div>
          <div class="form-orgao">
            <span class="label">Emissor:</span>
            <input placeholder="SSP" v-model="model.orgao_emissor" />
          </div>
          <div class="form-nascimento">
            <span class="label">Nascimento:</span>
            <input
              class="form-control"
              label="Data de Nascimento"
              v-model="model.data_nascimento"
              v-mask="'##/##/####'"
              placeholder="dd/mm/aaaa"
            />
          </div>
          <div class="form-cpf">
            <span class="label">CPF:</span>
            <input
              required
              class="form-control"
              label="CPF"
              v-model="model.cpf"
              v-mask="'###.###.###-##'"
              placeholder="CPF"
            />
          </div>
          <div class="form-nacionalidade">
            <span class="label">Nacionalidade:</span>
            <input
              class="form-control"
              label="Nacionalidade"
              v-model="model.nacionalidade"
              placeholder="Brasileiro"
            />
          </div>
          <div style="margin-top: 1rem" class="form-atualizacao">
            <span class="label hiddenable">Atualizado em:</span>
            <span class="data">{{
              this.formatDataHour(this.user.dt_modificacao)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="div-button">
      <button @click="atualizarDados()" class="button-atualizar">
        Atualizar
        <span class="hiddenable">meus dados</span>
      </button>
      <button class="button-adicionar" @click="adicionar" type="info" id="flag">
        Comprar
        <span class="hiddenable">passagem</span> para mim
      </button>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import multiselect from 'vue-multiselect';

import api from '../../api';
import { format } from 'path';
import { constants } from 'os';

import IconUser from '@/assets/icons/user.svg';

export default {
  components: {
    multiselect
  },
  props: ['dados', 'agencias', 'user'],
  data() {
    return {
      //Icons
      IconUser,
      dt_modificacao: '-',
      sexo: '',
      teste: null,
      flagPassageiro: false,
      valorTotalAux: 0,
      valorTotal: 0,
      flag: false,
      selected: null,
      value: '',
      nome: 'gabriel',
      userArray: [],
      auxString: '',
      passageiros: [],
      // arrayAgencia: this.agencias,
      passengerInstance: null,
      options: [],
      descontos: [],
      dadosViagem: this.dados,
      model: {
        username: null,
        telefone: null,
        email: null,
        rg: null,
        genero: null,
        nacionalidade: null,
        orgao_emissor: null,
        data_nascimento: null,
        cpf: null
      }
    };
  },
  mounted() {
    var vm = this;

    this.model = {
      username: this.user.username,
      genero:
        {
          m: 'masculino',
          M: 'masculino',
          f: 'feminino',
          F: 'feminino',
          o: 'outros',
          O: 'outros'
        }[this.user.sexo] || 'escolha',
      telefone: this.user.telefone,
      email: this.user.email,
      rg: this.user.rg,
      orgao_emissor: this.user.orgao_emissor,
      nacionalidade: this.user.nacionalidade,
      data_nascimento: this.formatData(this.user.data_nascimento),
      cpf: this.user.cpf
    };
    if (
      vm.model.username != null &&
      vm.model.username != '' &&
      vm.model.genero != null &&
      vm.model.genero != '' &&
      vm.model.telefone != null &&
      vm.model.telefone != '' &&
      vm.model.email != null &&
      vm.model.email != '' &&
      vm.model.rg != null &&
      vm.model.rg != '' &&
      vm.model.orgao_emissor != null &&
      vm.model.orgao_emissor != '' &&
      vm.model.nacionalidade != null &&
      vm.model.nacionalidade != '' &&
      vm.model.data_nascimento != null &&
      vm.model.data_nascimento != '' &&
      vm.model.cpf != null &&
      vm.model.cpf != ''
    ) {
      document.getElementById('flag').disabled = false;
      vm.flagPassageiro = false;
    } else {
      vm.flagPassageiro = true;
    }

    if (this.$store.state.session.auth == false) this.$router.push('/search');
    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };

    this.axios
      .get(`${api}/embarcacoes/${this.dados.embarcacao_id}`, config2)
      .then(response => {
        this.descontos.push(response.data);
      })
      .catch(err => {
        if (err.response.data.message == 'Auth failed')
          this.$store.dispatch('logout');
      });
  },
  methods: {
    retornaGenero(letra) {
      return (
        {
          M: 'masculino',
          F: 'feminino',
          O: 'outros'
        }[letra.toLocaleUpperCase()] || ''
      );
    },

    formataData(data) {
      if (data) {
        const dia = data.substring(0, 2);
        const mes = data.substring(5, 3);
        const ano = data.substring(6, 10);

        data = `${ano}-${mes}-${dia}`;
      }
      return data;
    },
    formatData(data) {
      // 2020-01-29
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },

    formatDataHour(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);
        const hora = data.substring(12, 21);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    obtemCPF(cpf) {
      if (cpf) {
        cpf = cpf
          .replace('.', '')
          .replace('.', '')
          .replace('-', '');
      }

      return cpf;
    },

    formatarTelefone(tel) {
      if (tel)
        tel = tel
          .replace('-', '')
          .replace(' ', '')
          .replace('(', '')
          .replace(')', '');

      return tel;
    },
    atualizarDados() {
      if (
        this.model.username != null &&
        this.model.username != '' &&
        this.model.genero != null &&
        this.model.genero != '' &&
        this.model.telefone != null &&
        this.model.telefone != '' &&
        this.model.email != null &&
        this.model.email != '' &&
        this.model.rg != null &&
        this.model.rg != '' &&
        this.model.orgao_emissor != null &&
        this.model.orgao_emissor != '' &&
        this.model.nacionalidade != null &&
        this.model.nacionalidade != '' &&
        this.model.data_nascimento != null &&
        this.model.data_nascimento != '' &&
        this.model.cpf != null &&
        this.model.cpf != ''
      ) {
        const config2 = {
          headers: {
            Authorization: `Bearer ${this.$store.state.session.token}`
          }
        };

        const usuario = {
          nome: this.model.username,
          telefone: this.formatarTelefone(this.model.telefone),
          email: this.model.email,
          rg: this.model.rg,
          sexo: this.model.genero,
          cpf: this.obtemCPF(this.model.cpf),
          nacionalidade: this.model.nacionalidade,
          orgao_emissor: this.model.orgao_emissor,
          dt_nascimento: this.formataData(this.model.data_nascimento)
        };

        this.axios
          .patch(`${api}/usuarios/perfil`, usuario, config2)
          .then(response => {
            if (response.data.message == 'Successfully updated') {
              this.$store.dispatch('update_login', {
                token: response.data.token
              });
              Swal.fire({
                icon: 'success',
                title: 'Dados atualizados com sucesso !',
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Erro ao atualizar os dados !',
                showConfirmButton: false,
                timer: 1500
              });
            }
          })
          .catch(error => {
            this.$notify({
              message: 'Erro ao cadastrar',
              type: 'danger',
              timeout: 2000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false
            });
          });
      } else {
        this.$notify({
          message: 'Os campos são obrigatórios!',
          type: 'danger',
          timeout: 2000,
          horizontalAlign: 'center',
          verticalAlign: 'top',
          showClose: false,
          closeOnClick: false
        });
      }
    },
    dataAtual() {
      var data = new Date(),
        dia = data.getDate().toString(),
        dia_formatado = dia.length == 1 ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(),
        mes_formatado = mes.length == 1 ? '0' + mes : mes,
        ano_formatado = data.getFullYear();
      this.dt_modificacao =
        dia_formatado + '/' + mes_formatado + '/' + ano_formatado;
    },
    remove() {
      const valorTransporte = this.agencias.valor_transporte - 0;
      const valorAlimentacao = this.agencias.valor_alimentacao - 0;
      const valorAcomodacao = this.agencias.valor_acomodacao - 0;
      this.valorTotalAux = valorTransporte + valorAlimentacao + valorAcomodacao;

      this.$store.dispatch('pop_valorTotal', this.valorTotalAux);
      this.$store.dispatch('pop_passenger', {});
    },

    calculateAgeInDays(date) {
      const now = new Date();
      const birth = new Date(date);
      const days = (now.getTime() - birth.getTime()) / (1000 * 3600 * 24);
      return days;
    },

    calculateDiscounts(days) {
      for (var i = 0; i < this.descontos.length; i++) {
        const discount = this.descontos[i];
        if (days >= discount.dias_minimo && days <= discount.dias_maximo) {
          return discount;
        }
      }
      return null;
    },
    adicionar() {
      $(document).ready(function() {
        var height = $('html, body').height();
        $('html, body').animate({ scrollTop: '2000px' }, 'speed');
      });

      const desc = this.calculateDiscounts(
        this.calculateAgeInDays(this.model.data_nascimento)
      );

      if (desc != null) {
        const valorTransporte =
          this.agencias.valor_transporte -
          this.agencias.valor_transporte * desc.percentual_transporte;
        const valorAlimentacao =
          this.agencias.valor_alimentacao -
          this.agencias.valor_alimentacao * desc.percentual_alimentacao;
        const valorAcomodacao =
          this.agencias.valor_acomodacao -
          this.agencias.valor_acomodacao * desc.percentual_acomodacao;
        this.valorTotal += valorTransporte + valorAlimentacao + valorAcomodacao;
      } else {
        const valorTransporte = this.agencias.valor_transporte - 0;
        const valorAlimentacao = this.agencias.valor_alimentacao - 0;
        const valorAcomodacao = this.agencias.valor_acomodacao - 0;

        this.valorTotal += valorTransporte + valorAlimentacao + valorAcomodacao;
      }
      var passengerInstance = {
        nome: this.model.username,
        sexo: this.model.genero,
        nacionalidade: this.model.nacionalidade,
        dt_nascimento: this.model.data_nascimento,
        telefone: this.model.telefone,
        email: this.model.email,
        rg: this.model.rg,
        cpf: this.model.cpf,
        orgao_emissor: this.model.orgao_emissor,
        valor_total: this.valorTotal
      };

      if (this.model.username && this.model.genero) {
        var payloadInside = {
          valor: this.valorTotal
        };
        var flagUser = 0;
        if (this.$store.state.passageiros.length > 0) {
          for (let i = 0; i < this.$store.state.passageiros.length; i++) {
            if (
              this.model.cpf === this.$store.state.passageiros[i].cpf ||
              this.model.rg == this.$store.state.passageiros[i].rg
            ) {
              flagUser = 1;
              this.$notify({
                message: 'Passageiro já foi incluso!',
                type: 'danger',
                timeout: 2000,
                horizontalAlign: 'center',
                verticalAlign: 'top',
                showClose: false,
                closeOnClick: false
              });
            }
          }

          if (flagUser === 0) {
            this.$store.dispatch('updatedValorTotal', payloadInside);
            this.$store.dispatch('add_passenger', passengerInstance);
          }
        } else {
          this.$store.dispatch('updatedValorTotal', payloadInside);
          this.$store.dispatch('add_passenger', passengerInstance);
        }

        // this.$store.dispatch('updatedValorTotal',payloadInside)
        // this.$store.dispatch('add_passenger',passengerInstance)
        this.valorTotal = 0;
      } else {
        this.$notify({
          message: 'Preencha os campos!',
          type: 'danger',
          timeout: 2000,
          horizontalAlign: 'center',
          verticalAlign: 'top',
          showClose: false,
          closeOnClick: false
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.entire-div {
  background-color: white;
  width: 40%;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem auto;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }

  .component-title {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: gray;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 2px dashed #cccccc;
    padding-bottom: 0.5rem;
    margin-bottom: 0.72rem;
  }

  .component-body {
    border: 8px solid #cccccc;
    padding: 1%;

    .logo-user {
      display: inline-block;
      height: 100%;
      width: 40%;
      border-right: 2px dashed #eeeeee;
      margin-right: 1%;
      // @media only screen and (max-width: 580px) {
      display: none;
      // }

      .logo {
        display: inline-block;
        position: relative;
        height: 90%;
        width: 90%;
        opacity: 0.15;
        padding: 5%;
        max-width: 20rem;
      }
      vertical-align: middle;
    }

    .form-pessoal {
      display: inline-block;
      width: 55%;
      margin: auto;
      vertical-align: middle;
      // @media only screen and (max-width: 580px) {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      // }
    }
  }
}

.group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  vertical-align: middle;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
  margin-bottom: 1rem;
  padding: 0.25rem;
  // @media only screen and (max-width: 580px) {
  padding: 0.25rem 0;
  // }

  div {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    width: 50%;

    input,
    select {
      width: 80%;
      background-color: white;
    }
  }

  .label {
    display: block;
    margin-bottom: 0.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 200;
    color: #344675;
  }
}

// .hiddenable {
//   @media only screen and (max-width: 580px) {
//     display: none;
//   }
// }

.group-dados {
  margin-bottom: 0;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

.div-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  margin: 0.25rem auto;
  @media only screen and (max-width: 580px) {
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
}
.button-adicionar {
  flex-grow: 2;

  color: white;
  background-color: #3fb935;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0.4rem 0rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin: 0.3rem auto;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 15px;
  @media only screen and (max-width: 580px) {
    margin: 0.3rem auto;
    width: 100%;
  }
}

.button-atualizar {
  flex-grow: 1;
  color: white;
  background-color: #344675;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0.4rem 0rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin: 0.3rem auto;
  margin-bottom: 0;
  cursor: pointer;
  @media only screen and (max-width: 580px) {
    margin: 0.3rem auto;
    width: 100%;
  }
}
input,
select {
  border-radius: 0.25rem;
  border: 2px dashed gray;
  padding: 0.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 200;
}
select:focus,
select:active,
input:focus,
input:active {
  outline: none;
  border-color: #344675;
}
</style>
