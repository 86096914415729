<template>
  <div class="entire-div">
    <div class="load visible">
      <div class="half-circle-spinner">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
    </div>
    <div v-if="!this.flagEmpty && this.passagens === ''">
      <p class="text-header">Encontre sua Viagem de Barco</p>
      <p class="subtext-header">Veja todas suas passagens compradas aqui</p>
      <div v-if="this.passagens === ''" class="half-circle-spinner">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
      <p v-if="this.passagens === ''" class="text-header2">
        Carregando suas passagens...
      </p>
    </div>
    <div class="when-empty" v-if="this.flagEmpty">
      <img :src="EmptyBoat" alt="Ícone de barco" />
      <p>Nenhuma passagem encontrada.</p>
      <span
        >Parece que ainda não realizamos viagens juntos. Estamos aguardando
        você!</span
      >
    </div>
    <div
      style="margin: 0 auto; width: fit-content; padding: 1rem"
      v-if="this.passagens && !this.flagEmpty"
    >
      <div>
        <h2 class="my-tickets-header">Minhas Passagens</h2>
        <h3 class="my-tickets-subheader">
          Verifique aqui o histórico de suas viagens!
        </h3>
      </div>
      <div
        class="my-tickets"
        v-for="(ticket, index) in this.passagens"
        :key="index"
      >
        <div class="ticket">
          <div class="ticket-head">
            <div>
              <label class="ticket-title">Data da Compra</label>
              <label class="ticket-subtitle">{{
                formatData(ticket.dadosComuns.dadosviagem.dt_compra)
              }}</label>
            </div>
            <div>
              <label class="ticket-title">Nº do Pedido</label>
              <label class="ticket-subtitle">{{
                ticket.lista[0].celer_pagamento_id.substring(6, 14)
              }}</label>
            </div>
            <div>
              <label class="ticket-title">Situação da Compra</label>
              <label class="ticket-subtitle">{{
                statusPagamentoTexto(ticket.dadosComuns.statusclass)
              }}</label>
            </div>
            <div>
              <label class="ticket-title">Total</label>
              <label class="ticket-subtitle">{{
                ticket.dadosComuns.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                })
              }}</label>
            </div>
          </div>
          <div class="ticket-body">
            <div class="ticket-body-left">
              <label class="ticket-title2">
                {{ ticket.dadosComuns.dadosviagem.cidade_origem }} -
                {{ ticket.dadosComuns.dadosviagem.cidade_destino }}
              </label>
              <label class="ticket-subtitle"
                >Embarcação:
                {{ ticket.dadosComuns.dadosviagem.embarcacao_nome }}</label
              >
              <label class="ticket-subtitle"
                >Saída:
                {{ formatData(ticket.dadosComuns.dadosviagem.dt_saida) }} -
                {{
                  formataHora(
                    ticket.dadosComuns.dadosviagem.dt_saida
                      .split(" ")
                      .splice(-1)[0]
                  )
                }}h</label
              >
            </div>

            <div class="ticket-body-center">
              <label class="ticket-title">Passageiros</label>
              <div
                v-for="(listPassageiro, plindex) in ticket.lista"
                :key="plindex"
                class="ticket-subtitle"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin: 0.2rem 0;
                "
              >
                <div>
                  <label>{{ listPassageiro.passageiro.nome }}</label>
                </div>
                <div
                  style="flex-grow: 1; justify-content: flex-end; display: flex"
                >
                  <label
                    class="label-link"
                    @click="showPdf(listPassageiro, plindex)"
                    >Ver bilhete</label
                  >
                  <label
                    class="label-link"
                    style="margin: 0rem 2rem"
                    @click="gerarPdf(listPassageiro, plindex)"
                    >Baixar pdf</label
                  >
                </div>
              </div>
            </div>

            <div class="ticket-body-right">
              <button class="btn-pdfAll" @click="gerarTodosPdf(ticket.lista)">
                <b-icon
                  icon="download"
                  style="color: #626262; margin-right: 0.5rem"
                />Baixar todos os bilhetes
              </button>
              <button
                style="margin-top: 1rem"
                class="btn-cancelPurchase"
                @click="cancelMessage()"
              >
                Solicitar cancelamento
              </button>
            </div>
          </div>
        </div>
        <div style="position: relative">
          <div
            v-for="(listTicket, lindex) in ticket.lista"
            :key="lindex"
            style="padding-bottom: 1rem; width: fit-content; margin: 0 auto"
          >
            <div
              :id="'pdf-element-' + index"
              class="pdf-element"
              style="position: relative"
              hidden
            >
              <header>
                <img :src="LogoNavegam" />
                <div class="header-info">
                  <p style="margin-bottom: 10px">
                    <strong>Data da compra: </strong
                    >{{ formatData(listTicket.dt_compra) }}
                  </p>
                  <p>
                    <strong>Pagamento: </strong
                    >{{
                      capitalizeFirstLetter(
                        formataFormaPagamento(listTicket.forma_pagamento)
                      )
                    }}
                  </p>
                </div>
              </header>

              <main class="main-content">
                <h2 class="section-title">Dados do Passageiro</h2>
                <section class="passageiro-info">
                  <div class="right-side">
                    <article>
                      <p>
                        <strong>Nome: </strong>{{ listTicket.passageiro.nome }}
                        {{ telefoneEmbarcacao }}
                      </p>
                    </article>

                    <article>
                      <p>
                        <strong>Data de Nascimento: </strong
                        >{{ formatData(listTicket.passageiro.dt_nascimento) }}
                      </p>
                    </article>

                    <article class="double-line">
                      <p>
                        <strong>RG: </strong
                        >{{ formataRg(listTicket.passageiro.rg) }}
                      </p>
                      <p>
                        <strong>CPF: </strong
                        >{{ formataCPF(listTicket.passageiro.cpf) }}
                      </p>
                    </article>
                    <article>
                      <p>
                        <strong>Celular: </strong
                        >{{ formatarTelefone(listTicket.passageiro.telefone) }}
                      </p>
                    </article>
                  </div>
                  <div class="left-side">
                    <p>
                      <strong>Nacionalidade: </strong
                      >{{
                        capitalizeFirstLetter(
                          listTicket.passageiro.nacionalidade
                        )
                      }}
                    </p>
                    <p>
                      <strong>Gênero: </strong
                      >{{ fullGenders(listTicket.passageiro.sexo) }}
                    </p>
                  </div>
                </section>

                <section class="travel-info">
                  <h2 class="section-title">Dados da Viagem</h2>
                  <article class="double-line">
                    <p>
                      <strong>Origem: </strong>{{ listTicket.cidade_origem }}
                    </p>
                    <p>
                      <strong>Destino: </strong
                      >{{ capitalizeFirstLetter(listTicket.cidade_destino) }}
                    </p>
                  </article>

                  <article class="double-line">
                    <p>
                      <strong>Embarcação: </strong
                      >{{ capitalizeFirstLetter(listTicket.embarcacao_nome) }}
                    </p>
                    <p>
                      <strong>Local de Partida: </strong>
                      <!-- {{ getPorto() }} -->
                      Porto de {{ listTicket.cidade_origem }}
                    </p>
                  </article>

                  <article class="double-line">
                    <p>
                      <strong>Saída: </strong
                      >{{ formatData(listTicket.dt_saida) }}
                      {{
                        formataHora(
                          ticket.dadosComuns.dadosviagem.dt_saida
                            .split(" ")
                            .splice(-1)[0]
                        )
                      }}h
                    </p>
                    <p>
                      <strong>Previsão de chegada: </strong
                      >{{ formatData(listTicket.dt_chegada) }}
                      {{
                        formataHora(
                          ticket.dadosComuns.dadosviagem.dt_chegada
                            .split(" ")
                            .splice(-1)[0]
                        )
                      }}h
                    </p>
                  </article>
                  <article>
                    <p>
                      <strong>Contato da Embarcação: </strong
                      >{{ formatarTelefone(listTicket.passageiro.telefone) }}
                    </p>
                  </article>
                </section>
                <section class="qrcode-container">
                  <img :src="listTicket.qrcode" />
                  <p>(Apresentar este código no embarque)</p>
                </section>

                <section class="box-infos">
                  <h2 class="section-title">Informações</h2>
                  <ol type="1" class="list-infos">
                    <li>
                      1. A taxa portuária <strong>não</strong> está inclusa
                      nesta compra e deverá ser paga no porto local.
                    </li>
                    <li>
                      2. No dia da sua viagem, chegue com antecedência ao local
                      de partida.
                    </li>
                    <li>
                      3. O serviço de alimentação depende das regras de cada
                      embarcação. Para mais informações, entre em contato com a
                      embarcação da sua viagem.
                    </li>
                    <li>
                      4. As bagagens dos passageiros podem pesar no máximo 10
                      quilos.
                    </li>
                    <li>
                      5. É <strong>obrigatório</strong> apresentar documento com
                      foto no embarque.
                    </li>
                    <li>
                      6. Em caso de crianças, é
                      <strong>obrigatório</strong> apresentar certidão de
                      nascimento no embarque.
                    </li>
                    <li>
                      7. Imprima este <strong>comprovante</strong> e apresente
                      no embarque.
                    </li>
                  </ol>
                </section>
              </main>
              <footer class="pdf-footer">
                <p>
                  <strong
                    >Navegam - Sistema de Agenciamento de Passagens</strong
                  >
                </p>
                <p>Rua do Sol, 300, Manaus/AM, 69095-210</p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="subtext-header2" style="color: #626262; font-weight: 550">
        Precisa de ajuda?
      </p>
      <p class="subtext-header2">
        Entre em contato conosco via email ou telefone.
      </p>

      <p class="subtext-header2">
        Email: passagens@navegam.com.br | Telefone: (99) 99343-8459
      </p>
      <p class="subtext-header2">
        Acesse:
        <span
          class="fale-conosco-redirect"
          style="color: #009aa0; cursor: pointer; font-weight: 700"
          @click="goTo('faleconosco')"
          >Fale Conosco</span
        >
      </p>
    </div>
  </div>
</template>
<script>
import sagrado from "../../public/img/sagrado.png";
import config from "@/config";
import api, { indexOf, substring } from "../api";
import moment from "moment";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
import Swal from "sweetalert2";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import _ from "lodash";
import IconCancel from "@/assets/icons/cancel_icon.svg";
import IconPdf from "@/assets/icons/pdf.svg";
import IconCheck from "@/assets/icons/check.svg";
import IconSad from "@/assets/icons/sad.svg";
import IconBarco from "@/assets/icons/icon_barco.svg";
import IconCalendar from "@/assets/icons/icon_calendar.svg";
import IconClock from "@/assets/icons/icon_clock.svg";
import IconPerson from "@/assets/icons/person.svg";
import MarcaNavegam from "@/assets/MARCA_NAVEGAM.svg";
import LogoNavegam from "../assets/navegam-logo.svg";
import IconDownload from "@/assets/icons/Icondownload.svg";
import NvgamBg from "@/assets/icons/nvgam_bg.png";
import logoNavegam from "@/assets/icons/logo_navegam.svg";
import EmptyBoat from "../assets/img/empty-boat.svg";

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      // Icons
      IconPdf,
      IconCheck,
      IconSad,
      sagrado,
      pdfTeste: "",
      flagEmpty: false,
      numeroBilhetes: 10,

      info: {
        nome: "Lucas",
        rg: "1234567-8",
        nascimento: "12/12/1221",
        celular: "(92)9999-9999",
        sexo: "M",
        pais: "Brasileiro"
      },

      qrSrc: "",
      flag: false,
      teste: "",
      qtdPassageiros: null,
      user: {},
      data_saida_hora_pdf: "",
      data_saida_pdf: "",
      valorTotal: 0,
      dt_saida: [],
      dadosViagem: [],
      embarcacao_id: null,
      agencias: [],
      passagens: {},
      semPassagem: false,
      IconBarco,
      IconClock,
      IconCalendar,
      IconPerson,
      NvgamBg,
      MarcaNavegam,
      LogoNavegam,
      // getPorto
      portoCidade: "",
      viagensOrigem: [],
      cidade_id: null,

      telefoneEmbarcacao: "",
      IconDownload,
      logoNavegam,
      IconCancel,
      EmptyBoat
    };
  },
  computed: {},
  methods: {
    cancelarPassagem(passagem) {
      const headers = {
        Authorization: `Bearer ${this.$store.state.session.token}`
      };
      Swal.fire({
        title: "Tem certeza?",
        text:
          "O reembolso será processado em até 24 horas, não sendo reembolsado prazo de 24 horas do embarque. Em caso de cancelamento, favor enviar um e-mail para passagens@navegam.com.br, você terá um retorno em até 48 horas a partir do envio do e-mail.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00FF00",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          axios({
            method: "PATCH",
            url: `${api}/v3/passagens/${passagem[0].celer_pagamento_id}/cancel`,
            headers: headers
          })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Compra cancelada com sucesso!",
                showConfirmButton: false,
                timer: 1000
              }).then(result => {
                //this.$router.push(`/meustickets`);
                window.location.reload();
              });
            })
            .catch(error => {
              console.error(error.response.data);
              let message = "Problemas ao cancelar compra";
              if (error.response.status == 405) {
                message = "Cancelamento não permitido";
              }
              this.$notify({
                message: message,
                type: "danger",
                timeout: 2000,
                horizontalAlign: "center",
                verticalAlign: "top",
                showClose: false,
                closeOnClick: false
              });
            });
        }
      });
    },
    cancelMessage() {
      Swal.fire({
        title: "Solicitação de cancelamento",
        text:
          "Para cancelar esta compra, entre em contato pela página Fale Conosco. Responderemos sua solicitação em até 48 horas a partir do envio da mensagem.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#009aa0",
        confirmButtonText: "Ir para Fale Conosco",
        cancelButtonColor: "#626262",
        cancelButtonText: "Voltar"
      }).then(result => {
        if (result.value) {
          this.goTo("faleconosco");
        }
      });
    },
    formatPhoneNumber(phoneNumberString) {
      var dig1 = phoneNumberString.substring(0, 2);
      var dig2 = phoneNumberString.substring(3, 7);
      var dig3 = phoneNumberString.substring(7, 11);
      var number = `(${dig1}) ${dig2}-${dig3}`;
      return number;
    },
    formatData(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    statusPagamentoTexto(forma) {
      if (forma === "valida") {
        return "Pagamento efetuado";
      } else if (forma === "pendente") {
        return "Pagamento pendente";
      } else if (forma === "invalida") {
        return "Pagamento cancelado";
      }
    },
    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    formataCPF(cpf) {
      var dig1 = cpf.substring(0, 3);
      var dig2 = cpf.substring(3, 6);
      var dig3 = cpf.substring(6, 9);
      var dig4 = cpf.substring(9, 12);
      var cpf = `${dig1}.${dig2}.${dig3}-${dig4}`;
      return cpf;
    },
    formatarTelefone(number) {
      var dig1 = number.substring(0, 2);
      var dig2 = number.substring(2, 7);
      var dig3 = number.substring(7, 11);
      var number = `(${dig1}) ${dig2}-${dig3}`;
      return number;
    },

    /*
    async getPorto() {
      // buscar id da cidade de embarque
      await this.axios.get(`${api}/cidades?tipo=origem`).then(response => {
        this.viagensOrigem = response.data[0].id;
        this.cidade_id = this.viagensOrigem;
        console.log(this.passagens);
      });

      // buscar nome do porto com o id da cidade
      this.axios
        .get(`${api}/embarcacoes/1/portos?cidade_id=${this.cidade_id}`)
        .then(response => {
          this.portoCidade = response.data.porto;
        });
      console.log(this.dadosViagem.cidade_origem);

      return this.portoCidade.nome;
    },
    */
    returnGenero(x) {
      switch (x) {
        case "m":
          return "Masculino";
          break;
        case "f":
          return "Femenino";
          break;
        case "o":
          return "Outros";
          break;
      }
    },
    returnFlag(flag, index, lista) {
      var element = document.getElementById("mais_detalhes_" + index);
      var element2 = document.getElementById("menos_detalhes_" + index);
      var element3 = [];
      var element4 = document.getElementsByClassName("collapse-button")[index];
      var nodes = document.getElementsByClassName("my-card")[index].childNodes
        .length;
      if (flag == 1) {
        element.style.display = "none";
        element2.style.display = "inline";
        element4.style.visibility = "visible";
        element4.style.height = "auto";

        for (var i = 1; i < nodes; i++) {
          element3[i] = document.getElementsByClassName("my-card")[
            index
          ].childNodes[i];
          element3[i].style.display = "flex";
        }
      } else {
        element.style.display = "inline";
        element2.style.display = "none";
        for (var i = 1; i < nodes; i++) {
          element3[i] = document.getElementsByClassName("my-card")[
            index
          ].childNodes[i];
          element3[i].style.display = "none";
        }
      }
    },
    formataRg(rg) {
      var dig1 = rg.substring(0, 7);
      var dig2 = rg.substring(7, 9);
      rg = `${dig1}-${dig2}`;
      return rg;
    },
    capitalizeFirstLetter(string) {
      if (string == "crianca") {
        string = "criança";
      }
      // if (this.listTicket.passageiro.sexo == 'M') {
      //   this.listTicket.passageiro.sexo = 'Masculino'
      // }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    fullGenders(string) {
      if (string === "M") {
        string = "Masculino";
      } else if (string === "F") {
        string = "Feminino";
      } else {
        string = "Outro";
      }
      return string;
    },
    retornaGenero(letra) {
      return (
        {
          M: "masculino",
          F: "feminino",
          O: "outros"
        }[letra.toLocaleUpperCase()] || ""
      );
    },
    gerarPdf(lista, index) {
      var formataNome = lista.passageiro.nome
        .replace(/\s/g, "_")
        .toLocaleLowerCase();
      //removendo espaços em branco e minimizando os caracteres
      var element = document.getElementsByClassName("pdf-element")[index]
        .innerHTML;
      var opt = {
        margin: 0,
        filename: `${formataNome}_passagem.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
      };
      html2pdf()
        .from(element)
        .set(opt)
        .save();
    },
    showPdf(lista, index) {
      var formataNome = lista.passageiro.nome
        .replace(/\s/g, "_")
        .toLocaleLowerCase();
      //removendo espaços em branco e minimizando os caracteres
      var element = document.getElementsByClassName("pdf-element")[index]
        .innerHTML;
      var opt = {
        margin: 0,
        filename: `${formataNome}_passagem.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: "avoid-all", after: [] }
      };
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          window.open(
            pdf.output("bloburl", { filename: formataNome }),
            "_blank"
          );
        });
    },
    gerarTodosPdf(lista) {
      document.querySelector(".load").classList.toggle("visible");
      console.log("opa");
      var numeroPdfs = lista;
      numeroPdfs.map((item, index) => {
        var formataNome = item.passageiro.nome
          .replace(/\s/g, "_")
          .toLocaleLowerCase();
        //removendo espaços em branco e minimizando os caracteres
        var element = document.getElementsByClassName("pdf-element")[index]
          .innerHTML;
        var opt = {
          margin: 0,
          filename: `${formataNome}_passagem.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
        };
        document.querySelector(".load").classList.toggle("visible");
        html2pdf()
          .from(element)
          .set(opt)
          .save();
      });
    },
    formataFormaPagamento(formaPagamento) {
      if (formaPagamento == "cartao_credito") {
        formaPagamento = "Cartão Crédito";
      } else {
        formaPagamento = "Cartão Débito";
      }
      return formaPagamento;
    },
    goTo(rota) {
      let routeData = this.$router.resolve({ name: rota });
      window.open(routeData.href, "_top");
    }
  },

  async mounted() {
    this.passagens = "";
    const config = {
      Authorization: `Bearer ${this.$store.state.session.token}`,
      "Content-Type": `application/json`
    };

    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };

    this.axios
      .get(`${api}/viagens/${this.$store.state.dadosViagem.id}`, config2)
      .then(response => {
        // this.dt_saida = moment(response.data.dt_saida).format("DD/MM/YYYY");
        // this.hr_saida = moment(response.data.dt_saida).format("h:mm");

        // this.data_saida_hora_pdf = moment(response.data.dt_saida).format(
        //   "h:mm"
        // );
        // this.data_saida_pdf = moment(response.data.dt_saida).format(
        //   "DD/MM/YYYY"
        // );
        this.embarcacao_id = response.data.embarcacao_id;

        // this.dadosViagem = response.data;

        // this.$store.dispatch("setValorTotal", 0);
        // this.valorAdulto =
        //   this.dadosViagem.agencias[0].valor_transporte +
        //   this.dadosViagem.agencias[0].valor_alimentacao +
        //   this.dadosViagem.agencias[0].valor_acomodacao;
        // this.img = this.dadosViagem.imagem_url;
        // this.$store.dispatch("data_trip", response.data);
      });

    this.axios
      .get(
        `${api}/viagens/busca/?cidade_origem=${
          this.dadosViagem.cidade_origem
        }&cidade_destino=${
          this.dadosViagem.cidade_destino
        }&dt_saida_inicial=${moment(this.dataInicio).format(
          "YYYY-MM-DD"
        )}&dt_saida_final=${moment(this.dataFim).format("YYYY-MM-DD")}`
      )
      .then(response => {
        response.data.map(element => {
          this.telefoneEmbarcacao = response.data.embarcacao_telefone;
        });
      });

    // let dados = dadosPassagens.filter(n => cielo_pagamento_id !== undefined);
    // let passageirosAgrupados = _.groupBy(dados, 'cielo_pagamento_id');
    // let resultado = [];
    // const data = (await axios.get(`${api}/usuarios/perfil/passagens`, config))
    //   .data;
    // let dadosPassagens = [...data];
    // axios.get((`${api}/usuarios/perfil/passagens`, config)).then(response => {
    // });
    // let dados = dadosPassagens.filter(n => n .fatura !== undefined);
    // let passageirosAgrupados = _.groupBy(dados, 'fatura.id');
    try {
      const data = (
        await axios.get(`${api}/usuarios/perfil/passagens`, config2)
      ).data;
      let dadosPassagens = data.filter(n => n.celer_pagamento_id !== null);
      let passageirosAgrupados = _.groupBy(
        dadosPassagens,
        "celer_pagamento_id"
      );

      let resultado = [];

      for (var pa in passageirosAgrupados) {
        let objInterno = {
          dadosComuns: {
            viagem: "",
            passageiros: [],
            qrcodeurls: [],
            embarcacao: "",
            valor: 0,
            vendedor: ""
          },
          lista: []
        };
        objInterno.lista = passageirosAgrupados[pa];
        for (var pain in passageirosAgrupados[pa]) {
          let info = passageirosAgrupados[pa][pain];
          objInterno.dadosComuns.status = info.status;
          objInterno.dadosComuns.statusclass = info.status;
          objInterno.dadosComuns.viagem =
            "De " + info.cidade_origem + " - para " + info.cidade_destino;
          objInterno.dadosComuns.embarcacao = info.embarcacao_nome;
          objInterno.dadosComuns.passageiros.push(info.passageiro.nome);
          objInterno.dadosComuns.qrcodeurls.push(info.qrcode);
          objInterno.dadosComuns.valor += info.valor_total;
          objInterno.dadosComuns.dadosviagem = info;
        }
        resultado.push(objInterno);
      }
      this.passagens = resultado;
      console.log(this.passagens);
      if (this.passagens.length == 0) {
        this.flagEmpty = true;
      }
      var qr;
      for (let i = 0; i < this.passagens.length; i++) {
        for (let j = 0; j < this.passagens[i].lista.length; j++) {
          qr = new VanillaQR({
            url: this.passagens[i].lista[j].qrcode,
            width: 220,
            height: 220,
            colorLight: "white",
            colorDark: "black",
            toTable: false,
            ecclevel: 1,
            noBorder: true,
            borderSize: 1
          });
          var imageElement = qr.toImage("png");
          this.passagens[i].lista[j].qrcode = imageElement.src;
        }
      }
    } catch (error) {
      if (error.response.data.message == "Auth failed") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Bold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

.my-tickets {
  margin: 1rem 0;
  width: 110%;
}

.my-tickets-header {
  margin-top: 12vh;
  font-size: 32px;
  margin-bottom: 1rem;
}

.my-tickets-subheader {
  font-size: 20px;
}

.my-tickets-subheader,
.my-tickets-header {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  color: #2a3550;
}
.ticket-title,
.ticket-subtitle {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #626262;
}

.ticket-title {
  font-weight: bold;
}

.ticket-title2 {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  color: #626262;
  font-weight: bold;
}

.ticket-subtitle {
  font-weight: normal;
}

.ticket-head {
  border-bottom: 2px dashed #009aa0;
  padding: 0.5rem 0;
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    label {
      margin: 0.4rem 0;
    }
  }
  div + div {
    margin-left: 4rem;
  }
}

.btn-pdfAll,
.btn-cancelPurchase {
  border: none;
  width: 13rem;
  color: #626262;
  background: #f0f0f0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.ticket-body {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .ticket-title,
  .ticket-title2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.ticket-body-right {
  flex-grow: 1;
  align-items: flex-end;
}

.ticket-body-left,
.ticket-body-center,
.ticket-body-right {
  display: flex;
  flex-direction: column;
  .label-link {
    color: #009aa0;
    text-decoration-line: underline;
    margin: 0rem 1rem;
    cursor: pointer;
    /* margin-right: 1rem; */
  }
}
.ticket-body-center {
  margin-left: 4rem;
}
.ticket {
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.text-header2 {
  font-family: "Roboto", sans-serif;
  color: #2a3550;
  font-size: 22px;
  margin: 0 auto;
  margin-top: 12rem;
  text-align: center;
}
.text-header {
  font-family: "Roboto", sans-serif;
  color: #2a3550;
  font-size: 34px;
  margin: 0 auto;
  padding-top: 13vh;
  text-align: center;
}
.subtext-header {
  font-family: "Roboto", sans-serif;
  color: #2a3550;
  font-size: 20px;
  margin: 0 auto;
  padding-top: 2vh;
  text-align: center;
}
.subtext-header2 {
  font-family: "Roboto", sans-serif;
  color: #626262;
  font-size: 16px;
  margin: 0 auto;
  padding-top: 1vh;
  text-align: center;
}

.when-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  margin: 100px auto;

  font-family: "Roboto";

  img {
    height: 230px;
    width: 230px;
  }

  p {
    font-weight: 700;
    font-style: italic;
    color: #2a3550;
    font-size: 24px;
  }

  span {
    font-size: 24px;
    font-weight: 400;
    color: #2a3550;
  }
}

.half-circle-spinner,
.half-circle-spinner {
  box-sizing: border-box;
}

.half-circle-spinner {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  top: 8vh;
  width: 100%;
  height: 100%;
  left: -1rem;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #009aa0;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #009aa0;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.load {
  min-height: 100vh;
  width: 100%;

  background: rgba(255, 255, 255, 0.658);
  position: absolute;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}
.visible {
  display: none;
  visibility: hidden;
}

.disabled {
  display: none;
}

.card-title > p > img {
  width: 20px;
  margin-top: auto;
}
.gerarPdfbutton2 {
  display: flex;
  background-color: transparent;
  filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(173deg)
    brightness(110%) contrast(100%);
  font-family: "OpenSans-SemiBold", sans-serif;
  font-weight: 400;
  padding: 0.3rem 0.8rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  align-self: flex-start;
  align-items: center;
  // margin-left: 10rem;
  // margin-bottom: 0;
  // left: 30%;
  bottom: 0.5rem;
  cursor: pointer;
}

.entire-div {
  position: relative;
  overflow: hidden;
  // margin-top: 55px;
  background-color: #f0f2f5 !important;
  width: 100%;
  height: 100%;
  padding-bottom: 7vh;
  /* min-height: 73vh; */
  // padding-bottom: 7vh; /* Footer height */
  display: block;
  justify-content: center;
  z-index: 0;
  @media only screen and (min-width: 900px) {
    .card-footer2 {
      display: block !important;
    }
  }
  @media only screen and (max-width: 1000px) {
    .my-card {
      width: 90% !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .card-title {
      font-size: 1.2em !important;
    }
    .card-info-embarcacao {
      font-size: 0.8em;
      border-top-right-radius: 8px !important;
    }
    .card-passageiro {
      font-size: 0.8em;
    }
    .card-id {
      width: 50px;
    }
    .my-card {
      padding: 1rem 0rem 1rem 0rem !important;
      width: 100% !important;
    }

    .collapse-button {
      margin-top: 1rem;
      margin: 0 auto !important;
      flex-direction: row !important;
    }
    .content-flex {
      flex-direction: column !important;
    }
    .section2 {
      display: none !important;
    }
    .primeira-secao {
      width: 100%;
    }
    .content-qrcode {
      display: block !important;
    }
  }
}
.card-id {
  // margin-top: 16px;
  // margin-bottom: 32px;
  background-color: #fdb417;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-top: 2.4rem;
  transform: rotate(0deg);
  width: 60px;
}
.text-id {
  // position: ;
  // width: 4.4rem;
  font-size: 15px;
  font-family: "OpenSans-Regular", sans-serif;
  color: white;
  transform: rotate(-90deg);
  width: 80px;
}
.collapse-button {
  margin-top: 1rem;
  visibility: hidden;
  height: 0;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .primeira-secao {
    display: flex;
    color: #35415e;
    flex-direction: column;
    align-content: center;
    align-self: center;
    font-family: "OpenSans-Bold", sans-serif;
  }

  .primeira-secao-img {
    display: flex;
    align-content: center;
    // align-items: center;
  }
  .segunda-secao {
    // display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }
  img {
    filter: invert(18%) sepia(52%) saturate(466%) hue-rotate(184deg)
      brightness(43%) contrast(223%);
  }
  .terceira-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: space-around;
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }

  .quarta-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    color: #35415e;
    margin-top: 8px;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }

  .quinta-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    color: #35415e;
    margin-top: 8px;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      margin-left: 12px;
      width: 20px;
      cursor: pointer;
    }
  }
  .card-info-embarcacao {
    padding: 1rem 1rem;
    // margin-top: 10px;
    // height: 419px;
    // width: 100%;
    p {
      font-size: 0.8em;
    }
    background-color: white;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    .text-field-escala {
      margin-left: 26px;
      font-family: "OpenSans-Regular", sans-serif;
      font-size: 12px;
    }
  }
  .text-field-label {
    font-family: "OpenSans-Regular", sans-serif;
    color: #35415e;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 8px;
    margin-left: 30px;
  }
  .text-field-label2 {
    margin-left: 8px;
    font-size: 15px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-label3 {
    margin-left: 60px;
    font-size: 15px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-label4 {
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    margin-bottom: 12px;
    margin-left: 30px;
  }

  .text-acomodacao {
    // background-color: #fdb417;
    // border: 2px solid #fdb417;
    padding: 5px 8px;
    margin: 5px;
    border-radius: 22px;
    margin-left: 30px;
    color: #2a3550;
    font-size: 14px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-acomodacao {
    margin-left: 30px;
    font-size: 12px;
    font-family: "OpenSans-Regular", sans-serif;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .text-field-embarcacao {
    margin-left: 30px;
    font-size: 14px;
    font-family: "OpenSans-Bold", sans-serif;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .card-passageiro {
    display: flex;
    // flex-direction: column;
    // width: 100%;
    // margin-bottom: 2rem;
    padding: 1rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #35415e;
    .primeira-secao {
      display: flex;
      color: #35415e;
      flex-direction: column;
      align-content: center;
      font-family: "OpenSans-Bold", sans-serif;
    }

    .primeira-secao-img {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      p {
        margin-left: 1rem;
      }
    }
    img {
      filter: none;
    }
    p {
      margin-left: 3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.4rem;
      color: white;
    }
    .card-footer {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      p {
        color: white;
        font-family: "OpenSans-Regular", sans-serif;
        margin: 0px;
        margin-left: 2px;
        margin-right: 4rem;
        font-size: 10px;
      }
      img {
        width: 100px;
      }
    }
    .text-field-label {
      margin: 0;
      margin-left: 3rem;
    }
  }
  .qr-background {
    background-color: white;
    margin-left: 3rem;
    border-radius: 8px;
    width: 150px;
    height: 150px;
    padding: 0.5rem;
  }
}

.aviso-sem-passagem {
  border-radius: 0.5rem;
  width: 50%;
  padding-top: 15rem;
  margin: 0 auto;
  text-align: center;
  font-family: "OpenSans-Regular", sans-serif;

  .titulo-aviso {
    position: relative;
    background-color: #344675;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-weight: 600;
    font-size: 1.5em;

    h1 {
      padding: 1rem;
      margin: 0;
      font-family: "OpenSans-Regular", sans-serif;
      color: white;
    }
  }
  .descricao-aviso {
    border-top: 3px dashed #cccccc;
    background-color: #35415e;
    color: white;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1em;

    p {
      padding: 1rem;
    }

    span {
      color: #888888;
    }
  }
}

.icon-aviso {
  filter: invert(86%) sepia(84%) saturate(2%) hue-rotate(206deg)
    brightness(123%) contrast(100%);
  float: left;
  height: 15vh;
  display: none;
}

.lista-de-cards {
  padding-top: 4vh;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 500;
  color: #344675;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
  .mais-detalhes {
    padding: 10px 24px;
    background-color: #009aa0;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
  }
  .menos-detalhes {
    padding: 10px 24px;
    background-color: transparent;
    display: none;
    border: 1px solid #009aa0;
    border-radius: 6px;
    color: white;
    cursor: pointer;
  }
  .mais-detalhes :hover {
    cursor: pointer;
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }

  .my-card {
    padding: 1.5rem;
    width: 80%;
    border-radius: 0.5rem;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.231);
    @media only screen and (max-width: 600px) {
      width: 90%;
    }
    .card-upper {
      display: block;
      margin-bottom: 0;
      text-align: center;

      .card-title {
        font-size: 1.5rem;
        font-weight: 500;
        color: white;
      }

      .descricao-viagem {
        .subtitles {
          color: gray;
          margin: 0.2rem 0;
        }
      }

      .div-da-thumb {
        .thumbnailDoCard {
          height: 8rem;
          float: right;
        }
      }
    }

    // .primeira-secao {
    //   display: flex;
    //   flex-wrap: wrap;
    //   margin-top: 8px;
    //   padding-bottom: 2%;
    //   border-top: 2px dashed #cccccc;
    //   padding-top: 2%;
    //   border-bottom: 3px dashed #cccccc;
    //   margin-bottom: 2%;
    // }

    .segunda-secao {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
}

.titulo-col {
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 0.3rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
  color: #344675;
  border-bottom: 1px dashed #cccccc;
}

div .col-esquerda {
  border: 8px solid #cccccc;
  padding: 0.5rem;
  vertical-align: top;
  // flex: 1 1 0px;

  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.25rem;

  p:nth-child(even) {
    background-color: #cccccc;
  }
}

div .col-direita {
  border: 8px solid #cccccc;
  padding: 0.5rem;
  vertical-align: top;
  flex: 1 1 0px;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.25rem;

  p:nth-child(even) {
    background-color: #cccccc;
  }
}

.bolded {
  font-weight: 400;
  color: #303030;
  margin-right: 0.5rem;
}

.dadosDoPassageiro {
  p {
    overflow-wrap: normal;
    height: 100%;
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.dadosDaVenda {
  p {
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.dadosDaEmbarcacao {
  p {
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.gerarPdfbutton {
  display: block;
  margin: 1rem auto;
  background-color: #b93535;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  padding: 0.3rem 0.8rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: white;
}

.passagens-list {
  margin-bottom: 2rem;
}

.divbotao {
  margin-bottom: 1rem;
  .botaoVerde {
    display: block;
    margin: 1rem auto;
    background-color: #39b935;
    font-family: "OpenSans-Regular", sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.8rem;
    border: 0;
    border-radius: 0.5rem;
    font-size: 100%;
    text-align: center;
    margin-bottom: 0;
    cursor: pointer;
    color: #35415e;
    width: 40%;
  }
}

.buttonIcon {
  vertical-align: middle;
  margin-right: 1rem;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(262deg)
    brightness(109%) contrast(101%);
}

button:hover {
  transform: translate(0px, -2px);
  // box-shadow: 0px 1px 10px 0px #35415E;
  // transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

#qrimage {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  image-rendering: pixelated;
}

div #col-qrcode {
  display: flex;
  align-self: center;
  justify-content: center;
}

.fale-conosco-redirect:hover {
  text-decoration: underline;
}

.pdf-element {
  background-color: #fff;
  color: #626262;
  font-family: "Roboto", sans-serif;
}
strong {
  font-weight: 700;
}
header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 50px;
  height: 100px;
  font-family: "Roboto", sans-serif;

  img {
    height: 53px;
    width: 223px;
  }

  .header-info {
    font-size: 14px;
    line-height: 14px;
  }
}

main {
  font-size: 14px;
  display: flex;
  flex-direction: column;

  margin-left: 50px;

  font-family: "Roboto", sans-serif;

  h2.section-title {
    font-size: 16px;
    font-weight: 700;
    margin: 30px 0 10px 0;
  }

  .passageiro-info {
    display: flex;
    gap: 30px;
  }

  .right-side,
  .left-side,
  .travel-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .double-line {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  .qrcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    margin: 50px 0;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .box-infos {
    background-color: #f0f2f5;
    margin-left: -50px;
    padding: 25px 35px 30px 40px;

    h2.section-title {
      margin: 0 0 10px 0;
    }

    ol {
      margin-left: 5px;
      white-space: wrap;
      line-height: 25px;
    }
  }
}
footer.pdf-footer {
  background-color: #2a3550;
  color: #fff;
  font-size: 10px;
  font-family: "Roboto", sans-serif;

  /* height: 55px; */
  padding: 18px 180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  white-space: nowrap;
}
</style>
