<template>
  <div class="entire-div">
    <div class="div-caminho">
      <Caminho :actualStep="4" />
    </div>
    <!-- <div v-if="this.isEmpty" class="half-circle-spinner">
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
    </div>
    <p v-if="this.isEmpty" class="text-header2">
      Carregando seu bilhete...
    </p> -->
    <div style="width: fit-content; margin: 0 auto">
      <div class="ticket">
        <p class="text-card">
          Compra confirmada! Veja os dados e os bilhetes da sua viagem.
        </p>
        <div class="ticket-head">
          <div class="ticket-head-left">
            <div>
              <label class="textTop">Número do pedido</label>
              <label class="textBottom">{{
                dadosViagem.lista[0].celer_pagamento_id.substring(0, 6)
              }}</label>
            </div>

            <div>
              <label class="textTop">Origem - Destino</label>
              <label class="textBottom"
                >{{ dadosViagem.dadosComuns.cidade_origem }} -
                {{ dadosViagem.dadosComuns.cidade_destino }}</label
              >
            </div>

            <div>
              <label class="textTop">Embarcação</label>
              <label class="textBottom">{{
                dadosViagem.dadosComuns.embarcacao
              }}</label>
            </div>

            <div>
              <label class="textTop">Local de partida</label>
              <label class="textBottom">Porto de Manaus</label>
            </div>

            <div>
              <label class="textTop">Saída</label>
              <label class="textBottom"
                >{{ formatData(dadosViagem.lista[0].dt_saida) }} -
                {{
                  formataHora(
                    dadosViagem.lista[0].dt_saida.split(" ").splice(-1)[0]
                  )
                }}h</label
              >
            </div>
          </div>

          <div class="ticket-head-right">
            <label class="textTop">Valor Total</label>
            <label class="textBottom value">{{
              dadosViagem.dadosComuns.valor.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}</label>
          </div>
        </div>
        <div style="position: relative">
          <p class="text-card2">Bilhetes de Viagem</p>
          <button
            class="btn-pdfAll"
            @click="gerarTodosPdfEmail(dadosViagem.lista)"
            style="margin-right: 16rem; background: #f0f0f0; color: #626262"
          >
            <b-icon icon="download" style="margin-right: 0.5rem" />
            ENVIAR BILHETES POR EMAIL
          </button>
          <button class="btn-pdfAll" @click="gerarTodosPdf()">
            <b-icon
              icon="download"
              style="color: #ffffff; margin-right: 0.5rem"
            />
            BAIXAR BILHETES EM PDF
          </button>
        </div>
        <div
          class="ticket-body"
          v-for="(item, index) in this.dadosViagem.lista"
          :key="index"
        >
          <div
            class="ticket-body-passageiro"
            v-b-toggle="'collapse' + index"
            style="cursor: pointer"
          >
            <div class="ticket-body-passageiro-left">
              <div
                style="
                  justify-content: space-between;
                  display: flex;
                  flex-direction: row;
                  width: 50%;
                "
              >
                <div>
                  <label class="textTop passageiro-nome"
                    >Passageiro {{ index + 1 }}</label
                  >
                  <label class="textBottom">{{ item.passageiro.nome }}</label>
                </div>
                <div>
                  <label class="textTop">N° Documento</label>
                  <label class="textBottom">{{ item.passageiro.rg }}</label>
                </div>
                <div class="ticket-body-passageiro-right">
                  <div>
                    <label class="textTop">Nº do Ticket</label>
                    <label class="textBottom">00000{{ index + 1 }}</label>
                  </div>
                </div>
              </div>
              <b-icon
                icon="caret-down"
                width="1.2rem"
                height="1.2rem"
                style="color: #626262; cursor: pointer; outline: 0"
              />
            </div>
            <b-collapse :id="'collapse' + index">
              <div class="passageiro-info">
                <div class="passageiro-info-left">
                  <div class="top">
                    <div>
                      <label class="textBottom"
                        ><span style="font-weight: 500">Nome Completo:</span>
                        {{ item.passageiro.nome }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500"
                          >Data de nascimento:</span
                        >
                        {{ formatData(item.passageiro.dt_nascimento) }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500">Nacionalidade:</span>
                        {{
                          capitalizeFirstLetter(item.passageiro.nacionalidade)
                        }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500">RG:</span>
                        {{ formataRg(item.passageiro.rg) }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500">CPF:</span>
                        {{ formataCPF(item.passageiro.cpf) }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500">Gênero:</span>
                        {{
                          capitalizeFirstLetter(
                            retornaGenero(item.passageiro.sexo)
                          )
                        }}</label
                      >
                      <label class="textBottom"
                        ><span style="font-weight: 500">Celular:</span>
                        {{ formatarTelefone(item.passageiro.telefone) }}</label
                      >
                    </div>

                    <div>
                      <div style="flex-grow: 1">
                        <div>
                          <label class="textBottom"
                            ><span style="font-weight: 500">Acomodação:</span>
                            Rede</label
                          >
                          <label class="textBottom"
                            ><span style="font-weight: 500">Alimentação:</span>
                            Inclusa</label
                          >
                        </div>
                      </div>
                      <label class="textBottom"
                        ><span style="font-size: 16px; font-weight: 500"
                          >VALOR UNITÁRIO</span
                        ></label
                      >
                      <label class="value">{{
                        item.valor_total.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}</label>
                    </div>
                    <div style="flex-direction: row">
                      <div style="margin-right: 1rem">
                        <div>
                          <label class="textTop" style="text-align: right"
                            >NÚMERO DO TICKET</label
                          >
                          <label class="textBottom" style="text-align: right"
                            >00000{{ index + 1 }}</label
                          >
                        </div>
                        <label class="textBottom" style="font-size: 12px"
                          >(Apresentar este código no embarque)</label
                        >
                      </div>
                      <div>
                        <img :src="item.qrcode" id="qrimage" />
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <label>*Peso máximo de bagagem até 10 quilos.</label>
                    <label>**Apresentar RG no embarque</label>
                    <label
                      >***Taxa portuária não inclusa nesta compra e deverá ser
                      paga no porto.</label
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin-top: 1.2rem;
                    "
                  >
                    <button class="btn-pdf" @click="gerarPdf(item, index)">
                      <b-icon
                        icon="download"
                        style="color: #626262; margin-right: 0.5rem"
                      />
                      Baixar em PDF
                    </button>
                  </div>
                </div>
                <!-- <div class="passageiro-info-right">
                </div> -->
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="divbotao">
          <button
            class="botaoVerde"
            v-if="this.dadosViagem != ''"
            @click="voltar('')"
            type="info"
            style="
              margin-right: 2rem;
              background: transparent;
              color: #626262;
              border: 1px solid #009aa0;
            "
          >
            IR PARA TELA INICIAL
          </button>

          <button
            class="botaoVerde"
            v-if="this.dadosViagem != ''"
            @click="finalizar"
            type="info"
          >
            IR PARA MINHAS PASSAGENS
          </button>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <div
        v-for="(item, index) in this.dadosViagem.lista"
        :key="index"
        style="padding-bottom: 1rem; width: fit-content; margin: 0 auto"
      >
        <div
          :id="'pdf-element-' + index"
          class="pdf-element"
          style="position: relative"
          hidden
        >
          <div
            style="
              background-color: #2a3550;
              position: relative;
              display: flex;
              justify-content: center;
            "
          >
            <img
              :src="NvgamBg"
              class="imgBg"
              style="
                width: 100%;
                height: 120px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              "
            />
            <img
              :src="MarcaNavegam"
              style="position: absolute; left: 38%; top: 30%"
            />
          </div>
          <p
            style="
              color: #2a3550;
              font-family: 'OpenSans-Bold', sans-serif;
              margin-top: 8px;
              margin-bottom: 4rem;
              text-align: center;
              font-size: 20px;
            "
          >
            Obrigado pela compra, boa viagem!
          </p>
          <div style="display: flex; justify-content: space-between">
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                padding-left: 1rem;
                padding-top: 1rem;
              "
            >
              <p
                class="header-pdf;"
                style="
                  color: #2a3550;
                  font-family: 'OpenSans-Bold', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Dados do passageiro
              </p>
              <br />
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Passageiro: {{ item.passageiro.nome }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                CPF: {{ formataCPF(item.passageiro.cpf) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                RG: {{ formataRg(item.passageiro.rg) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Data de nascimento:
                {{ formatData(item.passageiro.dt_nascimento) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Nacionalidade:
                {{ capitalizeFirstLetter(item.passageiro.nacionalidade) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Sexo: {{ capitalizeFirstLetter(item.passageiro.sexo) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Telefone: {{ formatarTelefone(item.passageiro.telefone) }}
              </p>
              <br />
              <p
                class="header-pdf"
                style="
                  color: #2a3550;
                  font-family: 'OpenSans-Bold', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Dados da embarcação:
              </p>
              <br />
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Embarcação:
                {{ capitalizeFirstLetter(item.embarcacao_nome) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Linha: De
                {{ capitalizeFirstLetter(item.cidade_origem) }} - para
                {{ capitalizeFirstLetter(item.cidade_destino) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Data da viagem: {{ formatData(item.dt_saida) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Origem: {{ capitalizeFirstLetter(item.cidade_origem) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Destino: {{ capitalizeFirstLetter(item.cidade_destino) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Local de saída: Porto de Manaus
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Telefone: (92) 98413-2558
              </p>
              <br />
              <p
                class="header-pdf"
                style="
                  color: #2a3550;
                  font-family: 'OpenSans-Bold', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Dados da passagem
              </p>
              <br />
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Vendedor: Agencia Navegam
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Valor pago: R${{ item.valor_total }},00
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Forma de pagamento:
                {{
                  capitalizeFirstLetter(
                    formataFormaPagamento(item.forma_pagamento)
                  )
                }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                Data da compra: {{ formatData(item.dt_compra) }}
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                taxa portuaria não inclusa,
              </p>
              <p
                style="
                  color: gray;
                  font-family: 'OpenSans-Regular', sans-serif;
                  margin-bottom: 4px;
                "
              >
                a mesma deverá ser realizada no porto.
              </p>
              <br />
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <img
                :src="item.qrcode"
                style="width: 250px; align-self: center"
              />
              <br />
              <div style="background-color: #2a3550; padding: 2rem">
                <p
                  class="header-pdf"
                  style="
                    color: white;
                    font-family: 'OpenSans-Bold', sans-serif;
                    margin-bottom: 4px;
                  "
                >
                  Informações importantes
                </p>
                <br />
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                    text-align: justify;
                  "
                >
                  - Imprima este comprovante e apresnete junto com o documento
                  de identificação na embarcação.
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                    text-align: justify;
                  "
                >
                  - Planeje-se para a chegada com antecedência.
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                    text-align: justify;
                  "
                >
                  - O fornecimento do serviço de alimentação depende das regras
                  das embarcações e rotas. Para mais informações entre em
                  contato com a embarcação.
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                    text-align: justify;
                  "
                >
                  - É obrigatório a apresentação dos documentos com foto no
                  momento do embarque.
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                    text-align: justify;
                  "
                >
                  - Passageiro(a), para maiores informações do seu direito ou
                  dever, basta acessar o link abaixo
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'OpenSans-Regular', sans-serif;
                    margin-bottom: 4px;
                  "
                >
                  <a href="https://cutt.ly/DghCGsC" target="_blank"
                    >clique aqui</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div
            style="
              background-color: white;
              position: relative;
              text-align: center;
              margin-top: 6.5rem;
            "
          >
            <img
              :src="NvgamBg"
              style="
                width: 100%;
                height: 160px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              "
            />
            <p
              style="
                color: #2a3550;
                font-family: 'OpenSans-Regular', sans-serif;
                position: absolute;
                left: 50%;
                top: 20%;
                transform: translate(-50%, -50%);
              "
            >
              NAVEGAM - SISTEMAS DE AGENCIAMENTO DE PASSAGENS
            </p>
            <p
              style="
                color: #2a3550;
                font-family: 'OpenSans-Regular', sans-serif;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              "
            >
              Rua do Sol, 300, Manaus/AM, 69095210
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterUserAllForm from "./Profile/RegisterUserAllForm";
import RegisterAdmForm from "./Profile/RegisterAdmForm";
import sagrado from "../../public/img/sagrado.png";
import config from "@/config";
import api from "../api";
import moment from "moment";
import Swal from "sweetalert2";
import QrcodeVue from "qrcode.vue";
import _ from "lodash";
import IconPdf from "@/assets/icons/pdf.svg";
import IconCheck from "@/assets/icons/check.svg";
import Caminho from "@/components/compras/Caminho.vue";
import axios from "axios";
import IconBarco from "@/assets/icons/icon_barco.svg";
import IconCalendar from "@/assets/icons/icon_calendar.svg";
import IconClock from "@/assets/icons/icon_clock.svg";
import IconPerson from "@/assets/icons/person.svg";
import MarcaNavegam from "@/assets/MARCA_NAVEGAM.svg";
import IconDownload from "@/assets/icons/Icondownload.svg";
import NvgamBg from "@/assets/icons/nvgam_bg.png";
export default {
  components: {
    QrcodeVue,
    RegisterUserAllForm,
    RegisterAdmForm,
    Caminho
  },
  data() {
    return {
      // Icons
      dadosViagem: this.$store.state.data_trip[
        this.$store.state.dadosViagem.key
      ],
      passageiros: this.$store.state.passageiros,
      IconPdf,
      IconCheck,
      sagrado,

      info: {
        nome: "Lucas",
        rg: "1234567-8",
        nascimento: "12/12/1221",
        celular: "(92)9999-9999",
        sexo: "M",
        pais: "Brasileiro"
      },
      isEmpty: true,
      qrSrc: "",
      qtdPassageiros: null,
      user: {},
      data_saida_hora_pdf: "",
      data_saida_pdf: "",
      valorTotal: 0,
      dt_saida: [],
      dadosViagem: "",
      embarcacao_id: null,
      agencias: [],
      IconCalendar,
      IconClock,
      IconPerson,
      MarcaNavegam,
      NvgamBg,
      IconBarco,
      IconDownload
    };
  },
  computed: {},
  methods: {
    formatData(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);
        const hora = data.substring(12, 21);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    finalizar() {
      this.$router.push("/meustickets");
    },
    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    formataCPF(cpf) {
      var dig1 = cpf.substring(0, 3);
      var dig2 = cpf.substring(3, 6);
      var dig3 = cpf.substring(6, 9);
      var dig4 = cpf.substring(9, 12);
      var cpf = `${dig1}.${dig2}.${dig3}-${dig4}`;
      return cpf;
    },
    formatarTelefone(number) {
      var dig1 = number.substring(0, 2);
      var dig2 = number.substring(2, 7);
      var dig3 = number.substring(7, 11);
      var number = `(${dig1}) ${dig2}-${dig3}`;
      return number;
    },
    retoraGenero(x) {
      switch (x) {
        case "m":
          return "Masculino";
          break;
        case "f":
          return "Femenino";
          break;
        case "o":
          return "Outros";
          break;
      }
    },
    formataRg(rg) {
      var dig1 = rg.substring(0, 7);
      var dig2 = rg.substring(7, 9);
      rg = `${dig1}-${dig2}`;
      return rg;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    retornaGenero(letra) {
      return (
        {
          M: "masculino",
          F: "feminino",
          O: "outros"
        }[letra.toLocaleUpperCase()] || ""
      );
    },
    FinalizarCompra() {
      const config2 = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`
        }
      };
      var array = [];
      this.$store.state.passageiros.forEach(element => {
        array.push(element.valorPorPassageiro);
      });
      const dados = {
        valor_viagem: array,
        endereco: this.$store.state.dadosPucharse[0]
      };

      this.axios.post(`${api}/pagamento/boleto`, dados, config2).then(res => {
        window.open(res.data.pdf);
      });
    },
    voltar(rota) {
      this.$router.push(`/${rota}`);
    },
    gerarPdf(lista, indexout) {
      var formataNome = lista.passageiro.nome
        .replace(/\s/g, "_")
        .toLocaleLowerCase();
      //removendo espaços em branco e minimizando os caracteres

      var element = document.getElementsByClassName("pdf-element")[indexout]
        .innerHTML;

      var opt = {
        margin: 0,
        filename: `${formataNome}_passagem.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
      };
      html2pdf()
        .from(element)
        .set(opt)
        .save();
    },
    async gerarTodosPdfEmail(lista) {
      var numeroPdfs = this.dadosViagem.lista;
      const isSuccess = true;
      numeroPdfs.map(async (item, index) => {
        var formataNome = item.passageiro.nome
          .replace(/\s/g, "_")
          .toLocaleLowerCase();
        //removendo espaços em brano e minimizando os caracteres
        var element = document.getElementsByClassName("pdf-element")[index]
          .innerHTML;
        var opt = {
          margin: 0,
          filename: `${formataNome}_passagem.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
        };

        const worker = await html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .output("blob", { filename: formataNome })
          .then(data => {
            return data;
          });

        const formData = new FormData();
        formData.append("files", worker);
        worker.originalname = formataNome;
        const headers = {
          Authorization: `Bearer ${this.$store.state.session.token}`
        };

        await axios
          .post(
            `${api}/v2/passagens/compra/email?pid=${lista[0].celer_pagamento_id}`,
            formData,
            { headers: headers }
          )
          .then(response => {
            response.data._mailSuccess === true
              ? (isSuccess = true)
              : (isSuccess = false);
          });
      });
      if (isSuccess) {
        Swal.fire({
          icon: "success",
          title: "Olhe seu Email!",
          text: "Seu bilhete foi enviado com sucesso!",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocorreu um erro!",
          text: "Não foi possível enviar os bilhetes para o email!",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    gerarTodosPdf() {
      var numeroPdfs = this.dadosViagem.lista;
      numeroPdfs.map((item, index) => {
        var formataNome = item.passageiro.nome
          .replace(/\s/g, "_")
          .toLocaleLowerCase();
        //removendo espaços em brano e minimizando os caracteres
        var element = document.getElementsByClassName("pdf-element")[index]
          .innerHTML;
        var opt = {
          margin: 0,
          filename: `${formataNome}_passagem.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
        };
        html2pdf()
          .from(element)
          .set(opt)
          .save();
      });
    },
    formataTelefone(tel) {
      tel = tel
        .replace("-", "")
        .replace(" ", "")
        .replace("(", "")
        .replace(")", "");

      return tel;
    },
    formataFormaPagamento(formaPagamento) {
      if (formaPagamento == "cartao_credito") {
        formaPagamento = "Cartão Crédito";
      } else {
        formaPagamento = "Cartão Débito";
      }
      return formaPagamento;
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.bigLineChart.allData[index]
          }
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ]
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  async mounted() {
    var vm = this;
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function() {
      if (vm.$router.history.current.path == "/purchase") {
        Swal.fire({
          title: "Tem certeza?",
          text: "Os dados serão perdidos!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim"
        }).then(result => {
          if (result.value) {
            vm.$router.push("/");
          }
        });
      }
      window.history.pushState(null, "", window.location.href);
    };

    if (
      this.$store.state.dadosViagem.id == undefined ||
      this.$store.state.session.auth != true
    ) {
      this.$router.push("/");
    }

    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };

    try {
      const data = (
        await axios.get(`${api}/usuarios/perfil/passagens`, config2)
      ).data;
      let dadosPassagens = [...data];
      let passageirosAgrupados = _.groupBy(
        dadosPassagens,
        "celer_pagamento_id"
      );
      let resultado = [];

      for (var pa in passageirosAgrupados) {
        let objInterno = {
          dadosComuns: {
            viagem: "",
            passageiros: [],
            embarcacao: "",
            valor: 0,
            vendedor: ""
          },
          lista: []
        };
        objInterno.lista = passageirosAgrupados[pa];

        for (var pain in passageirosAgrupados[pa]) {
          let info = passageirosAgrupados[pa][pain];
          objInterno.dadosComuns.viagem =
            "De " + info.cidade_origem + " - para " + info.cidade_destino;
          objInterno.dadosComuns.embarcacao = info.embarcacao_nome;
          objInterno.dadosComuns.vendedor = info.vendedor_id;
          objInterno.dadosComuns.data_compra = info.dt_compra;
          objInterno.dadosComuns.cidade_origem = info.cidade_origem;
          objInterno.dadosComuns.cidade_destino = info.cidade_destino;
          objInterno.dadosComuns.forma_pagamento = info.forma_pagamento;
          objInterno.dadosComuns.passageiros.push(info.passageiro.nome);
          objInterno.dadosComuns.valor += info.valor_total;
        }
        resultado.push(objInterno);
      }

      this.dadosViagem = resultado[0];
      var qr;
      for (let i = 0; i < this.dadosViagem.lista.length; i++) {
        qr = new VanillaQR({
          url: this.dadosViagem.lista[i].qrcode,
          width: 220,
          height: 220,
          colorLight: "white",
          colorDark: "black",
          toTable: false,
          ecclevel: 1,
          noBorder: true,
          borderSize: 1
        });
        var imageElement = qr.toImage("png");
        this.dadosViagem.lista[i].qrcode = imageElement.src;
      }
    } catch (error) {
      console.error(error);
    }
  },

  beforeDestroy() {}
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Bold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

.text-card {
  color: #2a3550;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  margin-bottom: 1rem;
}
.text-card2 {
  color: #626262;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.ticket-head {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  background-color: #ffffff;
  color: #626262;
  padding: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: fit-content;
  align-items: center;
  .ticket-head-left {
    display: flex;
    width: 50rem;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
    }
  }
  .ticket-head-right {
    width: 15rem;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }
}

.textTop,
.textBottom {
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  color: #626262;
  /* line-height: 19px; */
}
.textTop {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.value {
  font-size: 36px;
}
.passageiro-nome {
  font-size: 22px;
}
.textBottom {
  font-weight: normal;
}
.btn-pdfAll {
  position: absolute;
  cursor: pointer;
  right: 1rem;
  bottom: 0.2rem;
  background: #009aa0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
}
.ticket-body {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  .ticket-body-passageiro {
    display: flex;
    flex-direction: column;
    .ticket-body-passageiro-left {
      width: 65rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        flex-direction: column;
      }
    }
    .ticket-body-passageiro-right {
      /* width: 35rem; */
      /* align-items: flex-end; */
      display: flex;
      flex-direction: column;
    }
    .passageiro-info {
      display: flex;
      .passageiro-info-left {
        flex-grow: 1;
      }
      .passageiro-info-right {
        margin: 1rem;
        align-items: end;
      }
      .btn-pdf {
        background: #f0f0f0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        border: none;
        padding: 0.5rem 1rem;
        color: #626262;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 2px dashed #009aa0;
      .top {
        display: flex;
        justify-content: space-between;

        .textBottom {
          font-size: 15px;
          /* margin-bottom: 0.2rem; */
        }
        .value {
          font-family: Roboto;
          font-style: normal;
          font-size: 36px;
          color: #626262;
          font-weight: normal;
          /* line-height: 19px; */
        }
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .bottom {
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        label {
          font-size: 12px;
          font-family: Roboto, sans-serif;
          color: #626262;
          font-style: italic;
        }
      }
    }
  }
}

.text-header2 {
  font-family: "OpenSans-SemiBold", sans-serif;
  color: black;
  font-size: 22px;
  margin: 0 auto;
  margin-top: 25rem;
  text-align: center;
}
.text-header {
  color: #626262;
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 30px;
  text-align: center;
  margin-bottom: 1rem;
  /* margin-top: 12vh; */
}
.half-circle-spinner,
.half-circle-spinner {
  box-sizing: border-box;
}

.half-circle-spinner {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  top: 16vh;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: black;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: black;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-id {
  // margin-top: 16px;
  // margin-bottom: 32px;
  background-color: #fdb417;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-top: 1.4rem;
  transform: rotate(0deg);
  width: 40px;
}
.text-id {
  // position: ;
  // width: 4.4rem;
  font-size: 15px;
  font-family: "OpenSans-Regular", sans-serif;
  color: white;
  transform: rotate(-90deg);
  width: 80px;
  height: 47px;
}
.entire-div {
  // padding-top: 14vh;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 500;
  color: #344675;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  /* min-height: 93vh; */
  padding-bottom: 7vh;

  @media only screen and (min-width: 900px) {
    .card-footer2 {
      display: block !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .divbotao {
      margin-right: 0rem;
    }
    .collapse-button {
      margin: 0 auto;
      flex-direction: row;
    }
    .content-flex {
      flex-direction: column;
    }
    .section2 {
      display: none;
    }
    .primeira-secao {
      width: 100%;
    }
    .content-qrcode {
      display: block !important;
    }
  }

  // .my-card {
  //   padding: 0.5rem;
  //   width: 50%;
  //   border-radius: 0.5rem;
  //   margin: 0 auto;
  //   background-color: white;
  //   @media only screen and (max-width: 600px) {
  //     width: 80%;
  //   }

  //   .card-upper:hover {
  //     background-color: #dddddd;
  //     cursor: pointer;
  //   }

  //   .card-upper {
  //     display: block;
  //     margin-bottom: 0;
  //     text-align: center;

  //     .card-title {
  //       font-size: 1.5rem;
  //       font-weight: 500;
  //     }

  //     .descricao-viagem {
  //       .subtitles {
  //         color: gray;
  //         margin: 0.2rem 0;
  //       }
  //     }

  //     .div-da-thumb {
  //       .thumbnailDoCard {
  //         height: 8rem;
  //         float: right;
  //       }
  //     }
  //   }

  //   .primeira-secao {
  //     display: flex;
  //     flex-wrap: wrap;
  //     padding-bottom: 2%;
  //     border-top: 2px dashed #cccccc;
  //     padding-top: 2%;
  //     border-bottom: 3px dashed #cccccc;
  //     margin-bottom: 2%;
  //   }

  //   .segunda-secao {
  //     display: flex;
  //     justify-content: space-around;
  //     flex-wrap: wrap;
  //   }
  // }
}
.collapse-button {
  // margin-top: 1rem;
  display: flex;
  justify-content: center;
  .primeira-secao {
    margin-top: 1rem;
    display: flex;
    color: #35415e;
    flex-direction: column;
    align-content: center;
    align-self: center;
    font-family: "OpenSans-Bold", sans-serif;
  }

  .primeira-secao-img {
    display: flex;
    align-content: center;
    // align-items: center;
  }
  .segunda-secao {
    // display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }
  img {
    filter: invert(18%) sepia(52%) saturate(466%) hue-rotate(184deg)
      brightness(43%) contrast(223%);
  }
  .terceira-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }

  .quarta-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    color: #35415e;
    margin-top: 8px;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      width: 20px;
    }
  }

  .quinta-secao {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-self: center;
    align-items: center;
    color: #35415e;
    // margin-top: 8px;
    font-family: "OpenSans-Regular", sans-serif;
    img {
      margin-left: 12px;
      width: 20px;
      cursor: pointer;
    }
  }
  .card-info-embarcacao {
    padding: 1rem 1rem;
    // margin-top: 10px;
    // margin-bottom: 2rem;
    // width: 100%;
    background-color: white;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    .text-field-escala {
      margin-left: 76px;
      font-family: "OpenSans-Regular", sans-serif;
      font-size: 12px;
    }
  }
  .text-field-label {
    font-family: "OpenSans-Regular", sans-serif;
    color: #35415e;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 8px;
    margin-left: 30px;
  }
  .text-field-label2 {
    margin-left: 8px;
    font-size: 15px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-label3 {
    margin-left: 60px;
    font-size: 15px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-label4 {
    color: #35415e;
    font-family: "OpenSans-Regular", sans-serif;
    margin-bottom: 12px;
    margin-left: 30px;
  }

  .text-acomodacao {
    // background-color: #fdb417;
    // border: 2px solid #fdb417;
    padding: 5px 8px;
    margin: 5px;
    border-radius: 22px;
    margin-left: 30px;
    color: #2a3550;
    font-size: 14px;
    font-family: "OpenSans-Bold", sans-serif;
  }
  .text-field-acomodacao {
    margin-left: 30px;
    font-size: 12px;
    font-family: "OpenSans-Regular", sans-serif;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .text-field-embarcacao {
    margin-left: 30px;
    font-size: 14px;
    font-family: "OpenSans-Bold", sans-serif;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .card-passageiro {
    display: flex;
    flex-direction: row;
    // width: 100%;
    // margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #35415e;
    .primeira-secao {
      display: flex;
      color: #35415e;
      flex-direction: column;
      align-content: center;
      font-family: "OpenSans-Bold", sans-serif;
    }

    .primeira-secao-img {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      p {
        margin-left: 1rem;
      }
    }
    img {
      filter: none;
    }
    p {
      margin-left: 3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.4rem;
      color: white;
    }
    .card-footer {
      display: flex;
      align-items: center;
      margin-top: 2.8rem;
      p {
        color: white;
        font-family: "OpenSans-Regular", sans-serif;
        margin: 0px;
        margin-left: 2px;
        margin-right: 4rem;
        font-size: 10px;
      }
      img {
        width: 100px;
      }
    }
    .text-field-label {
      margin: 0.2rem;
      margin-left: 3rem;
    }
  }
  .qr-background {
    align-self: center;
    background-color: white;
    margin-left: 3rem;
    border-radius: 8px;
    width: 150px;
    height: 150px;
    padding: 0.5rem;
  }
}

.titulo-col {
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 0.3rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
  color: #344675;
  border-bottom: 1px dashed #cccccc;
}

div .col-esquerda {
  border: 8px solid #cccccc;
  padding: 0.5rem;
  vertical-align: top;
  flex: 1 1 0px;

  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.25rem;

  p:nth-child(even) {
    background-color: #cccccc;
  }
}

div .col-direita {
  border: 8px solid #cccccc;
  padding: 0.5rem;
  vertical-align: top;
  flex: 1 1 0px;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.25rem;

  p:nth-child(even) {
    background-color: #cccccc;
  }
}

.bolded {
  font-weight: 400;
  color: #303030;
  margin-right: 0.5rem;
}

.dadosDoPassageiro {
  p {
    overflow-wrap: normal;
    height: 100%;
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.dadosDaVenda {
  p {
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.dadosDaEmbarcacao {
  p {
    line-height: 1.2;
  }

  p::before {
    content: "- ";
  }
}

.gerarPdfbutton {
  display: flex;
  align-self: center;
  // position: absolute;
  background-color: #009aa0;
  font-family: "OpenSans-Regular", sans-serif;
  padding: 0.3rem 0.8rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  color: white;
  text-align: center;
  margin-left: 8px;
  margin-top: 1rem;
  cursor: pointer;
}

.divbotao {
  margin-top: 4rem;
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 1rem; */
  padding: 0.4rem;
  /* margin-right: 12rem; */
  // width: 90%;
  .botaoVerde {
    // position: absolute;
    display: block;
    background-color: #009aa0;
    font-family: "OpenSans-Regular", sans-serif;
    // font-weight: 400;
    padding: 0.4rem 0.4rem;
    border: 0;
    border-radius: 8px;
    font-size: 100%;
    text-align: center;
    // margin-top: 1rem;
    margin-bottom: 0;
    cursor: pointer;
    color: white;
    width: 280px;
  }
}

button:hover:not(.gerarPdfbutton) {
  transform: translate(0px, -1px);

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.gerarPdfbutton:hover {
  transform: translate(0px, -1px);
  // box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}
#qrimage {
  width: 120px;
  height: 120px;
  image-rendering: pixelated;
}
// div #qrimage {
//   padding: 0px;
//   margin: 0px;
//   @media only screen and (max-width: 360px) {
//     width: 120px;
//   }
// }

// div #col-qrcode {
//   display: flex;
//   align-self: center;
//   justify-content: center;
// }
</style>
