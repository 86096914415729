<template>
  <div class="entire-div">
    <h1 class="title">Fale Conosco</h1>
    <div class="container">
      <main>
        <div class="form-container">
          <h2 class="form-title">Envie uma mensagem</h2>
          <form v-on:submit.prevent id="myForm">
            <label for="name">Nome*</label>
            <input
              type="text"
              name="user-name"
              placeholder="Digite seu nome completo"
              class="input-field"
              ref="name_input"
              v-model="contato.name"
              v-on:input="onChangeName"
              autofocus
            />
            <p class="text-form" v-if="name_flag">
              Este campo é obrigatório
              <sup>*</sup>
            </p>
            <div class="mail-phone">
              <fieldset>
                <label for="email">Email*</label>
                <input
                  type="email"
                  name="user-email"
                  placeholder="usuario@email.com"
                  id="email"
                  class="input-field"
                  ref="email_input"
                  v-model="contato.email"
                  v-on:input="onChangeEmail"
                />
                <p class="text-form" v-if="email_flag">
                  Este campo é obrigatório.
                  <sup>*</sup>
                </p>
              </fieldset>

              <fieldset>
                <label for="phone-number">Telefone</label>
                <the-mask
                  :mask="['(##) ####-####', '(##) #####-####']"
                  type="tel"
                  minlength="11"
                  name="user-phone-number"
                  id="phone-number"
                  placeholder="(xx) xxxxx-xxxx"
                  class="input-field"
                  v-model="contato.phone"
                />
              </fieldset>
            </div>
            <label for="subject">Assunto*</label>
            <select
              name="subject-text"
              placeholder="Selecione"
              class="input-field"
              v-model="contato.subject"
              ref="select_input"
            >
              <option disabled value="">Selecione</option>
              <option value="Dúvidas gerais">Dúvidas gerais</option>
              <option value="Cancelamento de passagens">
                Cancelamento de passagens
              </option>
              <option value="Suporte">Suporte</option>
            </select>
            <p class="text-form" v-if="subject_flag">
              Este campo é obrigatório.
              <sup>*</sup>
            </p>
            <label for="content">Mensagem*</label>
            <textarea
              placeholder="Digite sua mensagem"
              name="content"
              rows="200"
              style="resize: none"
              class="input-field"
              ref="content_input"
              v-model="contato.content"
              v-on:input="onChangeContent"
            />
            <p class="text-form" v-if="content_flag">
              Este campo é obrigatório.
              <sup>*</sup>S
            </p>
            <footer class="form-footer">
              <span class="require-message">*Campos obrigatórios</span>
              <div style="align-self: center">
                <button
                  :id="submit - button"
                  type="submit"
                  class="btn-central2"
                  @click="validateFields"
                >
                  Enviar
                </button>
              </div>
            </footer>
          </form>
        </div>
      </main>
      <aside>
        <div class="aside-container">
          <h2 style="margin-left: 40px" class="aside-title">
            Como você prefere entrar em contato?
          </h2>
          <div class="contact-container">
            <div>
              <a
                class="contact"
                href="mailto:passagens@navegam.com.br"
                style="color: inherit; text-decoration: none"
              >
                <img
                  src="../assets/icons/icon-mail.svg"
                  alt="Email"
                  class="filter-svg"
                />
                <span class="contact-type">Email</span>
                <span class="contact-info">passagens@navegam.com.br</span>
              </a>
            </div>
            <div>
              <a
                class="contact"
                href="tel:+55-92-98855-2157"
                style="color: inherit; text-decoration: none"
              >
                <img
                  src="../assets/icons/icon-phone.svg"
                  alt="Telefone"
                  class="filter-svg"
                />
                <span class="contact-type">Telefone</span>
                <span class="contact-info">(92) 98855-2157</span>
              </a>
            </div>
            <div class="contact">
              <a
                class="contact"
                target="_blank"
                href="https://web.whatsapp.com/send?phone=5592988552157"
                style="color: inherit; text-decoration: none"
              >
                <img
                  src="../assets/icons/whatsapp.svg"
                  alt="Logo Whatsapp"
                  class="filter-svg"
                />
                <span class="contact-type">Whatsapp</span>
                <span class="contact-info">(92) 98855-2157</span>
              </a>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import { TheMask } from "vue-the-mask";
import Swal from "sweetalert2";

import api from "../api";

export default {
  data() {
    return {
      contato: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        content: ""
      },
      name_flag: false,
      email_flag: false,
      email_flag2: false,
      subject_flag: false,
      content_flag: false,
      flag: false,
      isActive: false,
      isDisabled: true
    };
  },

  methods: {
    goBack() {
      this.$router.push("/dashboard");
    },

    validateFields() {
      this.contato.phone.trim() == "" ? "" : `Contato: ${this.contato.phone}`;

      const body = {
        name: this.contato.name,
        email: this.contato.email,
        phone: this.contato.phone,
        subject: this.contato.subject,
        content: this.contato.content
      };

      this.axios.post(`${api}/usuarios/email`, body).then(response => {
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Sua mensagem foi enviada com sucesso!",
            text: "Em breve entraremos em contato.",
            showConfirmButton: false,
            timer: 1500
          });
          document.getElementById("myForm").reset();
        } else {
          Swal.fire({
            icon: "error",
            title: "Ocorreu um erro!",
            text: "Tente novamente.",
            showConfirmButton: false,
            timer: 1500
          });
        }
      });

      if (
        this.$refs.name_input.style.borderColor == "green" &&
        this.$refs.email_input.style.borderColor == "green" &&
        this.$refs.content_input.style.borderColor == "green"
      ) {
        console.log("opa");
        this.isDisabled = false;
      } else {
        this.contato.name == "" || this.contato.name == null
          ? ((this.name_flag = true),
            (this.$refs.name_input.style.borderColor = "red"))
          : (this.name_flag = false);

        this.contato.email == "" || this.contato.email == null
          ? ((this.email_flag = true),
            (this.$refs.email_input.style.borderColor = "red"))
          : (this.email_flag = false);

        this.contato.content == "" || this.contato.content == null
          ? ((this.content_flag = true),
            (this.$refs.content_input.style.borderColor = "red"))
          : (this.content_flag = false);

        this.contato.subject.options.selectedIndex.value == "" ||
        this.contato.subject == null
          ? ((this.subject_flag = true),
            (this.$refs.subject_input.style.borderColor = "red"))
          : (this.subject_flag = false);
      }
    },
    onChangeName() {
      console.log("style-border");
      this.name_flag = false;
      if (this.contato.name.length > 3) {
        this.$refs.name_input.style.borderColor = "green";
        this.name_flag = false;
      } else {
        this.$refs.name_input.style.borderColor = "red";
        this.name_flag = true;
      }
    },
    onChangeEmail() {
      this.email_flag = false;
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.$refs.email_input.value
        )
      ) {
        this.$refs.email_input.style.borderColor = "green";
        this.email_flag = false;
      } else {
        this.$refs.email_input.style.borderColor = "red";
      }
    },
    onChangeContent() {
      console.log(this.$refs.content_input.style);
      this.content_flag = false;
      if (this.contato.content.length > 3) {
        this.$refs.content_input.style.borderColor = "green";
        this.content_flag = false;
      } else {
        this.$refs.content_input.style.borderColor = "red";
        this.content_flag = true;
      }
    }
  },
  components: { TheMask }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");

@media screen and (max-width: 1220px) {
  .contact-container {
    flex-direction: column;
  }
  aside-container {
    margin-top: 0;
  }
}
@media screen and (max-width: 1020px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    gap: 10vh;
  }
  .contact-container {
    flex-direction: row;
    margin-bottom: 20px;
  }
  aside-container {
    margin-top: 0;
  }
}

.entire-div {
  height: 100%;
  min-height: 78vh;
  /* padding-bottom: 10vh;  */

  h1.title {
    /* text-align: center; */
    color: #2a3550;
    margin: 15vh 0 30px 10vh;
    font-size: 2.25rem;
    font-family: "Roboto", sans-serif;
  }
}

.container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 90vw;

  padding: 1rem;
  gap: 10px;
}

main {
  width: 60%;
}
.form-container {
  height: 520px;
  width: 550px;
  margin-bottom: 70px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px 0px #00000040;
  display: flex;
  flex-direction: column;

  .form-title {
    font-size: 1.75rem;
    font-family: "Roboto", sans-serif;
    color: #2a3550;
    margin: 10px 0 10px 20px;
  }
}

form {
  width: 500px;
  height: 220px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #626262;
  }

  select {
    color: #626262;
    appearance: none;
    background: url("../assets/icons/arrow-select.svg") 98% / 4% no-repeat;

    option {
      background-color: #fff;
      color: #000;
    }
  }

  input,
  textarea {
    font-family: "Roboto", sans-serif;
    color: #626262;
  }
}

.mail-phone {
  display: flex;
  flex-direction: row;
  width: 100%;

  input:first-of-type {
    margin-right: 20px;
  }

  #email,
  #phone-number {
    width: 220px;
  }
}

.input-field {
  width: initial;
  margin: 5px 0 10px;
  padding: 10px 5px 10px 12px;
  font-size: 1rem;
  border: 1px solid #626262;
  border-radius: 5px;
  outline: none;
  resize: none;

  &::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-style: italic;
    color: #a9a9a9;
  }
}

textarea {
  /* width: 50px;
  height: 40vh !important; */
  min-height: 100px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  span {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    color: #626262;
    font-style: italic;
  }
}

.btn-central2 {
  background-color: #009aa0;
  /* border: none; */
  border: none;
  outline: none;
  padding: 8px 12px;
  color: white;
  font-size: 1.125rem;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  align-self: center;
  cursor: pointer;

  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:active {
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:disabled {
    background-color: black;
  }
}

.text-form {
  margin-bottom: 0.4rem;
  // padding-bottom: 3px;
  // left: 12px;
  padding-bottom: 0.2rem;
  font-size: 0.7em;
  font-family: "Roboto", sans-serif;
  color: red;
}

/* ASIDE */

.aside-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .aside-title {
    font-size: 1.75rem;
    color: #2a3550;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

.contact-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  img {
    width: 60px;
    height: 60px;
    /* background: #2A3550; */
  }

  .contact-type {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    color: #009aa0;
  }

  .contact-info {
    font-family: "Roboto", sans-serif;
    color: #626262;
    white-space: nowrap;
  }
}

/* muda a cor do svg */
.filter-svg {
  filter: invert(17%) sepia(8%) saturate(3574%) hue-rotate(185deg)
    brightness(90%) contrast(86%);
}
</style>
