<template>
  <div>
    <div class="entire-page">
      <div class="load visible">
        <div class="half-circle-spinner">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
        </div>
      </div>
      <!-- <button class="voltar-button" @click="voltar('dashboard')" type="warning">Voltar</button> -->
      <div class="div-caminho" v-if="flag">
        <Caminho :actualStep="1" />
      </div>
      <div class="content-viagem" v-if="flag">
        <div class="content-viagem-body" style="margin-top: 1.5rem">
          <h2 class="text-header">
            Você tem interesse nesta passagem? Venha viajar conosco!
          </h2>
          <div
            class="content-body-details"
            style="
              display: flex;
              border-radius: 10px;
              background-color: #ffffff;
              justify-content: space-between;
              padding: 1.5rem;
            "
          >
            <div class="content-left content-left-responsive">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.8rem;
                "
              >
                <label
                  style="
                    color: #009aa0;
                    margin-bottom: 0;
                    margin-right: 2rem;
                    font-size: 20px;
                    font-weight: 550;
                  "
                  >{{ dadosViagem.cidade_origem }}</label
                >
                <b-icon
                  icon="arrow-right"
                  width="2rem"
                  height="2rem"
                  style="color: #626262"
                ></b-icon>
                <label
                  style="
                    color: #009aa0;
                    margin-bottom: 0;
                    margin-left: 2rem;
                    font-size: 20px;
                    font-weight: 550;
                  "
                  >{{ dadosViagem.cidade_destino }}</label
                >
              </div>
              <label style="font-weight: bold"
                >Embarcação: {{ dadosViagem.embarcacao_nome }}</label
              >
              <label>Acomodação: Rede</label>
              <label
                v-if="this.portoCidade != null && this.portoCidade.nome !== ''"
                >Local de Partida: {{ this.portoCidade.nome }}</label
              >
              <label v-else
                >Local de Partida: Porto de
                {{ dadosViagem.cidade_origem }}</label
              >
              <label
                >Saída: {{ formatData(dadosViagem.dt_saida) }} -
                {{
                  formataHora(dadosViagem.dt_saida.split(" ").splice(-1)[0])
                }}h</label
              >
              <label
                >Previsão de chegada: {{ formatData(dadosViagem.dt_chegada) }} -
                {{
                  formataHora(dadosViagem.dt_chegada.split(" ").splice(-1)[0])
                }}h</label
              >
              <div style="margin-top: 1rem">
                <img :src="dadosViagem.imagem_url" />
              </div>
            </div>
            <div
              class="content-right content-right-responsive"
              style="width: 20rem; background-color: #ffffff;"
            >
              <label>Quantidade de Passageiros</label>
              <label style="color: #0090a0; font-size: 12px">SELECIONE:</label>
              <div style="display: flex">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-right: 1.5rem;
                  "
                >
                  <div>
                    <label>{{ qtdAdulto }} Adulto</label>
                  </div>
                  <div>
                    <label> {{ qtdCrianca }} Criança </label>
                  </div>
                  <div>
                    <label>{{ qtdIdoso }} Idoso</label>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                  "
                >
                  <div>
                    <b-icon
                      style="
                        color: #626262;
                        cursor: pointer;
                        margin-right: 0.6rem;
                      "
                      icon="person-dash"
                      @click="removePassageiro('adulto')"
                    ></b-icon>
                    <b-icon
                      style="color: #626262; cursor: pointer"
                      icon="person-plus"
                      @click="addPassageiro('adulto')"
                      id="popover-target-1"
                    ></b-icon>
                    <b-popover
                      target="popover-target-1"
                      triggers="hover"
                      placement="right"
                    >
                      <div class="popover">
                        <p class="popover-title">
                          Taxas por Adulto ({{
                            Math.ceil(this.taxaAdulto.dias_minimo / 365)
                          }}
                          até
                          {{ Math.ceil(this.taxaAdulto.dias_maximo / 365) }}
                          anos)
                        </p>
                        <p class="popover-text">
                          Alimentação:
                          <span>{{
                            formatCurrency(this.taxaAdulto.valorAlimentacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Transporte:
                          <span>{{
                            formatCurrency(this.taxaAdulto.valorTransporte)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Acomodação:
                          <span>{{
                            formatCurrency(this.taxaAdulto.valorAcomodacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Total:
                          <span>{{ formatCurrency(this.valorAdulto) }}</span>
                        </p>
                      </div>
                    </b-popover>
                  </div>
                  <div>
                    <b-icon
                      style="
                        color: #626262;
                        cursor: pointer;
                        margin-right: 0.6rem;
                      "
                      icon="person-dash"
                      @click="removePassageiro('crianca')"
                    ></b-icon>

                    <b-icon
                      style="color: #626262; cursor: pointer"
                      icon="person-plus"
                      @click="addPassageiro('crianca')"
                      id="popover-target-2"
                    ></b-icon>

                    <b-popover
                      target="popover-target-2"
                      triggers="hover"
                      placement="right"
                    >
                      <div class="popover">
                        <p class="popover-title">
                          Taxas por Criança ({{
                            Math.ceil(this.taxaCrianca.dias_minimo / 365)
                          }}
                          até
                          {{ Math.ceil(this.taxaCrianca.dias_maximo / 365) }}
                          anos)
                        </p>
                        <p class="popover-text">
                          Alimentação:
                          <span>{{
                            formatCurrency(this.taxaCrianca.valorAlimentacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Transporte:
                          <span>{{
                            formatCurrency(this.taxaCrianca.valorTransporte)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Acomodação:
                          <span>{{
                            formatCurrency(this.taxaCrianca.valorAcomodacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Total:
                          <span>{{ formatCurrency(this.valorCrianca) }}</span>
                        </p>
                      </div>
                    </b-popover>
                  </div>
                  <div>
                    <b-icon
                      style="
                        color: #626262;
                        cursor: pointer;
                        margin-right: 0.6rem;
                      "
                      icon="person-dash"
                      @click="removePassageiro('idoso')"
                    ></b-icon>
                    <b-icon
                      style="color: #626262; cursor: pointer"
                      icon="person-plus"
                      @click="addPassageiro('idoso')"
                      id="popover-target-3"
                    ></b-icon>
                    <b-popover
                      target="popover-target-3"
                      triggers="hover"
                      placement="right"
                    >
                      <div class="popover">
                        <p class="popover-title">
                          Taxas por Idoso ({{
                            Math.ceil(this.taxaIdoso.dias_minimo / 365)
                          }}+ anos)
                        </p>
                        <p class="popover-text">
                          Alimentação:
                          <span>{{
                            formatCurrency(this.taxaIdoso.valorAlimentacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Transporte:
                          <span>{{
                            formatCurrency(this.taxaIdoso.valorTransporte)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Acomodação:
                          <span>{{
                            formatCurrency(this.taxaIdoso.valorAcomodacao)
                          }}</span>
                        </p>
                        <p class="popover-text">
                          Total:
                          <span>{{ formatCurrency(this.valorIdoso) }}</span>
                        </p>
                      </div>
                    </b-popover>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    flex-grow: 1;
                    align-items: flex-end;
                  "
                >
                  <label>{{
                    (qtdAdulto * valorAdulto).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label>
                  <label>{{
                    (qtdCrianca * valorCrianca).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label>
                  <label>{{
                    (qtdIdoso * valorIdoso).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label>
                </div>
              </div>
              <div
                style="
                  margin-top: 1rem;
                  margin-bottom: 0.6rem;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div>
                  <label
                    style="font-size: 18px; color: #2a3550; font-weight: bold"
                    >TOTAL</label
                  >
                </div>
                <label
                  style="font-size: 24px; color: #2a3550; font-weight: bold"
                  >{{
                    (
                      valorIdoso * qtdIdoso +
                      valorCrianca * qtdCrianca +
                      valorAdulto * qtdAdulto
                    ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label
                >
              </div>
              <div style="display: flex; flex-direction: column">
                <label style="font-size: 12px"
                  >*Todos os maiores de 5 anos pagam taxa portuária.</label
                >
                <label style="font-size: 12px"
                  >**Taxa portuária não inclusa nesta compra e deverá ser paga
                  no porto.</label
                >
                <label style="font-size: 12px"
                  >***Peso máximo de bagagem até 10 quilos.</label
                >
                <label style="font-size: 12px; text-aling: justify"
                  ><span style="color: #0090a0; font-weight: 500"
                    >GRATUIDADE: </span
                  >Crianças menores que
                  {{ Math.ceil(this.taxaGratuita.dias_maximo / 365) }} anos não
                  pagam esta viagem. Apresente a certidão de nascimento no
                  embarque.</label
                >
              </div>
              <div class="content-buttons">
                <button @click="ativarFlag(1)" class="btn-compra2">
                  CONTINUAR
                </button>
                <button
                  class="btn-compra2"
                  style="
                    background-color: #f0f0f0;
                    color: #626262;
                    border: 1px solid #f0f0f0;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
                  "
                  @click="rotas('')"
                >
                  VOLTAR
                </button>
              </div>
            </div>
          </div>
          <div
            class="content-right recommended"
            style="background-color: #ffffff; overflow-y: scroll;"
          >
            <div style="display: flex; align-items: center">
              <label
                style="
                  font-size: 20px;
                  color: #000000;
                  margin-bottom: 0;
                  margin-right: 0.5rem;
                "
                >Outras datas disponíveis</label
              >
              <b-icon icon="calendar4-event" style="color: #009aa0"></b-icon>
            </div>
            <div
              v-for="(item, index) in passagensAlternativas"
              :key="index"
              @click="comprar({ key: index, id: item.id })"
              class="passagensAlternativas"
              style="background-color: #f0f2f5"
            >
              <div style="display: flex; flex-direction: column">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; flex-direction: column">
                    <label>{{
                      passagensAlternativas[index].embarcacao_nome
                    }}</label>
                    <label
                      >{{ passagensAlternativas[index].cidade_origem }} -
                      {{ passagensAlternativas[index].cidade_destino }}</label
                    >
                  </div>
                  <div>
                    <label>{{
                      (
                        passagensAlternativas[index].valor_alimentacao +
                        passagensAlternativas[index].valor_transporte
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                      })
                    }}</label>
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <label style="font-size: 12px">
                    Saída:
                    {{ formatData(passagensAlternativas[index].dt_saida) }}
                  </label>
                  <label style="font-size: 12px">
                    Chegada:
                    {{ formatData(passagensAlternativas[index].dt_chegada) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Caminho :actualStep="2" v-if="flag2" />
      <div v-if="flag2" class="content-passageiros">
        <h2 class="content-passageiros-title">Dados da viagem.</h2>
        <div class="content-info-3">
          <div class="content-info3-main">
            <div class="id-viagem">
              <div class="id-cell">
                <label class="id-cell-title">Itinerário:</label>
                <label class="id-cell-info destino">
                  {{ dadosViagem.cidade_origem }} &#129042;
                  {{ dadosViagem.cidade_destino }}
                </label>
              </div>
            </div>
            <div
              style="display: flex; justify-content: space-between; gap: 2rem; flex-wrap: wrap"
            >
              <div class="id-embarcacao">
                <div class="id-cell">
                  <label class="id-cell-title">Embarcação:</label>
                  <label class="id-cell-info">{{
                    dadosViagem.embarcacao_nome
                  }}</label>
                </div>
              </div>
              <div>
                <div class="id-cell">
                  <label class="id-cell-title">Acomodação:</label>
                  <label class="id-cell-info">Rede</label>
                </div>
              </div>
              <div>
                <div class="id-cell">
                  <label class="id-cell-title">Alimentação:</label>
                  <label class="id-cell-info">Inclusa</label>
                </div>
              </div>
              <div class="id-saida">
                <div class="id-cell">
                  <label class="id-cell-title">Saída:</label>
                  <label class="id-cell-info">{{
                    formatData(dadosViagem.dt_saida)
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="additional-infos">
            <div class="content-info3-aside">
              <div class="additional-cell">
                <div><label>Acomodação: Rede</label></div>
                <div><label>Alimentação: Inclusa</label></div>
              </div>
            </div>
          </div> -->
        </div>

        <h2 class="content-passageiros-title" style="margin-bottom: -3rem">
          Preencha os dados dos passageiros.
        </h2>
        <div class="content-passageirosAgrupados">
          <div
            v-for="(passageiro, index) in this.passageiros"
            :key="index"
            class="content-passageiro"
          >
            <div class="content-passageiro-body">
              <!-- <div class="id-viagem">
                <label>
                  {{ dadosViagem.cidade_origem }} -
                  {{ dadosViagem.cidade_destino }}
                </label>
              </div> -->
              <div
                class="infos-ticket"
                style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: wrap; gap: .5rem;"
              >
                <div
                  class="infos-ticket-passageiro"
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: wrap; gap: 1rem"
                >
                  <label
                    style="
                      font-size: 20px;
                      font-weight: 500;
                      color: #000000;
                      margin-right: 0.5rem;
                    "
                    >Passageiro {{ index + 1 }}:</label
                  >

                  <div class="info-passageiro">
                    <label
                      style="font-size: 20px; font-weight: 500; color: #009aa0"
                      >{{
                        capitalizeFirstLetter(passageiro.tipo).toUpperCase()
                      }}</label
                    >
                    <label style="font-size: 18px; margin-left: 0.2rem">
                      ({{ agesRangeLabel(passageiro.tipo) }})</label
                    >
                  </div>
                </div>
                <label
                  style="
                      font-size: 24px;
                      color: #626262;
                      font-weight: bold;
                      margin-bottom: 1rem;
                      align-self: end;
                    "
                  >{{
                    returnValor(passageiro.tipo).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label
                >
              </div>
              <div class="inline-input" style="margin-top: 1rem">
                <div class="column-input nome-input">
                  <label>Nome completo<sup>*</sup></label>
                  <input
                    type="text"
                    placeholder="Digite o nome completo"
                    v-model="passageiro.nome"
                  />
                </div>
                <div
                  class="column-input expanded-small"
                  style="position: relative"
                >
                  <label>Data de nascimento<sup>*</sup></label>
                  <input
                    type="date"
                    v-model="passageiro.dt_nascimento"
                    class="date"
                  />
                  <label class="invalidDate-label">Data inválida!</label>
                </div>
                <div class="column-input expanded-small">
                  <label>Gênero<sup>*</sup></label>
                  <select v-model="passageiro.genero">
                    <option value="" disabled selected>Selecione</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="O">Não Especificado</option>
                  </select>
                </div>
              </div>
              <div class="inline-input">
                <div class="column-input" style="width: 13rem">
                  <label>Nacionalidade<sup>*</sup></label>
                  <select
                    v-model="passageiro.nacionalidade"
                    style="width: 13rem"
                  >
                    <option value="" disabled selected>Selecione</option>
                    <option
                      v-for="(item, index) in nacionality"
                      :key="index"
                      :value="capitalizeFirstLetter(item)"
                    >
                      {{ capitalizeFirstLetter(item) }}
                    </option>
                  </select>
                </div>
                <div class="column-input expanded-small">
                  <label>RG<sup>*</sup></label>
                  <input
                    type="text"
                    placeholder="Digite o RG"
                    v-mask="'########'"
                    maxlength="8"
                    v-model="passageiro.rg"
                  />
                </div>
                <div class="column-input expanded-small">
                  <label>CPF<sup>*</sup></label>
                  <input
                    type="text"
                    placeholder="Digite o CPF"
                    v-model="passageiro.cpf"
                    v-mask="'###.###.###-##'"
                  />
                </div>
              </div>
              <div class="inline-input">
                <div style="display: flex; flex-start; gap: 1rem">
                  <div class="column-input ddd-input">
                    <label>DDD*</label>
                    <input
                      type="text"
                      placeholder="(XX)"
                      v-mask="'(##)'"
                      v-model="passageiro.ddd"
                    />
                  </div>
                  <div class="column-input">
                    <label>Celular*</label>
                    <input
                      type="text"
                      placeholder="X XXXX-XXXX"
                      v-mask="'# ####-####'"
                      v-model="passageiro.telefone"
                    />
                  </div>
                </div>
                <div>
                  <label>*Campos obrigatórios</label>
                </div>
              </div>
              <!-- <div class="content-info">
                <label
                  style="font-size: 18px; color: #626262; font-weight: normal"
                  >VALOR</label
                >
                <label
                  style="
                    font-size: 24px;
                    color: #626262;
                    font-weight: bold;
                    margin-bottom: 1rem;
                  "
                  >{{
                    returnValor(passageiro.tipo).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}</label
                >
                <label
                  style="
                    font-size: 15px;
                    color: #626262;
                    font-weight: 500;
                    margin-bottom: 0.6rem;
                    text-align: right;
                  "
                  >Embarcação: {{ dadosViagem.embarcacao_nome }}</label
                >
                <label
                  style="
                    font-size: 15px;
                    color: #626262;
                    font-weight: 500;
                    margin-bottom: 0.6rem;
                  "
                  >Data: {{ formatData(dadosViagem.dt_saida) }}</label
                >
                <label
                  style="
                    font-size: 15px;
                    color: #626262;
                    font-weight: 500;
                    margin-bottom: 0.6rem;
                  "
                  >Acomodação: Rede</label
                >
                <label
                  style="
                    font-size: 15px;
                    color: #626262;
                    font-weight: 500;
                    margin-bottom: 0.6rem;
                  "
                  >Alimentação: Inclusa</label
                >
                <div
                  style="
                    margin-top: 0.6rem;
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                  "
                >
                  <label style="font-size: 12px"
                    >*Taxa portuária não inclusa nesta compra e deverá ser paga
                    no porto.</label
                  >
                  <label style="font-size: 12px"
                    >**Peso máximo de bagagem até 10 quilos.</label
                  >
                  <label
                    v-if="passageiro.tipo !== 'crianca'"
                    style="font-size: 12px"
                    >***Apresentar RG no embarque.</label
                  >
                  <label style="font-size: 12px" v-else
                    >***Apresentar certidão de nascimento no embarque.</label
                  >
                </div>
              </div> -->
            </div>
          </div>
          <div class="content-info-3" style="gap: .7rem;">
            <h2>Importante:</h2>
            <div>
              <label
                >A taxa portuária não está inclusa nesta compra e deverá ser
                paga no porto.</label
              >
            </div>
            <div>
              <label>O peso máximo de bagagem é 10kg (por passageiro).</label>
            </div>
            <div>
              <label
                >É necessário apresentar documento com foto no embarque.</label
              >
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              margin-top: 2rem;
              position: relative;
            "
          >
            <button
              class="btn-compra2"
              @click="goBack(1)"
              style="
                font-weight: bold;
                background-color: #f0f0f0;
                color: #626262;
                border: 1px solid #f0f0f0;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
                margin-right: 8px;
              "
            >
              VOLTAR
            </button>
            <button
              class="btn-compra2"
              style="margin-left: 8px"
              @click="createPassageiro(passageiros)"
            >
              CONTINUAR PAGAMENTO
            </button>
          </div>
        </div>
      </div>

      <Caminho :actualStep="3" v-if="flag3" />
      <div class="content-purchase" v-if="flag3">
        <div
          class="content-purchase-direction"
          style="display: flex; align-items: center"
        >
          <h2 class="text-header">
            Quase lá! Preencha as informações abaixo e finalize sua compra.
          </h2>
          <div class="content-left">
            <div class="content-top">
              <h2 class="content-title">DADOS DO COMPRADOR</h2>
              <div class="inline-input">
                <div class="column-input nome-input">
                  <label>Nome completo*</label>
                  <input
                    type="text"
                    placeholder="Digite seu nome completo"
                    v-model="dadosComprador.name"
                  />
                </div>
                <div class="column-input email-input">
                  <label
                    >Email*
                    <span style="color: #009aa0"
                      >(receberá a confirmação da compra)</span
                    ></label
                  >
                  <input
                    type="email"
                    :value="this.$store.state.session.email"
                    placeholder="Digite seu email"
                    class="disabled2"
                    disabled
                  />
                </div>
              </div>
              <div class="inline-input">
                <div class="column-input">
                  <label>CPF*</label>
                  <input
                    type="text"
                    placeholder="Digite o CPF do titular"
                    style="width: 14rem"
                    v-model="dadosComprador.document"
                    v-mask="'###.###.###-##'"
                  />
                </div>
                <div class="column-input expanded-small">
                  <label>País de origem*</label>
                  <select
                    v-model="dadosComprador.billing.country"
                    style="width: 15rem"
                  >
                    <option
                      v-for="(item, index) in countries"
                      :key="index"
                      :value="capitalizeFirstLetter(item)"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="column-input">
                  <label>Data de nascimento*</label>
                  <input
                    type="date"
                    class="date"
                    v-model="dadosComprador.birthDate"
                  />
                </div>
                <div class="column-input expanded-small">
                  <label>CEP*</label>
                  <input
                    type="text"
                    placeholder="Digite o CEP"
                    v-model="dadosComprador.billing.zipcode"
                    v-mask="'#####-###'"
                    @keyup="verificaCep()"
                  />
                </div>
              </div>
              <div class="inline-input">
                <!-- <div class="column-input expanded-small">
                  <label>CEP</label>
                  <input
                    type="text"
                    placeholder="Digite o CEP"
                    v-model="dadosComprador.billing.zipcode"
                    v-mask="'#####-###'"
                    @keyup="verificaCep()"
                  />
                </div> -->
                <div class="column-input endereco-input">
                  <label>Endereço*</label>
                  <input
                    type="text"
                    :placeholder="dadosComprador.billing.street"
                    v-model="dadosComprador.billing.street"
                    disabled
                    class="disabled2"
                  />
                </div>
                <div class="column-input expanded-input">
                  <label>Cidade*</label>
                  <input
                    type="text"
                    disabled
                    :placeholder="dadosComprador.billing.city"
                    v-model="dadosComprador.billing.city"
                    class="disabled2"
                  />
                </div>
                <div class="column-input expanded-input">
                  <label>Estado*</label>
                  <input
                    type="text"
                    disabled
                    :placeholder="dadosComprador.billing.state"
                    v-model="dadosComprador.billing.state"
                    class="disabled2"
                  />
                </div>
              </div>
              <div class="inline-input expanded-input">
                <div class="column-input number-input">
                  <label>Número*</label>
                  <input
                    type="text"
                    placeholder="Digite"
                    v-model="dadosComprador.billing.number"
                    maxlength="6"
                    v-mask="'######'"
                  />
                </div>
                <div class="column-input">
                  <label>Bairro*</label>
                  <input
                    disabled
                    style="width: 25rem"
                    type="text"
                    v-model="dadosComprador.billing.neighborhood"
                    class="disabled2"
                  />
                </div>
                <div class="column-input">
                  <label>DDD*</label>
                  <input
                    type="text"
                    placeholder="(XX)"
                    v-mask="'(##)'"
                    style="width: 4rem"
                    v-model="dadosComprador.ddd"
                  />
                </div>
                <div class="column-input">
                  <label>Celular*</label>
                  <input
                    type="text"
                    style="width: 14rem"
                    placeholder="Digite o celular"
                    v-mask="'#####-####'"
                    v-model="dadosComprador.phone"
                  />
                </div>
              </div>
              <div class="inline-input">
                <div style="display: flex" class="flex-start">
                  <div class="column-input ddd-input">
                    <label>DDD</label>
                    <input
                      type="text"
                      placeholder="(XX)"
                      v-mask="'(##)'"
                      style="width: 4rem"
                    />
                  </div>
                  <div class="column-input">
                    <label>Celular <label>(Opcional)</label></label>
                    <input
                      type="text"
                      placeholder="Digite o celular"
                      v-mask="'#####-####'"
                    />
                  </div>
                </div>
                <div>
                  <label>*Campos obrigatórios</label>
                </div>
              </div>
            </div>
            <div class="content-bottom">
              <h2 class="content-title">FORMA DE PAGAMENTO</h2>
              <div class="radio-buttons">
                <div>
                  <input
                    type="radio"
                    name="radio1"
                    v-model="flagCredit"
                    value="1"
                    style="height: auto"
                  />
                  <label style="font-weight: 400"
                    >Cartão de crédito
                    <span style="color: #626262; font-weight: 500"
                      >(à vista)</span
                    ></label
                  >
                </div>
              </div>
              <div v-if="flagCredit">
                <h2 class="content-title">DADOS CARTÃO</h2>
                <div class="inline-input top-input">
                  <div class="column-input">
                    <label>Titular</label>
                    <input
                      type="text"
                      placeholder="Digite como está escrito no cartão"
                      v-model="card.holder"
                    />
                  </div>
                  <div class="column-input card-holder-input">
                    <label>Número do cartão</label>
                    <input
                      type="text"
                      placeholder="Digite o número do cartão"
                      v-mask="'#### #### #### ####'"
                      v-model="card.displayNumber"
                    />
                  </div>
                </div>
                <div class="inline-input flex-start">
                  <div class="column-input" style="margin-right: 1rem">
                    <label>Data de expiração</label>
                    <input
                      type="text"
                      placeholder="MM/AA"
                      v-model="card.data_validation"
                      v-mask="'##/##'"
                    />
                  </div>
                  <div class="column-input card-cvv-input">
                    <label>Código de segurança</label>
                    <input
                      type="text"
                      placeholder="XXX"
                      maxlength="3"
                      v-mask="'###'"
                      v-model="card.validation_value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-right" style="align-self: baseline">
            <div class="content-embarcacao">
              <h2 class="content-title">DETALHES DA COMPRA</h2>
              <div class="align-content" style="width: 20rem">
                <div style="display: flex; flex-direction: column">
                  <label class="label-subtitle">ORIGEM</label>
                  <label class="label-strong2" style="color: #009aa0">{{
                    dadosViagem.cidade_origem
                  }}</label>
                </div>
                <b-icon
                  width="2rem"
                  height="2rem"
                  icon="arrow-right"
                  style="color: #626262"
                ></b-icon>
                <div style="display: flex; flex-direction: column">
                  <label class="label-subtitle">DESTINO</label>
                  <label class="label-strong2" style="color: #009aa0">{{
                    dadosViagem.cidade_destino
                  }}</label>
                </div>
              </div>
              <label
                style="
                  font-size: 20px;
                  color: #626262;
                  font-weight: bold;
                  margin-top: 1rem;
                  margin-bottom: 0.6rem;
                "
                >Embarcação: {{ dadosViagem.embarcacao_nome }}</label
              >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <label
                  style="
                    font-size: 16px;
                    color: #626262;
                    font-weight: 400;
                    margin-bottom: 0.6rem;
                  "
                  >Acomodação: Rede</label
                >
                <label
                  style="
                    font-size: 16px;
                    color: #626262;
                    font-weight: 400;
                    margin-bottom: 0.5rem;
                  "
                  >Alimentação: Inclusa</label
                >
              </div>
              <label
                style="
                  font-size: 16px;
                  color: #626262;
                  font-weight: 400;
                  margin-bottom: 0.6rem;
                "
                <label
                v-if="this.portoCidade != null && this.portoCidade.nome !== ''"
                >Local de Partida: {{ this.portoCidade.nome }}</label
              >
              <label v-else
                >Local de Partida: Porto de
                {{ dadosViagem.cidade_origem }}</label
              >
              <label
                style="
                  font-size: 16px;
                  color: #626262;
                  font-weight: 400;
                  margin-bottom: 0.6rem;
                "
                >Saída: {{ formatData(dadosViagem.dt_saida) }} -
                {{
                  formataHora(dadosViagem.dt_chegada.split(" ").splice(-1)[0])
                }}h</label
              >
              <label
                style="
                  font-size: 16px;
                  color: #626262;
                  font-weight: 400;
                  margin-bottom: 0.6rem;
                "
                >Previsão de chegada: {{ formatData(dadosViagem.dt_chegada) }} -
                {{
                  formataHora(dadosViagem.dt_chegada.split(" ").splice(-1)[0])
                }}h</label
              >

              <label class="label-strong" style="margin-top: 1rem"
                >Passageiros</label
              >
              <div class="align-content">
                <label>{{ this.qtdAdulto }} Adulto</label>
                <label>{{
                  (this.valorAdulto * this.qtdAdulto).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
                }}</label>
              </div>
              <div class="align-content">
                <label>{{ this.qtdCrianca }} Criança</label>
                <label>{{
                  (this.valorCrianca * this.qtdCrianca).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL"
                    }
                  )
                }}</label>
              </div>
              <div class="align-content">
                <label>{{ this.qtdIdoso }} Idoso</label>
                <label>{{
                  (this.valorIdoso * this.qtdIdoso).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
                }}</label>
              </div>
              <div class="align-content">
                <label class="label-strong">TOTAL</label>
                <label class="label-strong2">{{
                  (
                    this.valorIdoso * this.qtdIdoso +
                    this.valorCrianca * this.qtdCrianca +
                    this.valorAdulto * this.qtdAdulto
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  })
                }}</label>
              </div>
              <label class="label-subtitle2" style="margin-top: 1rem"
                >*Todos os maiores de 5 anos pagam taxa portuária.</label
              >
              <label class="label-subtitle2"
                >**Taxa portuária não inclusa nesta compra e deverá ser paga no
                porto.</label
              >
              <label class="label-subtitle2"
                >***Peso máximo de bagagem até 10 quilos.</label
              >
              <div class="content-buttons">
                <button @click="validarCompra()" class="btn-compra2">
                  FINALIZAR COMPRA
                </button>
                <button
                  class="btn-compra2"
                  style="
                    background-color: #f0f0f0;
                    color: #626262;
                    border: 1px solid #f0f0f0;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
                  "
                  @click="goBack(2)"
                >
                  VOLTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RegisterUserAllForm from "./Profile/RegisterUserAllForm";
import RegisterAdmForm from "./Profile/RegisterAdmForm";
import api from "../api";
import moment from "moment";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
import Swal from "sweetalert2";
import _ from "lodash";
import IconBarco from "@/assets/icons/icon_barco.svg";
import IconCalendar from "@/assets/icons/icon_calendar.svg";
import IconClock from "@/assets/icons/icon_clock.svg";
import IconAddPerson from "@/assets/icons/person_add.svg";
import IconRemovePerson from "@/assets/icons/person_remove.svg";
import IconDebitCard from "@/assets/icons/debito.svg";
import IconCreditCard from "@/assets/icons/credito.svg";
import IconBoleto from "@/assets/icons/boleto.svg";
import IconMaster from "@/assets/img/mastercard.png";
import IconVisa from "@/assets/img/visa.png";
import IconElo from "@/assets/img/elo.png";
import IconDiners from "@/assets/img/diners.png";
import IconAmerican from "@/assets/img/american-express.png";
import Footer from "@/components/Footer";
import personIcon from "@/assets/icons/person_icon.svg";
import lockIcon from "@/assets/icons/lock_icon.svg";
import PoliticaPrivacidade from "./PoliticaPrivacidade";

import countries from "@/utils/paises.json";
import nacionality from "@/utils/nacionalidades.json";
// import { formatCurrency } from "@/utils/format/currency.js";

window.$ = window.jQuery = require("jquery");

import IconTrash from "@/assets/icons/trash.svg";
import Caminho from "@/components/compras/Caminho.vue";

export default {
  components: {
    RegisterUserAllForm,
    RegisterAdmForm,
    Caminho,
    Footer,
    PoliticaPrivacidade
  },
  data() {
    return {
      //Icons
      IconTrash,

      // mask: '',
      fingerPrint: "",
      img: "",
      flagClientPurchase: false,
      email: "",
      senha: "",
      taxaGratuita: {},
      taxaAdulto: {},
      taxaCrianca: {},
      taxaIdoso: {},
      descontos: [],
      clientCard: null,
      dadosCartao: [],
      passagensAlternativas: [],
      dataInicio: new Date(),
      dataFim: new Date(),
      card: {
        displayNumber: null,
        validation_value: null,
        holder: "",
        data_validation: "",
        brand: ""
      },
      flag: true,
      flag2: false,
      flag3: false,
      flagOptionsCartao: true,
      flagBoleto: false,
      flagCartao: false,
      flagRadio: false,
      flagDebit: false,
      flagCredit: false,
      flagTrueCard: false,
      flagTermo: false,
      isValidCep: false,
      qtdPassageiros: 0,
      qtdAdulto: 1,
      qtdIdoso: 0,
      qtdCrianca: 0,
      valorAdulto: 0,
      valorCrianca: 0,
      valorIdoso: 0,
      valorAdultoAux: 0,
      valorCriancaAux: 0,
      valorIdosoAux: 0,
      countries,
      nacionality,
      passageiros: [
        {
          nome: "",
          email: "",
          telefone: "",
          ddd: "",
          dt_nascimento: "",
          cpf: "",
          rg: "",
          genero: "",
          nacionalidade: "",
          tipo: "adulto",
          valor: ""
        }
      ],
      dadosComprador: {
        name: "",
        ddd: "",
        phone: "",
        email: "",
        documentType: "CPF",
        document: "",
        birthDate: "",
        billing: {
          street: "",
          number: "",
          neighborhood: "",
          city: "",
          state: "",
          country: "",
          zipcode: ""
        }
      },
      data_saida_hora_pdf: "",
      data_saida_pdf: "",
      data_hoje: new Date(),
      valorTotal: 0,
      dt_saida: "",
      hr_saida: "",
      dadosViagem: "",
      viagensOrigem: [],
      cidade_id: null,
      portoCidade: "",
      embarcacao_id: null,
      focusElementStyle: null,
      isInputFocused: false,
      parcelas: 1,
      IconBarco,
      IconClock,
      IconCalendar,
      IconAddPerson,
      IconRemovePerson,
      IconDebitCard,
      IconCreditCard,
      IconVisa,
      IconMaster,
      IconAmerican,
      IconVisa,
      IconElo,
      IconDiners,
      IconBoleto,
      personIcon,
      lockIcon
    };
  },
  watch: {
    // flagRadio: function(val) {
    //   if (val == 1) {
    //     this.flagBoleto = true;
    //     this.flagCartao = false;
    //   } else {
    //     this.flagBoleto = false;
    //     this.flagCartao = true;
    //   }
    // },
    flagCredit: function(val) {
      if (val == 1) {
        this.flagCredit = true;
        this.flagBoleto = false;
        this.flagDebit = false;
        setTimeout(function() {
          $("html, body").height();
          $("html, body").animate({ scrollTop: "220px" }, "speed");
        }, 100);
        // this.parcelas = 1;
      }
    },
    flagDebit: function(val) {
      if (val == 2) {
        this.flagDebit = true;
        this.flagBoleto = false;
        this.flagCredit = false;
        this.parcelas = 0;
      }
    }
  },
  computed: {},
  async mounted() {
    this.countries = this.countries.paises.sort((a, b) => {
      return a.localeCompare(b);
    });

    this.nacionality = this.nacionality.nacionalidade.sort((a, b) => {
      return a.localeCompare(b);
    });

    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };
    if (
      this.$store.state.dadosViagem.id == undefined ||
      this.$store.state.session.auth != true
    ) {
      this.$router.push("/signup");
      return;
    }
    this.$store.dispatch("pop_purchase");
    this.$store.dispatch("remove_passeger_all");
    var vm = this;
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function() {
      if (vm.$router.history.current.path == "/purchase") {
        Swal.fire({
          title: "Tem certeza?",
          text: "Os dados serão perdidos!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.value) {
            vm.$router.push("/");
          }
        });
      }
      window.history.pushState(null, "", window.location.href);
    };
    if (
      this.$store.state.dadosViagem.id == undefined ||
      this.$store.state.session.auth != true
    )
      this.$router.push("/signup");

    await this.axios
      .get(`${api}/usuarios/perfil`, config2)
      .then(response => {
        this.user = {
          pessoa_id: response.data.complemento_pessoa.pessoa_id,
          username: response.data.pessoa.nome,
          telefone: response.data.complemento_pessoa.telefone,
          email: response.data.complemento_pessoa.email,
          rg: response.data.complemento_pessoa.rg,
          sexo: response.data.pessoa.sexo,
          nacionalidade: response.data.pessoa.nacionalidade,
          orgao_emissor: response.data.complemento_pessoa.orgao_emissor,
          data_nascimento: response.data.pessoa.dt_nascimento,
          cpf: response.data.complemento_pessoa.cpf,
          dt_modificacao: response.data.pessoa.dt_modificacao
        };
      })
      .catch(err => {
        if (err.response.data.message == "Auth failed")
          this.$store.dispatch("logout");

        this.$router.push("/login");
      });
    await this.axios
      .get(`${api}/viagens/${this.$store.state.dadosViagem.id}`, config2)
      .then(response => {
        this.dt_saida = moment(response.data.dt_saida).format("DD/MM/YYYY");
        this.hr_saida = moment(response.data.dt_saida).format("h:mm");

        this.data_saida_hora_pdf = moment(response.data.dt_saida).format(
          "h:mm"
        );
        this.data_saida_pdf = moment(response.data.dt_saida).format(
          "DD/MM/YYYY"
        );
        this.embarcacao_id = response.data.embarcacao_id;

        this.dadosViagem = response.data;

        this.$store.dispatch("setValorTotal", 0);
        this.valorAdulto =
          this.dadosViagem.agencias[0].valor_transporte +
          this.dadosViagem.agencias[0].valor_alimentacao +
          this.dadosViagem.agencias[0].valor_acomodacao;
        this.img = this.dadosViagem.imagem_url;
        this.$store.dispatch("data_trip", response.data);
      })
      .catch(err => {
        if (err.response.data.message == "Auth failed")
          this.$store.dispatch("logout");

        this.$router.push("/signup");
      });

    this.axios
      .get(`${api}/embarcacoes/${this.embarcacao_id}/descontos`, config2)
      .then(response => {
        this.descontos.push(response.data);
        this.descontoPassageiro(this.descontos[0]);
      });

    // buscar id da cidade de embarque
    await this.axios.get(`${api}/cidades?tipo=origem`).then(response => {
      this.viagensOrigem = response.data[0].id;
      this.cidade_id = this.viagensOrigem;
    });

    // buscar nome do porto com o id da cidade
    this.axios
      .get(`${api}/embarcacoes/1/portos?cidade_id=${this.cidade_id}`)
      .then(response => {
        this.portoCidade = response.data.porto;
      });

    this.dataInicio.setDate(this.dataInicio.getDate() + 1);
    this.dataFim.setDate(this.dataFim.getDate() + 20);
    this.axios
      .get(
        `${api}/viagens/busca/?cidade_origem=${
          this.dadosViagem.cidade_origem
        }&cidade_destino=${
          this.dadosViagem.cidade_destino
        }&dt_saida_inicial=${moment(this.dataInicio).format(
          "YYYY-MM-DD"
        )}&dt_saida_final=${moment(this.dataFim).format("YYYY-MM-DD")}`
      )
      .then(response => {
        response.data.map(element => {
          this.passagensAlternativas.push(element);
        });
      });

    this.passagensAlternativas.splice(0, 9); //permanecer somente as 3 primeiras.
  },
  methods: {
    formatCurrency(current) {
      return current.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL"
      });
    },
    descontoPassageiro(desc) {
      this.taxaGratuita = desc[0];
      this.taxaCrianca = desc[1];
      this.taxaAdulto = desc[2];
      this.taxaIdoso = desc[3];
      var agencia = this.dadosViagem.agencias[0];

      var valorTransporte;
      var valorAlimentacao;
      var valorAcomodacao;
      var pt, pali, paco; //apelidos para diminuir a robustes do codigo;

      for (var i = 0; i < 4; i++) {
        pt = desc[i].percentual_transporte;
        pali = desc[i].percentual_alimentacao;
        paco = desc[i].percentual_acomodacao;

        //atribuindo os valores conforme o percentual do desconto(taxa);
        valorTransporte = agencia.valor_transporte * (1 - pt);
        valorAlimentacao = agencia.valor_alimentacao * (1 - pali);
        valorAcomodacao = agencia.valor_acomodacao * (1 - paco);
        desc[i].valorTransporte = valorTransporte;
        desc[i].valorAlimentacao = valorAlimentacao;
        desc[i].valorAcomodacao = valorAcomodacao;
        desc[i].valorUnitario =
          valorTransporte + valorAlimentacao + valorAcomodacao;
        //adicionando em cada tipo de passageiro o seu valor unitario;
      }
      this.taxaGratuita = desc[0];
      this.taxaCrianca = desc[1];
      this.taxaAdulto = desc[2];
      this.taxaIdoso = desc[3];
      this.valorAdulto = desc[2].valorUnitario;
      this.valorCrianca = desc[1].valorUnitario;
      this.valorIdoso = desc[3].valorUnitario;
      // switch (tipoPassageiro) {
      //   case "adulto":
      //     this.valorAdulto =
      //       valorTransporte + valorAlimentacao + valorAcomodacao;
      //     break;
      //   case "crianca":
      //     this.valorCrianca =
      //       valorTransporte + valorAlimentacao + valorAcomodacao;
      //     break;
      //   case "idoso":
      //     this.valorIdoso =
      //       valorTransporte + valorAlimentacao + valorAcomodacao;
      //     break;
      // }
    },
    comprar(key) {
      if (this.$store.state.session.auth != true) {
        this.$router.push("/signup");
      }
      location.reload();
      var dados = {
        id: key.id,
        key: key.key
      };
      this.$store.dispatch("viagem", dados);
      this.$router.push({
        name: "purchase",
        params: { id: this.$route.params.id, key: key }
      });
    },
    verificaCep() {
      var cep = this.dadosComprador.billing.zipcode.replace(/[^0-9]+/g, "");
      if (cep.length === 8) {
        const customer = this.dadosComprador;
        this.axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then(response => {
            if (response.data.erro === true) {
              Swal.fire({
                title: "CEP Inválido!",
                text: "Verifique se o número do cep está correto.",
                icon: "error",
                showConfirmButton: false,
                timer: 2200
              });
            } else {
              const endereco = response.data;
              customer.billing.street = endereco.logradouro;
              customer.billing.city = endereco.localidade;
              customer.billing.state = endereco.uf;
              customer.billing.neighborhood = endereco.bairro;
              this.isValidCep = true;
            }
          })
          .catch(error => {});
      }
    },
    verificaComprador(customer) {
      if (
        customer.name == "" ||
        customer.document == "" ||
        customer.birthDate == "" ||
        customer.phone == "" ||
        customer.billing.zipcode == "" ||
        customer.billing.number == ""
      ) {
        this.$notify({
          message: "Preencha todos os dados do contato!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
        return false;
      } else {
        return true;
      }
    },

    verificaCartao(cardNumber) {
      if (
        this.card.displayNumber != null &&
        this.card.displayNumber != "" &&
        this.card.validation_value != null &&
        this.card.validation_value != "" &&
        this.card.holder != null &&
        this.card.holder != "" &&
        this.card.data_validation != null &&
        this.card.data_validation != ""
      ) {
        var cardNumber = cardNumber.replace(/[^0-9]+/g, "");
        var cards = {
          Visa: /^4[0-9]{12}(?:[0-9]{3})/,
          "Elo Credito": /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
          Mastercard: /^5[1-5][0-9]{14}/,
          Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
          Amex: /^3[47][0-9]{13}/
        };

        for (var flag in cards) {
          if (cards[flag].test(cardNumber)) {
            this.card.brand = flag;
            this.flagTrueCard = true;
            return true;
          }
        }
        Swal.fire({
          title: "Cartão Inválido",
          text: "Verifique se o número do cartão está correto",
          icon: "warning",
          showConfirmButton: false,
          timer: 2200
        });
        this.flagTrueCard = false;
        return false;
      } else {
        this.$notify({
          message: "Preencha todos os dados do cartão!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
        return false;
      }
    },
    hiddeModal() {
      this.$modal.hide("termosUso");
    },
    show() {
      this.$modal.show("passageiro");
    },
    afterOpen() {
      var x = document.getElementsByClassName("v--modal-box v--modal")[0];
      if (document.body.offsetWidth < 768) {
        x.style.width = "100%";
        x.style.left = "auto";
      }
    },
    checkBox() {
      if (this.$refs.checkbox.checked) {
        this.flagTermo = true;
      } else {
        this.flagTermo = false;
      }
    },
    checkBox2() {
      if (this.$refs.checkbox2.checked) {
        this.flagTermo = true;
      } else {
        this.flagTermo = false;
      }
    },
    goBack(flag) {
      switch (flag) {
        case 1:
          this.flag2 = false;
          this.flag = true;
          break;
        case 2:
          this.flag3 = false;
          this.flag2 = true;
          this.$store.dispatch("deleteValorTotal");
          this.$store.dispatch("remove_passeger_all");
      }
    },
    formatData(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    rotas(rota) {
      this.$router.push(`/${rota}`);
    },
    capitalizeFirstLetter(string) {
      if (string == "crianca") {
        string = "criança";
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    agesRangeLabel(tipo) {
      let label, min, max;
      switch (tipo) {
        case "adulto":
          min = Math.ceil(this.taxaAdulto.dias_minimo / 365);
          max = Math.ceil(this.taxaAdulto.dias_maximo / 365);
          label = `${min} - ${max} anos`;
          break;
        case "idoso":
          min = Math.ceil(this.taxaIdoso.dias_minimo / 365);
          max = Math.ceil(this.taxaIdoso.dias_maximo / 365);
          label = `${min}+ anos`;
          break;
        case "crianca":
          min = Math.ceil(this.taxaCrianca.dias_minimo / 365);
          max = Math.ceil(this.taxaCrianca.dias_maximo / 365);
          label = `${min} - ${max} anos`;
          break;
      }
      return label;
    },

    ativarFlag(n) {
      if (n == 1) {
        this.passageiros.map(item => {
          if (item.valor == "" || item.valor == null) {
            switch (item.tipo) {
              case "adulto":
                item.valor = this.valorAdulto;
                break;
              case "crianca":
                item.valor = this.valorCrianca;
                break;
              case "adulto":
                item.valor = this.valorIdoso;
                break;
            }
          }
        });
        if (this.passageiros.length == 0) {
          this.$notify({
            message: "Adicione pelo menos um passageiro!",
            type: "danger",
            timeout: 2000,
            horizontalAlign: "center",
            verticalAlign: "top",
            showClose: false,
            closeOnClick: false
          });
          return;
        }
        this.flag = false;
        this.flag2 = true;
      } else if (n == 2) {
        this.flag2 = false;
        this.flag3 = true;
      }
    },
    addPassageiro(tipoPassageiro) {
      var desc = 0;
      if (this.passageiros.length < 7) {
        switch (tipoPassageiro) {
          case "adulto":
            this.qtdAdulto++;
            desc = this.descontos[0][2];
            break;
          case "crianca":
            this.qtdCrianca++;
            desc = this.descontos[0][1];
            break;
          case "idoso":
            this.qtdIdoso++;
            desc = this.descontos[0][3];
            break;
        }
        // this.descontoPassageiro(tipoPassageiro, desc);
        var newPassageiro = {
          nome: "",
          email: "",
          telefone: "",
          dt_nascimento: "",
          cpf: "",
          rg: "",
          nacionalidade: "",
          sexo: "",
          tipo: tipoPassageiro
        };

        switch (tipoPassageiro) {
          case "adulto":
            newPassageiro.valor = this.valorAdulto;
            break;
          case "crianca":
            newPassageiro.valor = this.valorCrianca;
            break;
          case "idoso":
            newPassageiro.valor = this.valorIdoso;
            break;
        }
        this.passageiros.push(newPassageiro);
      } else {
        Swal.fire({
          icon: "error",
          title: "Limite Atingido!",
          text: "É permitido somente 7 (sete) passageiros por compra.",
          showConfirmButton: false,
          timer: 1800
        });
      }
      // return this.qtdPassageiros++;
    },
    removePassageiro(tipoPassageiro) {
      var element;
      for (var i = 0; i < this.passageiros.length; i++) {
        if (this.passageiros[i].tipo == tipoPassageiro) element = i;
      }
      switch (tipoPassageiro) {
        case "adulto":
          if (this.qtdAdulto == 0) return;
          this.passageiros.splice(element, 1);
          this.qtdAdulto--;
          break;
        case "crianca":
          if (this.qtdCrianca == 0) return;
          this.passageiros.splice(element, 1);
          this.qtdCrianca--;
          break;
        case "idoso":
          if (this.qtdIdoso == 0) return;
          this.passageiros.splice(element, 1);
          this.qtdIdoso--;
          break;
      }
    },
    createPassageiro(passageiro) {
      // console.log(passageiro[0].tipo);

      var array_cpf = [],
        array_rg = [],
        array_nome = [],
        flagPassageiro = true;

      // for (let i = 0; i < passageiro.length; i++) {
      //   if (passageiro[i].tipo == "crianca") {
      //     console.log("certo");
      //   }
      // }

      // var p_nasc = passageiro[0].dt_nascimento.substring(6, 10);
      for (var i = 0; i < passageiro.length; i++) {
        if (
          passageiro[i].nome == "" ||
          passageiro[i].genero == "" ||
          passageiro[i].telefone == "" ||
          passageiro[i].cpf == "" ||
          passageiro[i].rg == "" ||
          passageiro[i].nacionalidade == "" ||
          passageiro[i].dt_nascimento == ""
        ) {
          flagPassageiro = false;
        }
        array_cpf[i] = passageiro[i].cpf;
        array_nome[i] = passageiro[i].nome;
        array_rg[i] = passageiro[i].rg;
      }
      if (flagPassageiro) {
        for (var i = 0; i < passageiro.length; i++) {
          if (array_nome.filter(el => el == passageiro[i].nome).length > 1) {
            this.$notify({
              message: "Passageiros com Nomes iguais, favor revisar.",
              type: "danger",
              timeout: 2000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
            return;
          }
          if (array_cpf.filter(el => el == passageiro[i].cpf).length > 1) {
            this.$notify({
              message: "Passageiros com CPFs iguais, favor revisar.",
              type: "danger",
              timeout: 2000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
            return;
          }
          if (array_rg.filter(el => el == passageiro[i].rg).length > 1) {
            this.$notify({
              message: "Passageiros com RGs iguais, favor revisar.",
              type: "danger",
              timeout: 2000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
            return;
          }
        }
        var flagAdulto = true,
          flagCrianca = true,
          flagIdoso = true;
        for (var i = 0; i < passageiro.length; i++) {
          var anosMaximoAdulto = Math.ceil(this.taxaAdulto.dias_maximo / 365);
          var anosMinimoAdulto = Math.ceil(this.taxaAdulto.dias_minimo / 365);

          var anosMaximoCrianca = Math.ceil(this.taxaCrianca.dias_maximo / 365);
          var anosMinimoCrianca = Math.ceil(this.taxaCrianca.dias_minimo / 365);

          var anosMaximoIdoso = Math.ceil(this.taxaIdoso.dias_maximo / 365);
          var anosMinimoIdoso = Math.ceil(this.taxaIdoso.dias_minimo / 365);

          switch (passageiro[i].tipo) {
            case "adulto":
              var idadeAdulto =
                this.data_hoje.getFullYear() -
                passageiro[i].dt_nascimento.substring(0, 4);

              if (
                anosMinimoAdulto > idadeAdulto ||
                idadeAdulto > anosMaximoAdulto
              ) {
                var inputDate = document.getElementsByClassName("date")[i];
                var dateLabel = document.getElementsByClassName(
                  "invalidDate-label"
                )[i];
                dateLabel.style.visibility = "visible";
                inputDate.style.border = "1px solid red";
                Swal.fire({
                  icon: "error",
                  title: "Data Inválida!",
                  text:
                    "A data de nascimento não corresponde ao tipo de passagem (Adulto)",
                  showConfirmButton: false,
                  timer: 2000
                });
                flagAdulto = false;
              }
              break;
            case "crianca":
              var idadeCrianca =
                this.data_hoje.getFullYear() -
                passageiro[i].dt_nascimento.substring(0, 4);

              if (
                anosMinimoCrianca > idadeCrianca ||
                idadeCrianca > anosMaximoCrianca
              ) {
                var inputDate = document.getElementsByClassName("date")[i];
                var dateLabel = document.getElementsByClassName(
                  "invalidDate-label"
                )[i];
                dateLabel.style.visibility = "visible";
                inputDate.style.border = "1px solid red";

                Swal.fire({
                  icon: "error",
                  title: "Data Inválida!",
                  text:
                    "A data de nascimento não corresponde ao tipo de passagem (Criança)",
                  showConfirmButton: false,
                  timer: 2000
                });
                flagCrianca = false;
              }
              break;
            case "idoso":
              var idadeIdoso =
                this.data_hoje.getFullYear() -
                passageiro[i].dt_nascimento.substring(0, 4);

              if (
                anosMinimoIdoso > idadeIdoso ||
                idadeIdoso > anosMaximoIdoso
              ) {
                var inputDate = document.getElementsByClassName("date")[i];
                var dateLabel = document.getElementsByClassName(
                  "invalidDate-label"
                )[i];
                dateLabel.style.visibility = "visible";
                inputDate.style.border = "1px solid red";

                Swal.fire({
                  icon: "error",
                  title: "Data Inválida!",
                  text:
                    "A data de nascimento não corresponde ao tipo de passagem (Idoso)",
                  showConfirmButton: false,
                  timer: 2000
                });
                flagIdoso = false;
              }
              break;
          }
        }
        if (flagAdulto && flagCrianca && flagIdoso) {
          this.flag2 = false;
          this.flag3 = true;
          // this.descontoPassageiro(passageiro);
          if (this.valorAdulto == 0) {
            this.valorAdulto =
              this.dadosViagem.agencias[0].valor_transporte +
              this.dadosViagem.agencias[0].valor_alimentacao +
              this.dadosViagem.agencias[0].valor_acomodacao;
          }

          var payloadInside = {
            valor:
              this.valorIdoso * this.qtdIdoso +
              this.valorCrianca * this.qtdCrianca +
              this.valorAdulto * this.qtdAdulto
          };

          this.$store.dispatch("updatedValorTotal", payloadInside);

          for (var i = 0; i < passageiro.length; i++) {
            this.$store.dispatch("add_passenger", passageiro[i]);
          }
        }
      } else {
        this.$notify({
          message: "Preencha todos os campos!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
    },

    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    validarCompra() {
      if (
        this.verificaComprador(this.dadosComprador) &&
        this.verificaCartao(this.card.displayNumber) &&
        this.isValidCep
      ) {
        document.querySelector(".load").classList.toggle("visible");
        this.finalizarCompra();
      }
    },
    finalizarCompra() {
      if (this.flagBoleto) {
        this.FinalizarCompraBoleto();
      } else if (this.flagDebit || this.flagCredit) {
        this.FinalizarCompraCartao();
      } else {
        this.$notify({
          message: "Informe a forma de pagamento",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    remove(cpf) {
      var payload = {
        cpf: cpf
      };

      this.valorTotalAux =
        this.dadosViagem.agencias[0].valor_transporte +
        this.dadosViagem.agencias[0].valor_alimentacao +
        this.dadosViagem.agencias[0].valor_acomodacao;

      this.$store.dispatch("pop_valorTotal", this.valorTotalAux);

      this.$store.dispatch("pop_passenger", payload);
    },
    returnValor(tipo) {
      switch (tipo) {
        case "adulto":
          return this.valorAdulto;
        case "idoso":
          return this.valorIdoso;
        case "crianca":
          return this.valorCrianca;
      }
    },

    FinalizarCompraCartao() {
      if (this.parcelas == 0 && this.flagCredit) {
        this.$notify({
          message: "Selecione a quantidade de parcelas!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
        return;
      }
      // if (this.flagTermo == false) {
      //   this.$notify({
      //     message: "É necessário concordar com os termos de uso!",
      //     type: "danger",
      //     timeout: 2000,
      //     horizontalAlign: "center",
      //     verticalAlign: "top",
      //     showClose: false,
      //     closeOnClick: false
      //   });
      //   return;
      // }

      if (this.flagDebit) {
        this.parcelas = 0;
      }
      if (this.flagCredit) this.geraPassagens("CreditCard");
      if (this.flagDebit) this.geraPassagens("DebitCard");
    },

    geraPassagens(formaPagamento) {
      this.dadosComprador.email = this.$store.state.session.email;
      let formapagamento = formaPagamento;
      let endereco = null;

      if (this.flagBoleto) {
        formapagamento = "boleto";
        endereco = this.purchase;
      }
      const passageiros = this.$store.state.passageiros;
      const newpassageiros = passageiros.map(p => {
        return { ...p, qrcode: "" };
      });

      console.log("passageiros Form", this.passageiros);
      console.log("newPassageiros", newpassageiros);
      const passeger = this.$store.state.passageiros.find(element => {
        if (element.email === this.$store.state.session.email) {
          return element;
        }
      });

      if (passeger != undefined) {
        this.$store.dispatch("add_passenger_adm", passeger);
        this.flagClientPurchase = true;
      }
      //gerando um array32bits randomico para servir como identificador unico
      //por compra.

      // Criar o body para a requisição
      // Isso abaixo é um exemplo. No futuro, de ve-se obter manualmente essas infos do usuário
      var req_body = {
        payment: {
          pan: this.card.displayNumber.replace(/[^0-9]+/g, ""),
          cardholderName: this.card.holder,
          expirationDate: this.card.data_validation,
          cvv: this.card.validation_value,
          brand: this.card.brand.toUpperCase(),
          installments: this.parcelas,
          customer: {
            name: this.dadosComprador.name,
            ddd: this.dadosComprador.ddd.replace(/[^0-9]+/g, ""),
            phone:
              this.dadosComprador.ddd.replace(/[^0-9]+/g, "") +
              this.dadosComprador.phone.replace(/[^0-9]+/g, ""),
            email: this.dadosComprador.email,
            documentType: this.dadosComprador.documentType,
            document: this.dadosComprador.document.replace(/[^0-9]+/g, ""),
            birthDate: this.formatData(this.dadosComprador.birthDate),
            billing: {
              street: this.dadosComprador.billing.street,
              number: this.dadosComprador.billing.number,
              neighborhood: this.dadosComprador.billing.neighborhood,
              city: this.dadosComprador.billing.city,
              state: this.dadosComprador.billing.state,
              country: this.dadosComprador.billing.country,
              zipcode: this.dadosComprador.billing.zipcode.replace(
                /[^0-9]+/g,
                ""
              )
            },
            fingerPrint: this.fingerPrint[0].toString()
          }
        },
        records: []
      };

      // Isso adiciona os passageiros (junto com suas passagens) dentro do req_body
      for (let index = 0; index < passageiros.length; index++) {
        if (passageiros[index].tipo == "adulto")
          passageiros[index].tipo = "normal";
        var item = {
          pessoa: {
            nome: passageiros[index].nome,
            sexo: passageiros[index].genero,
            nacionalidade: passageiros[index].nacionalidade,
            dt_nascimento: passageiros[index].dt_nascimento.replace(
              /(\d{2})\/(\d{2})\/(\d{4})/,
              "$3-$2-$1"
            ),
            telefone: (
              passageiros[index].ddd + passageiros[index].telefone
            ).replace(/[^0-9]+/g, ""),
            // email: passageiros[index].email,
            rg: passageiros[index].rg.replace(/[^0-9]+/g, ""),
            cpf: passageiros[index].cpf.replace(/[^0-9]+/g, ""),
            orgao_emissor: "SSP-AM"
            // doc_estrangeiro: 'nenhum',
            // tipo_doc_estrangeiro: 'nenhum'
          },
          passagem: {
            vendedor_id: this.dadosViagem.agencias[0].vendedor_id,
            viagem_id: this.dadosViagem.id,
            embarcacao_acomodacao_id: this.dadosViagem.agencias[0]
              .embarcacao_acomodacao_id,
            assento: 0,
            tipo_passagem: passageiros[index].tipo,
            valor_total: passageiros[index].valor,
            valor_desconto: 0,
            dt_compra: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            plataforma: "ecommerce"
          }
        };

        // Caso isso seja true, o passageiro é o próprio usuário
        // Nesse caso, não é necessario enviar o "pessoa" do objeto
        if (this.$store.state.session.email === item.pessoa.email) {
          item.passagem.pessoa_id = this.user.pessoa_id;
          delete item.pessoa;
        }

        req_body.records.push(item);
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`,
          "Content-Type": "application/json"
        }
      };

      this.axios
        .post(`${api}/v3/passagens/compra`, req_body, config)
        .then(response => {
          if (response.data.authenticationUrl != undefined) {
            window.open(response.data.authenticationUrl, "_blank");
          }

          for (var i = 0; i < newpassageiros.length; i++) {
            newpassageiros[i].qrcode = response.data.data[i];
          }

          this.$store.dispatch("remove_passeger_all");
          newpassageiros.map(element => {
            this.$store.dispatch("add_passenger", element);
          });
          // Tudo foi um sucesso
          document.querySelector(".load").classList.toggle("visible");
          Swal.fire({
            icon: "success",
            title: "Compra efetuada com sucesso!",
            text:
              "Obrigado pela compra! Enviaremos a confirmação para o seu email.",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.$router.push(`/checkout`);
          });
        })
        .catch(error => {
          document.querySelector(".load").classList.toggle("visible");
          var nmr_erro = error.response.data.error.cod;
          if (
            error.response.data.error.cod == "001" ||
            error.response.data.error.cod == "002"
          ) {
            Swal.fire({
              title: "Compra Cancelada!",
              text:
                "Falha na conexão com o servidor, tente novamente mais tarde.",
              icon: "error",
              showConfirmButton: false,
              timer: 2100
            });
          } else if (error.response.data.error.cod == "003") {
            Swal.fire({
              title: "Compra Cancelada!",
              text: "Verifique os dados do comprador, e tente novamente.",
              icon: "error",
              showConfirmButton: false,
              timer: 2100
            });
          } else if (error.response.data.error.cod === "005") {
            Swal.fire({
              title: "Compra Cancelada!",
              text:
                "Verifique os dados do seu cartão de crédito, e tente novamente.",
              icon: "error",
              showConfirmButton: false,
              timer: 2100
            });
          } else if (error.response.data.error.cod == "601") {
            Swal.fire({
              title: "Compra Cancelada!",
              text:
                "Falha na conexão com o servidor, tente novamente mais tarde.",
              icon: "error",
              showConfirmButton: false,
              timer: 2100
            });
          } else {
            Swal.fire({
              title: "Compra Cancelada!",
              text:
                "Compra não concluída! Houve um erro ao tentar finalizar sua compra. Tente novamente mais tarde",
              icon: "error",
              showConfirmButton: false,
              timer: 2100
            });
          }
        });
    },

    voltar(rota) {
      this.$router.push(`/${rota}`);
    },
    Modal() {
      this.$modal.show("termosUso");
    }
  },
  created() {
    var randomCryptoHash = new Uint32Array(1);
    this.fingerPrint = window.crypto.getRandomValues(randomCryptoHash);
    //atribuindo o hash gerado a variavel
    var ProviderPrgId =
      process.env.NODE === "production" ? "k8vif92e" : "1snn5n9w";
    var ProviderMerchantId = "redeceler_dm";
    var ProviderMerchantIdentifier = this.fingerPrint[0].toString();
    let noScript = document.createElement("noscript");
    let iFrame = document.createElement("iframe");
    iFrame.setAttribute(
      "src",
      `https://h.online-metrix.net/fp/tags?org_id=${ProviderPrgId}
&session_id=${ProviderMerchantId}${ProviderMerchantIdentifier}`
    );
    noScript.appendChild(iFrame);
    document.head.appendChild(noScript);
  }
  //codigo para adicionar o noscript a tag head do ecommerce,
  //demanda solicitada pelo celer
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Bold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Light.ttf");
}

.popover {
  padding: 0.4rem 1rem;
  z-index: 10;
  margin-left: 0.5rem;
  /* box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5); */
  background-color: rgb(240, 242, 245);
  border-radius: 10px;
  /* border: 1px solid black; */
  display: block;
  opacity: 0.9;
  .popover-title,
  .popover-text {
    color: black;
    font-family: Roboto, sans-serif;
    margin: 0.4rem 0;
  }
  .popover-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  .popover-text {
    font-size: 14px;
    span {
      font-weight: 500;
    }
  }
}

.load {
  min-height: 100vh;
  width: 100%;

  background: rgba(255, 255, 255, 0.658);
  position: absolute;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  /* .gif-load {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    img {
      padding: 20px;
      border-radius: 100%;
    }
  } */
}
.half-circle-spinner {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  top: 0vh;
  width: 100%;
  height: 100%;
  left: -1rem;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #009aa0;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #009aa0;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.visible {
  display: none;
  visibility: hidden;
}

.content-passageirosAgrupados {
  width: fit-content;
  width: 100%;
  position: relative;
  margin-top: 5rem;
  .text-header {
    position: absolute;
    top: -3rem !important;
    /* left: 3rem; */
  }
}

.content-passageiros {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  // margin-right: 16rem;
  margin-bottom: 5rem;
  margin-top: 3.5rem;
  width: 60%;
}
.content-passageiro {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // padding-left: 3rem;
  padding-bottom: 1rem;
  position: relative;

  label {
    font-family: Roboto, sans-serif;
    color: #626262;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0.4rem;
  }

  .content-passageiro-body {
    background-color: #ffffff;
    padding: 1.5rem 2.5rem;
    align-self: stretch;
    position: relative;
    border-radius: 5px;
    align-items: center;
    z-index: 1;
    // width: 48rem;
    // width: 80%;
    border: 1px solid rgba(29, 37, 59, 0.3);

    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    input,
    select {
      margin-bottom: 1.5rem;
      padding: 0.4rem 0.5rem;
      background: #ffffff;
      border: 1px solid #626262;
      color: #626262;
      box-sizing: border-box;
      border-radius: 4px;
      outline: 0;
      height: 2.2rem;
      font-family: Roboto, sans-serif;
      /* width: 12rem; */
      font-size: 14px;

      &::placeholder {
        font-style: italic;
      }
    }
    .expanded-small {
      input {
        width: 10rem !important;
      }
    }
  }

  .id-viagem {
    display: flex;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: fixed;
    left: -10rem;
    top: 43%;
    transform: translate(50%, -50%);
    background-color: #009aa0;
    color: white;
    padding: 1rem;
    width: 17.5rem;
    transform: rotate(-90deg);
    height: 2.5rem;
    label {
      font-size: 12px;
      color: white;
      font-weight: 500;
    }
  }
  .content-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    padding: 1rem;
    top: 0.5rem;
    right: -15rem;
    width: 16rem;
    // height: 17.5rem;
    z-index: 1;
    /* transform: translate(2%, -10%); */
    background: #f0f0f0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border-right: 16px solid #009aa0;
    &::after {
      content: "";
      position: absolute;
      left: 0.5rem;
      height: 17.5rem;
      top: 0rem;
      border-left: 3px dashed #009aa0;
    }
  }
}

.content-passageiros-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  align-self: start;
  // margin-left: -5rem;
  color: #2a3550;
  position: relative;
  // position: absolute;
  // display: block;
  // margin-bottom: 0.5rem;
  // margin-top: 6vh;
}

.content-info-3 {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  // flex-wrap: wrap;
  // width: 60rem;
  width: 100%;

  margin: 2rem 0;

  padding: 2rem;
  // background-color: #fff;
  background: #f0f0f0;

  border-radius: 10px;

  color: #626262;
  font-family: "Roboto";

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-right: 5px solid #009aa0;
  border-left: 3px solid #009aa0;

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5em;
    text-align: left;
    // margin-bottom: 0.5rem;
    // margin-top: 1rem;
    color: #2a3550;
    font-size: 30px;
    // text-align: center;
    // margin-bottom: 0.5rem;
    // margin-top: 6vh;
  }

  .id-viagem {
    font-size: 0.75rem;
    font-weight: 500;
  }

  .id-cell {
    display: flex;
    flex-direction: column;
  }

  .id-cell-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .id-cell-info {
    font-size: 1.2rem;
  }

  .destino {
    color: #009aa0;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .content-info3-main {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    width: 100%;

    // margin-bottom: 2rem;

    & > div {
      margin-left: 1rem;
    }
  }

  .additional-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;

    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .content-passageiros {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .content-passageiros {
    width: 90%;
  }
}
@media only screen and (max-width: 425px) {
  .content-info-3 {
    padding: 0.7rem 1rem;
  }
  .content-passageiro {
    width: 100%;
  }
  .content-passageiros {
    width: 90%;
  }
  .content-passageiro-body {
    padding: 1rem 1.5rem !important;
    width: 100%;
  }
  .column-input input {
    width: 9rem;
  }
  .ddd-input {
    width: fit-content;
  }
  .ddd-input input {
    width: 3rem;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  .inline-input {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0rem !important;
  }
  .nome-input {
    margin-right: 5rem;
  }
  input,
  select {
    width: 100% !important;
  }
}

.content-viagem {
  display: flex;
  position: relative;
  justify-content: center;

  .content-viagem-body {
    display: flex;
  }
  label {
    font-family: Roboto, sans-serif;
    color: #626262;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0.6rem;
  }
  .content-left {
    width: 22rem;

    img {
      width: 300px;
      height: 200px;
      object-fit: fill;
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 10px;
    width: 22rem;
    max-height: 480px;
    margin-left: 1rem;
    img {
      fill: #626262;
    }
  }
  .passagensAlternativas {
    padding: 1rem;
    border-left: 3px solid #009aa0;
    border-right: 3px solid #009aa0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 0.5rem;
    border-radius: 5px;
    label {
      color: #000000;
    }
    &:hover {
      transform: translate(0px, -1px);
      cursor: pointer;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }
  }
  .content-buttons {
    flex-direction: column;
    display: flex;
    align-self: stretch;
    button {
      padding: 0.5rem;
      margin-top: 0.8rem;
      font-weight: 500;
      font-family: Roboto, sans-serif;
    }
  }
}

.content-left {
  width: 910px;
  display: flex;
  flex-direction: column;
  button {
    padding: 0.8rem 1.2rem;
    background: #009aa0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    cursor: pointer;
  }
}

/* RESPONSIVIDADE SELEÇÃO DE VIAGENS */

@media only screen and (max-width: 550px) {
  .text-header {
    font-size: 1.3rem !important;
    color: red;
    word-break: break-word;
    left: 2rem;
    margin-bottom: 5vh !important;
  }
  .content-body-details {
    flex-direction: column;
    gap: 1rem;
    margin: 5vh 0;
    margin-bottom: 5vh;

    .content-right {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 825px) {
  .content-body-details {
    margin-left: 0.5rem;
  }

  .content-viagem {
    min-width: 100vw;
  }

  .content-viagem-body {
    /* flex-wrap: wrap; */
    justify-content: center;
  }
  .content-right.recommended {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .content-right-responsive {
    width: calc(22rem * 0.85) !important;
  }
  .content-left-responsive {
    width: calc(22rem * 0.85) !important;
  }
}

/* RESPONSIVIDADE DADOS DA COMPRA */

@media only screen and (max-width: 1350px) {
  .content-embarcacao {
    /* width: 370px !important; */
  }
  .content-top {
  }
  .inline-input {
  }
}

.content-embarcacao {
  width: 24rem;
  align-self: baseline;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem;
  label {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #626262;
    font-weight: 400;
    font-style: normal;
    margin: 0.2rem 0;
  }
  .label-strong {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;

    margin-bottom: 1rem;
  }

  .label-strong2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    /* margin-bottom: 1rem; */
  }

  .label-subtitle2 {
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .content-buttons {
    flex-direction: column;
    display: flex;
    align-self: stretch;
    button {
      padding: 0.7rem;
      margin-top: 0.8rem;
      font-weight: 500;
      font-family: Roboto, sans-serif;
    }
  }
}

.content-title {
  font-family: Roboto, sans-serif;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #626262;
}
.align-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0;
  .align-label {
    display: flex;
    flex-direction: column;
  }
  .label-subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 0.5rem;
  }
}

.content-purchase {
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.content-bottom {
  .top-input {
    input {
      width: 26rem;
    }
  }
  .card-holder-input {
    input {
      width: 22rem;
    }
  }
  .card-cvv-input {
    input {
      width: 12rem;
    }
  }
}

.invalidDate-label {
  position: absolute;
  color: red !important;
  font-style: italic !important;
  bottom: 0;
  visibility: hidden;
}

.content-bottom,
.content-top {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem;
  label {
    margin: 0.8rem 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #626262;
    font-weight: 500;
  }
  input,
  select {
    padding: 0.4rem 0.5rem;
    background-color: #ffffff;
    border: 1px solid #626262;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    font-size: 14px;
    height: 2.2rem;
    margin-bottom: 0.5rem;

    &::placeholder {
      font-style: italic;
    }
  }
}

.inline-input {
  display: flex;
  // justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;

  /* input {
    color: #626262;
  } */
}

.column-input {
  display: flex;
  flex-direction: column;
}
.number-input {
  input {
    width: 6rem !important;
  }
}

.endereco-input {
  input {
    width: 20rem;
  }
}

.email-input {
  width: 22.5rem;
}
.nome-input {
  flex: 1;
  // margin-right: 1rem;
  width: max-content !important;
}

.expanded-input {
  input {
    width: 15rem;
  }
}

.expanded-small {
  input,
  select {
    width: 12rem !important;
  }
}

.ddd-input {
  width: 3rem;
  // margin-right: 1rem;
}

.flex-start {
  justify-content: flex-start;
  div + div {
    margin-right: 2rem;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
.disabled2 {
  background-color: rgb(202, 202, 202) !important;
  opacity: 0.5;
}
// .div-caminho {
//   margin-top: 14vh;
//   margin-bottom: 5vh;
// }
.select-input {
  border: none;
  margin-left: 0.5rem;
  width: 94%;
  background-color: transparent;
  font-family: "OpenSans-Regular", sans-serif;
}
.termos-uso {
  display: flex;
  margin: 0 auto;
  margin-top: 1rem;
  margin-left: 30px;
  input {
    width: 20px;
    margin: 0;
    padding: 0;
  }
  label {
    font-family: "OpenSans-Regular", sans-serif;
    font-size: 14px;
  }
}
.content-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-right: 12px;

  img {
    width: 22px;
    cursor: pointer;
  }
  .disabled {
    cursor: default;
  }
  .text-field-label3 {
    color: white;
    font-size: 13px;
    margin-left: 0;
  }
}
.esqueci {
  background-color: transparent;
  margin: 0 auto;
  font-family: "OpenSans-Regular", sans-serif;
  // line-height: 2.5;
  cursor: pointer;
  // padding: -0.7rem 0.6rem;
  border: 0;
  // border-radius: 0.5rem;
  // font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: gray;
  vertical-align: middle;
  outline: none;
  margin-left: 30px;
}
#search_input {
  // margin-top: 1rem;
  cursor: pointer;
  border: 1px solid gray;
  padding: 5px 3px;
  border-radius: 5px;
  color: #35415e;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: bold;
}
.primeira-secao {
  display: flex;
  color: white;
  flex-direction: column;
  align-content: center;
  align-self: center;
  font-family: "OpenSans-Bold", sans-serif;
}

.primeira-secao-img {
  display: flex;
  align-content: center;
  align-items: center;
}

.text-acomodacao {
  // background-color: #fdb417;
  // border: 2px solid #fdb417;
  padding: 5px 8px;
  margin: 5px;
  border-radius: 22px;
  margin-left: 30px;
  font-size: 12px;
  font-family: "OpenSans-Bold", sans-serif;
}

.segunda-secao {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  align-items: center;
  color: white;
  font-family: "OpenSans-Regular", sans-serif;
  img {
    width: 20px;
  }
}

.terceira-secao {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  align-items: center;
  color: white;
  font-family: "OpenSans-Regular", sans-serif;
  img {
    width: 20px;
  }
}

.quarta-secao {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  align-items: center;
  color: white;
  margin-top: 8px;
  font-family: "OpenSans-Regular", sans-serif;
  img {
    width: 20px;
  }
}

.quinta-secao {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  align-items: center;
  color: white;
  margin-top: 8px;
  font-family: "OpenSans-Regular", sans-serif;
  img {
    margin-left: 12px;
    width: 20px;
    cursor: pointer;
  }
}

.text-field-label {
  font-family: "OpenSans-Light", sans-serif;
  color: white;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 7px;
  margin-left: 30px;
}
.text-field-label2 {
  margin-left: 8px;
  font-size: 12px;
  font-family: "OpenSans-Bold", sans-serif;
}
.text-field-label3 {
  margin-left: 60px;
  font-size: 15px;
  font-family: "OpenSans-Bold", sans-serif;
}
.text-field-label4 {
  color: white;
  font-family: "OpenSans-Regular", sans-serif;
  margin-bottom: 12px;
  margin-left: 30px;
}
.text-field-price {
  font-size: 32px;
  font-family: "OpenSans-Bold", sans-serif;
  color: white;
  // margin-bottom: 40px;
  margin-left: 30px;
}
.text-field-escala {
  margin-left: 45px;
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 12px;
}

.text-field-acomodacao {
  margin-left: 30px;
  font-size: 12px;
  font-family: "OpenSans-Regular", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}
.text-field-embarcacao {
  margin-left: 30px;
  font-size: 11px;
  font-family: "OpenSans-Bold", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-input-div {
  position: relative;
  width: 60%;
  margin: 2rem auto;
  // border: 1px solid rgba(29, 37, 59, 0.5);
  margin-bottom: 0.6rem;
  // border-radius: 4px;
}
::placeholder {
  color: rgba(37, 35, 35, 0.472);
}
.my-input {
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 92%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  padding: 12px 0px 12px 18px;
  border: none;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}
.my-input:focus {
  outline: none;
  border: none;
}
#inputPassword {
  margin-bottom: 1rem;
}
.icons {
  right: 10%;
  position: absolute;
  bottom: 10px;
}
.text-modal {
  text-align: center;
  font-size: 20px;
  font-family: "OpenSans-Regular", sans-serif;
  color: #2a3550;
  margin-top: 1rem;
}
@media only screen and (min-width: 900px) {
  .dissapear {
    display: none !important;
  }
}

.entire-page {
  position: relative;
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  /* Footer height */
  padding-top: 1px; /* Footer height */
  display: block;
  justify-content: center;
  .card-info-embarcacao {
    padding: 1rem 1rem;
    margin-top: 10px;
    margin-bottom: 7rem;
    background-color: #35415e;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .input-style {
    background-color: rgba(0, 0, 0, 0.07);
    border-bottom: 1px solid #35415e;
    // padding: 4px 12px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin-bottom: 1rem;
    p {
      color: #35415e;
      font-size: 12px;
      font-family: "OpenSans-Regular", sans-serif;
      margin-bottom: 2px;
      padding: 4px 12px;
    }
    input {
      border: none;
      background-color: transparent;
      // margin-bottom: 8px;
      padding: 2px 12px;
      width: 100%;
    }
  }

  .input-style :focus {
    border-bottom: 1px solid #02e1db;
  }
  .text-header {
    font-family: "Roboto", sans-serif;
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    position: absolute;
    top: -2rem;
    /* color: #2A3550; */
    color: #2a3550;
  }
  .text-header2 {
    color: white;
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 30px;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 6vh;
  }
  .card-id {
    margin-top: 16px;
    // margin-bottom: 24px;
    background-color: #fdb417;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-top: 2.4rem;
    transform: rotate(0deg);
    width: 60px;
  }
  .card-passageiros-agrupados {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 6vh; */
    .align {
      padding-bottom: 0rem;
    }
    .card-id {
      margin-top: 16px;
      margin-bottom: 7rem;
      background-color: #fdb417;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-top: 2.4rem;
      transform: rotate(0deg);
      width: 60px;
    }
    .card-input-passageiro {
      position: relative;
      padding: 1.4rem 1rem;
      height: 485px;
      background-color: #ffffff;
      // display: flex;
      // flex-direction: column;
    }
    @media only screen and (max-width: 900px) {
      .dissapear {
        height: 200px;
      }
      .hiddenable {
        display: none;
      }
      .card-passageiro {
        height: auto !important;
        margin-top: 1rem;
      }
      .card-id {
        margin-bottom: 0rem !important;
      }
      .card-info-embarcacao {
        margin-bottom: 0rem !important;
      }
      .card-input-passageiro {
        height: 58rem;
      }
      .card-info-embarcacao {
        height: 390px;
        margin-top: 16px;
      }
      .btn-compra2 {
        margin: 2rem;
        font-size: 0.7em;
      }
    }
    @media only screen and (min-width: 900px) {
      .dissapear {
        display: none !important;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content-purchase-direction {
      flex-direction: column !important;
    }
  }
  .card-passageiro {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    height: 520px;
    // margin-bottom: 4rem;
  }
  .btn-compra3 {
    background-color: #009aa0;
    cursor: pointer;
    padding: 0.4rem 4rem;
    border: 1px solid #009aa0;
    font-family: "OpenSans-Regular", sans-serif;
    border-radius: 8px;
    color: white;
  }
  .card-purchase {
    display: flex;
    position: relative;
    justify-content: center;
    // padding-bottom: 15vh;
    @media only screen and (min-width: 900px) {
      .content-flex {
        flex-direction: row !important;
      }
    }
    @media only screen and (max-width: 380px) {
      .forma-pagamento-input {
        flex-direction: row !important;
        display: block !important;
        #search_input {
          margin-top: 1rem;
          margin-left: 30px;
        }
      }
    }
    @media only screen and (max-width: 900px) {
      .card-id {
        display: none;
      }
      .card-forma-pagamento {
        width: fit-content !important;
      }
      .content-flex {
        flex-direction: column;
      }
      .hiddenable {
        display: none;
      }
      .dissapear {
        width: fit-content;
        margin-top: 1rem;
      }
      .content-types-passager {
        width: 70% !important;
      }
      .card-input-passageiro {
        width: 100% !important;
      }
      .btn-compra3 {
        padding: 0.4rem 2rem;
        margin-bottom: 1rem;
      }
    }

    .card-input-passageiro {
      padding: 1.4rem 1rem;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      width: 400px;
      .text-field-purchase {
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 24px;
        color: #35415e;
        margin-bottom: 0.5rem;
      }

      .card-inputs {
        display: flex;
        justify-content: space-between;
      }
    }
    .align {
      padding-bottom: 4rem;
    }
    .card-id {
      margin-top: 16px;
      margin-bottom: 32px;
      background-color: #fdb417;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-top: 2.4rem;
      transform: rotate(0deg);
      width: 60px;
    }
    .text-id {
      // position: ;
      // width: 4.4rem;
      font-family: "OpenSans-Regular", sans-serif;
      color: white;
      transform: rotate(-90deg);
      width: 80px;
    }
    .primeira-secao {
      display: flex;
      color: #2a3550;
      align-self: flex-start;
      flex-direction: column;
      align-self: flex-start;
      font-family: "OpenSans-Bold", sans-serif;
      .text-field-embarcacao {
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 5px;
      }
    }

    .primeira-secao-img {
      display: flex;
      align-content: center;
      align-items: center;

      img {
        filter: invert(18%) sepia(52%) saturate(466%) hue-rotate(184deg)
          brightness(43%) contrast(223%);
      }
    }
    .card-forma-pagamento {
      .text-field-purchase {
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 20px;
        color: #35415e;
        margin-left: 30px;
        margin-bottom: 0.5rem;
      }
      .text-field-label3 {
        color: white;
        font-size: 13px;
        margin-left: 0;
      }
      .brands {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
        align-items: center;
        img {
          width: 50px;
        }
      }
      width: 400px;
      background-color: white;
      .text-field-label4 {
        color: #35415e;
      }
      .text-field-price {
        color: #35415e;
      }
      img {
        width: 60px;
        height: auto;
      }
      .content-types-passager {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-left: 30px;
        p {
          color: #35415e;
          font-size: 15px;
        }
      }
    }

    .text-field-label4 {
      margin-top: 1rem;
    }
    .forma-pagamento-input {
      display: flex;
      align-content: center;
      margin-bottom: 0.5rem;
      align-items: center;
      img {
        margin-right: 1rem;
      }
      input {
        margin-right: 0.5rem;
      }
    }
  }
  .text-id {
    // position: ;
    // width: 4.4rem;
    font-family: "OpenSans-Regular", sans-serif;
    color: white;
    transform: rotate(-90deg);
    width: 80px;
  }
  .btn-compra2 {
    background-color: #009aa0;
    // margin: 10px auto;
    // position: absolute;
    cursor: pointer;
    // width: 6rem;
    // margin-right: 8px;
    // border: none;
    padding: 0.4rem 2rem;
    border: 1px solid #009aa0;
    font-family: "OpenSans-Regular", sans-serif;
    text-align: center;
    // float: r;
    border-radius: 8px;
    color: white;
    // vertical-align: middle;
  }
  .align {
    display: flex;
    flex-direction: column;
    /* padding-bottom: 5rem; */
    margin: 0 auto;
    width: fit-content;
    align-items: flex-end;
    .card-id {
      height: 390px;
    }
    @media only screen and (max-width: 900px) {
      align-items: center;
    }
    .text-header2 {
      align-self: flex-start;
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .card-comprador {
      align-self: flex-start;
    }
    .card-comprador-body {
      display: flex;
      .card-id {
        margin-bottom: 0;
      }

      .card-input-comprador {
        padding: 1.4rem 1rem 0rem;
        background-color: #ffffff;
      }
    }
  }

  .btn-modal {
    margin-bottom: 0.6rem;
    margin-right: 6rem;
    background-color: #009aa0;
    border: none;
    cursor: pointer;
    padding: 0.4rem 2rem;
    border-radius: 4px;
    color: white;
    font-family: "OpenSans-Regular", sans-serif;
  }
  .btn-compra {
    background-color: #009aa0;
    cursor: pointer;
    padding: 0.4rem 2rem;
    border: 1px solid #009aa0;
    font-family: "OpenSans-Regular", sans-serif;
    text-align: center;
    border-radius: 8px;
    color: white;
  }
  .card-viagem {
    .img-ship {
      object-fit: fill;
      width: 33rem;
      height: 26rem;
    }
    background-color: transparent;
    position: relative;
    // width: 70%;
    // height: 370px;
    display: flex;
    flex-direction: row;
    // margin: 0 auto;
    border-radius: 0.2rem;
    justify-content: center;
    @media only screen and (max-width: 900px) {
      .img-ship {
        width: 13rem !important;
        height: auto !important;
      }
      flex-direction: column;
      .card-id {
        border-bottom-left-radius: 0rem;
        img {
          width: 13rem;
        }
        height: auto;
        margin-top: 0rem;
      }
      .card-info {
        margin-top: 0rem !important;
        border-top-right-radius: 0rem !important;
        width: 268px !important;
      }
    }
    .card-passageiro {
      width: 100%;
    }

    .card-title {
      width: 100%;
      text-align: center;
      font-size: 2rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: gray;
      padding-bottom: 0.5rem;
      border-bottom: 2px dashed #cccccc;
      margin-bottom: 1rem;
    }

    .card-image {
      // display: inline-block;
      // width: 100%;
      // @media only screen and (max-width: 550px) {
      //   display: none;
      // }

      img {
        width: 35rem;
        height: 26rem;
        background-position: center;
        object-fit: cover;
        border-radius: 0.3rem;
      }
    }
    .text-field-label4 {
      margin-left: 0;
      margin-top: 0.5rem;
      margin-bottom: 0.2rem;
      font-family: "OpenSans-Light", sans-serif;
    }
    .text-field-price {
      margin: 0;
      font-size: 30px;
    }

    .card-info {
      // font-family: Poppins, sans-serif;
      display: flex;
      flex-direction: column;
      // font-weight: 300;
      // line-height: 120%;
      display: inline-block;
      background-color: #35415e;
      margin-top: 16px;
      width: 270px;
      height: 390px;
      padding-top: 0.4rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 1rem;
      vertical-align: top;
      @media only screen and (max-width: 600px) {
        font-size: 1rem;
      }
      // @media only screen and (max-width: 550px) {
      //   width: 100%;
      // }
      .quinta-secao {
        margin-top: 0px;
      }

      .titulo {
        display: block;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 0.8rem;
        color: #888888;
      }

      .desc {
        padding: 0.1rem 0.5rem;
        display: block;
        background-color: #eeeeeeee;
        margin-bottom: 0.2rem;
      }
      .agencia {
        padding: 0.1rem 0.5rem;
        background-color: #eeeeeeee;
        margin-bottom: 0.2rem;
        display: block;
      }
      .preco {
        padding: 0.1rem 0.5rem;
        background-color: #baf1a4dd;
        padding: 0.2rem;
        text-align: right;
        display: block;
      }
    }
  }

  .highlight {
    font-weight: 400;
  }

  .aviso {
    display: block;
    text-align: center;
    padding-top: 0.4rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    color: #404040;
    border-top: 1px dashed #cccccc;
    margin-top: 1rem;
  }
}

.div-button {
  width: 30%;
  margin: 1rem auto;
  @media only screen and (max-width: 900px) {
    width: 50%;
  }

  @media only screen and (max-width: 530px) {
    width: 90%;
  }

  .adicionar-mais-button {
    position: relative;
    margin: 0 auto;
    color: white;
    background-color: #344675;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 0.4rem 2rem;
    border: 0;
    border-radius: 0.5rem;
    font-size: 100%;
    text-align: center;
    margin: 0.3rem auto;
    cursor: pointer;
    width: 100%;
  }
}

.passageiros {
  margin: 1rem auto;
  margin-top: 1rem;
  width: 50%;
  text-align: center;
  margin-bottom: 1rem;
  .no-radius-table {
    border-radius: 0;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
  }

  .passageiros-title {
    background-color: #344675;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 0.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: white;
    font-size: 1.5rem;
    border-bottom: 1px dashed #cccccc;
  }

  table {
    width: 100%;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    thead {
      background-color: #dddddd;
      vertical-align: middle;
      tr {
        width: 100%;
        margin: 0 auto;
        border-bottom: 1px dashed #cccccc;

        th {
          width: 25%;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        td {
          width: 25%;
          padding-top: 0.5rem;
          vertical-align: middle;
          font-family: "Poppins", sans-serif;
          font-weight: 200;
          padding-bottom: 0.5rem;
          padding-left: 2%;
          padding-right: 2%;
          @media only screen and (max-width: 600px) {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.divider {
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  color: #344675;
  background-color: white;

  border-bottom: 5px dashed #cccccc;
  border-top: 5px dashed #cccccc;

  @media only screen and (max-width: 550px) {
    font-size: 2rem;
    margin-top: 1rem;
  }
}

.forma-pagamento {
  width: 50%;
  border-radius: 1rem;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }

  @media only screen and (max-width: 650px) {
    width: 80%;
  }

  .forma-pagamento-title {
    background-color: #344675;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
    width: 100%;
    padding: 0.5rem 0;
    border-bottom: 1px dashed #cccccc;
    vertical-align: middle;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
  }
}
.forma-pagamento-body {
  background-color: white;
  // width: 100%;
  // padding: 1rem 0;
  // margin-left: 2rem;
  label {
    font-family: "OpenSans-Regular", sans-serif;
    vertical-align: middle;
  }
}

.cartao-form {
  border-top: 2px dashed #cccccc;
  margin-top: 1rem;
  padding-top: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  div {
    width: 50%;
    display: inline-block;
    input {
      margin: 0.2rem;
      width: 80%;
      padding: 0.3rem;
      border-radius: 0.2rem;
      border: 1px solid #cccccc;
    }
  }

  .div-guardar {
    display: block;
    width: 50%;
    margin: 0.5rem auto;

    .button-guardar {
      width: 100%;
      background-color: #39b935;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      border: 0;
      border-radius: 0.5rem;
      font-size: 100%;
      text-align: center;
      margin-bottom: 8px;
      cursor: pointer;
      color: white;
      vertical-align: middle;
    }
  }
}
.seus-cartoes {
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  .seus-cartoes-title {
    font-size: 1.5rem;
    color: #344675;
    border-top: 2px dashed #cccccc;
    font-family: "Poppins", sans-serif;
    padding: 0.8rem 0;
    font-weight: 500;
  }
  .seu-cartao {
    padding: 0.2rem 0;
  }
}

.total-pagar {
  background-color: #39b935;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0.7rem 0rem;
  border: 0;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  vertical-align: middle;
  color: white;
  width: 100%;
  margin: 0 auto;
  border-radius: 1rem;
  margin-bottom: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.adicionar-cartao {
  width: 50%;
  border-radius: 1rem;
  background-color: #39b935;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0.3rem 0.6rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  margin-top: 8px;
  cursor: pointer;
  color: white;
  vertical-align: middle;
}

.div-finalizar {
  width: 30%;
  margin: 1rem auto;
  .finalizar-compra {
    width: 100%;
    border-radius: 1rem;
    background-color: #39b935;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.6rem;
    border: 0;
    border-radius: 0.5rem;
    font-size: 100%;
    text-align: center;
    margin-bottom: 0;
    cursor: pointer;
    color: white;
    vertical-align: middle;
  }
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  // z-index: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 220px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}
</style>
