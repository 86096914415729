<template>
  <div class="entire-div">
    <esqueci-senha></esqueci-senha>
  </div>
</template>

<script>
import EsqueciSenha from './Profile/ForgotPassword';
import navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default {
  components: {
    EsqueciSenha,
    navbar,
    Footer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.entire-div {
  position: relative;
  background-color: #2a3550;
  width: 100%;
  height: 100%;
  min-height: 93vh;
  padding-bottom: 7vh; /* Footer height */
  display: block;
  justify-content: center;
  z-index: 0;
}
</style>
