<template>
  <div>
    <div class="entire-div">
      <div class="div-margin">
        <div class="card">
          <h5 class="card-title">Alterar senha</h5>
          <div class="row">
            <div class="password">
              <input
                autocomplete="off"
                type="password"
                class="input-password"
                label="password"
                v-model="password"
                placeholder="Senha Atual"
              />
            </div>
            <div class="password">
              <input
                autocomplete="off"
                type="password"
                class="input-password"
                label="password"
                v-model="new_password"
                placeholder="Nova Senha"
              />
            </div>
            <div class="password">
              <input
                autocomplete="off"
                type="password"
                class="input-password"
                label="password"
                v-model="confirm_password"
                placeholder="Confirma Nova Senha"
              />
            </div>
          </div>
          <div>
            <button class="button-cadastrar" type="info" @click="enviar" fill>Alterar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../api';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      password: '',
      new_password: '',
      confirm_password: ''
    };
  },
  methods: {
    enviar() {
      let valido = this.password && this.new_password && this.confirm_password;
      let equals = this.new_password === this.confirm_password;
      if (!valido) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, preencha todos os campos'
        });
        return;
      } else if (!equals) {
        this.$notify({
          message: 'Nova senha e Confirme nova senha tem que ser iguais',
          type: 'danger',
          timeout: 2000,
          horizontalAlign: 'center',
          verticalAlign: 'top',
          showClose: false,
          closeOnClick: false
        });
        return;
      }

      const body = {
        senha: this.password,
        nova_senha: this.new_password
      };
      const config2 = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.session.token}`
        }
      };

      this.axios
        .patch(`${api}/usuarios/perfil/senha`, body, config2)
        .then(statusCode => {
          if (statusCode.data.message === 'Password changed successfully') {
            this.$notify({
              message: 'Senha atualizada com sucesso',
              type: 'success',
              timeout: 4000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false
            });
            this.$router.push('/');
          } else {
            this.$notify({
              message:
                'Problemas ao atualizar senha, tente novamente mais tarde',
              type: 'danger',
              timeout: 4000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              showClose: false,
              closeOnClick: false
            });
          }
        })
        .catch(error => {
          this.$notify({
            message: 'Senha Atual Incorreta',
            type: 'danger',
            timeout: 4000,
            horizontalAlign: 'center',
            verticalAlign: 'top',
            showClose: false,
            closeOnClick: false
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.div-margin {
  padding-top: 15vh;
  .card {
    background-color: white;
    width: 20%;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1100px) {
      width: 50%;
    }

    @media only screen and (max-width: 900px) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: 550px) {
      width: 80%;
    }

    .card-title {
      text-transform: inherit;
      font-weight: 300;
      margin-bottom: 2rem;
      margin-top: 0.2rem;
      font-size: 1.5rem;
      font-family: Poppins, sans-serif;
      text-align: center;
      color: #1d253b;
      padding-bottom: 1rem;
      border-bottom: 2px dashed #cccccc;
    }
  }
}

input {
  caret-color: #1d253b;
  font-family: 'Poppins', sans-serif;

  display: block;
  width: 100%;
  height: 50%;
  margin: 0.6rem 0.9rem;
  margin-top: -0.2rem;
  border: none;
  font-weight: 300;
  font-family: Poppins, sans-serif !important;
  border-radius: 0.25rem;

  animation: blink-caret 0.75s step-end infinite;
}

input {
  outline: none;
  border: 1px solid #1d253b50;
  padding: 0.2rem;
  width: 70%;
  margin: 0.4rem auto;
}

.button-cadastrar {
  display: block;
  margin: 0 auto;
  background-color: #1d253b;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0.3rem 0.6rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: white;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}
</style>
