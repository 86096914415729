<template>
  <div>
    <div class="entire-div">
      <div class="central-div">
        <h5 class="header">Faça seu login para continuar.</h5>
        <div class="login-card">
          <img :src="logoNavegam" class="logo-navegam" />

          <form class="my-form">
            <div class="my-input-div email-input">
              <input
                v-model="email"
                id="inputEmail"
                type="email"
                autocomplete="off"
                class="my-input"
                placeholder="Login"
                required
                autofocus
              />
              <img :src="personIcon" class="icons" />
              <!-- <input type="checkbox" class id="customCheck1" /> -->
              <!-- <label for="customCheck1">
                <button @click="rotas('esquecisenha')" class="esqueci">Esqueci minha senha</button>
              </label>-->
            </div>
            <div class="my-input-div password-input">
              <input
                id="inputPassword"
                :type="isVisible ? 'text' : 'password'"
                autocomplete="off"
                v-model="senha"
                class="my-input"
                placeholder="Senha"
                required
              />
              <b-icon
                class="iconVisible"
                :icon="this.isVisible ? 'eye' : 'eye-slash'"
                @click="
                  () => {
                    this.isVisible = !this.isVisible;
                  }
                "
              ></b-icon>
            </div>

            <div class="myCaptcha">
              <vue-recaptcha
                class="captcha"
                sitekey="6LdG1bEZAAAAAI4OhfZBqSiQcFdff_rf373BG_Wp"
                @verify="onVerify"
                :loadRecaptchaScript="true"
              ></vue-recaptcha>
            </div>
            <div class="flex-buttons">
              <button
                type="button"
                class="button-cadastrar"
                @click="rotas('signup')"
              >
                CADASTRAR
              </button>
              <button type="button" class="button-login" @click="logar()">
                ENTRAR
              </button>
            </div>

            <!-- <button @click="rotas('signup')" class="signup" variant="success">Cadastrar</button> -->
          </form>
          <button @click="rotas('esquecisenha')" class="esqueci">
            Esqueci minha senha
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import { BarLoader, ClipLoader } from "@saeris/vue-spinners";
import axios from "axios";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { constants } from "crypto";
// import store from '../store/index.js'
import Multiselect from "vue-multiselect";
import api from "../api";
import VueRecaptcha from "vue-recaptcha";
import logoNavegam from "@/assets/icons/logo_navegam.svg";

import internetAvailable from "internet-available";
import isOnline from "is-online";

import personIcon from "@/assets/icons/person_icon.svg";
import lockIcon from "@/assets/icons/lock_icon.svg";

//import VueLoadingButton from "vue-loading-button";

export default {
  components: {
    VueRecaptcha,
    Multiselect,
    Footer
  },

  name: "Login",
  data() {
    return {
      params: {
        client_id:
          "106441160049-hluknfum2os1gdhji7i35n2u51ro0q8k.apps.googleusercontent.com"
      },
      tokenCaptcha: "",
      recaptcha: false,
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
        title: "s"
      },
      senha: "",
      email: "",
      items: [],
      destino: "",
      origem: "",
      optionsOrigem: [],
      optionsDestino: [],
      loadMore: true,
      usuario: "",

      disabled: false,
      isVisible: false,
      loading: false,
      color: "green",
      px: "300px",
      logoNavegam,
      personIcon,
      lockIcon
    };
  },

  async mounted() {
    if (this.$store.state.session.auth == true) {
      this.$router.push("/");
    }
  },

  watch: {
    recaptcha: function(val) {}
  },
  methods: {
    onVerify: function(response) {
      this.recaptcha = true;
      this.tokenCaptcha = response;
    },
    redirectClickToGoogleButton() {
      document.getElementById("google-button").click();
    },
    async onSuccessGoogleLogin(googleUser) {
      let userInfo = googleUser.getBasicProfile();
      //gabriel, essa variavel userInfo tem algumas informações do usuário,
      // caso queira ver o token gerado jwt
      // é só dar um console log na variável que está em parmetro googleUser
      // seta os valores necessários no vuex e depois redireciona para a página que tem q ser
      //redirecionado
      // se possivel coloca o nome de quem está logado em alguma parte do sistema
      // porque asism nao da pra saber qm está logado
    },
    onFailureGoogleLogin(error) {},
    rotas(rota) {
      this.$router.push(`/${rota}`);
    },
    async logar() {
      if (!this.recaptcha) {
        this.$notify({
          message: "Confirme que não é um robô!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
      if (this.email && this.senha) {
        if (this.recaptcha == true) {
          this.disabled = true;

          this.loading = true;

          const payload = {
            usuario: this.email,
            senha: this.senha
          };

          let config = {
            headers: {
              token: this.$store.state.session.token,
              "Content-Type": "application/json"
            }
          };
          //   fetch(`${baseURL}/usuarios/login`, { method: 'POST', body: body, headers: myHeaders, signal: signal })
          const abortController = new AbortController();
          const signal = abortController.signal;
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const body = {
            usuario: this.email,
            senha: this.senha
          };

          await this.axios
            .post(`${api}/usuarios/login`, body, myHeaders, signal)
            .then(async response => {
              if (response.data.token) {
                const config2 = {
                  headers: {
                    Authorization: `Bearer ${response.data.token}`
                  }
                };
                var token = response.data.token;
                await this.axios
                  .get(`${api}/usuarios/perfil`, config2)
                  .then(response => {
                    var payloadInside = {
                      tokenCaptcha: this.tokenCaptcha,
                      id: response.data.pessoa.id,
                      usuario: response.data.pessoa.nome,
                      email: response.data.usuario.usuario,
                      token: token
                    };

                    this.$store.dispatch("login", payloadInside);
                    this.$notify({
                      message: "Usuário logado com sucesso!",
                      type: "success",
                      timeout: 2000,
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      showClose: false,
                      closeOnClick: false
                    });
                    this.$router.push("/");
                    // if (this.$store.state.dadosViagem.id != "") {
                    //   this.$router.push({
                    //     name: "purchase",
                    //     params: {
                    //       id: this.$store.state.dadosViagem.id,
                    //       key: this.$store.state.dadosViagem.key
                    //     }
                    //   });
                    // } else {
                    //   this.$router.push("/");
                    // }
                  });
              } else {
                this.$notify({
                  message: "Usuário logado!",
                  type: "success",
                  timeout: 2000,
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  showClose: false,
                  closeOnClick: false
                });
              }
            })
            // That user is not registered
            // Password incorrect
            .catch(error => {
              if (
                error.response.data.message == "That user is not registered."
              ) {
                this.$notify({
                  message: "Email ou senha incorretos",
                  type: "danger",
                  timeout: 2000,
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  showClose: false,
                  closeOnClick: false
                });
              } else if (error.response.data.message == "Password incorrect.") {
                this.$notify({
                  message: "Senha incorreta.",
                  type: "danger",
                  timeout: 2000,
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  showClose: false,
                  closeOnClick: false
                });
              }
              // this.$router.push('/login');
            });
          // .finally(() => {
          //   this.disabled = false;

          //   this.$router.push('/');

          //   this.loading = false;
          // });
        }
      } else {
        this.$notify({
          message: "Os campos são obrigatórios!",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
    },

    detalhes() {
      this.$store.state.session.auth;
      if (this.$store.state.session.auth == true) {
        this.$router.push("/");
      } else {
        this.$router.push("/login");
      }
    }

    // buscaViagens() {
    //   this.axios
    //     .get(
    //       `${api}/viagens/busca/?cidade_origem=${this.origem}&cidade_destino=${this.destino}`
    //     )
    //     .then((response) => {
    //       response.data.map((res) => {
    //         this.items.push(res);
    //       });
    //       //  this.items.push(response.data)
    //     });
    // },
  },
  teste() {
    if (this.email && this.senha) {
      this.disabled = true;

      this.loading = true;

      const payload = {
        usuario: this.email,
        senha: this.senha
      };

      let config = {
        headers: { token: this.$store.state.session.token }
      };
      //   fetch(`${baseURL}/usuarios/login`, { method: 'POST', body: body, headers: myHeaders, signal: signal })

      this.axios
        .post(`${baseURL}/usuario/login`, payload, config)
        .then(response => {
          if (response.data.auth) {
            this.$store.state.session.auth = response.data.auth;
            this.$store.state.session.token = response.data.token;

            var payloadInside = {
              id: response.data.clinicData.ID,
              usuario: response.data.clinicData.USERNAME,
              token: response.data.token
            };

            this.$store.dispatch("login", payloadInside);
            this.$notify({
              message: "Usuário logado!",
              type: "success",
              timeout: 2000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });

            let config = {
              headers: { token: response.data.token }
            };
          } else {
            this.$notify({
              message: "Usuário logado!",
              type: "success",
              timeout: 2000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
          }
        })
        .catch(error => {
          this.$notify({
            message: error.response.data.message,
            type: "danger",
            timeout: 2000,
            horizontalAlign: "center",
            verticalAlign: "top",
            showClose: false,
            closeOnClick: false
          });
        })
        .finally(() => {
          this.disabled = false;

          this.$router.push("/guiaexterna");

          this.loading = false;
        });
    } else {
      this.$notify({
        message: "Os campos são obrigatórios!",
        type: "danger",
        timeout: 2000,
        horizontalAlign: "center",
        verticalAlign: "top",
        showClose: false,
        closeOnClick: false
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

.captcha {
  margin: 0 auto;
  margin-top: 1rem;
  transform: scale(0.8);
  transform-origin: 1 1;
  align-self: center;
  display: inline-block;
}
.logo-navegam {
  display: block;
  // padding-bottom: 2px;
  width: 160px;
  margin-left: 0;
  margin-right: 0;
  margin: auto;
  margin-top: 25px;
}

.icons {
  right: 10%;
  position: absolute;
  bottom: 10px;
}

.flex-buttons {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
}

.entire-div {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 93vh;
  padding-bottom: 7vh; /* Footer height */
  display: block;
  justify-content: center;
  background-color: #2a3550;
  z-index: 0;

  .central-div {
    background-color: #2a3550;
    width: 100%;
    padding-top: 12vh;

    .header {
      // text-transform: inherit;
      // font-weight: 300;
      margin-bottom: 1.5rem;
      // margin-top: 0.2rem;
      font-size: 30px;
      font-family: "OpenSans-Bold", sans-serif;
      text-align: center;
      color: white;
      // padding-bottom: 1rem;
      // border-bottom: 2px dashed #cccccc;
      @media only screen and (max-width: 900px) {
        font-size: 1.5em;
      }
    }
    .iconVisible {
      /* position: absolute; */
      font-size: 24px;
      /* bottom: 0.7rem; */
      /* right: 1.5rem; */
      color: #747474;
      right: 10%;
      position: absolute;
      bottom: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .login-card {
      background-color: white;
      width: fit-content;
      margin: auto;
      margin-top: 0.3rem;
      padding: 1rem;
      border-radius: 7px;
      margin-bottom: 1rem;

      // @media only screen and (max-width: 1100px) {
      //   width: 30%;
      // }

      // @media only screen and (max-width: 900px) {
      //   margin-top: 5rem;
      // }

      // @media only screen and (max-width: 745px) {
      // }

      .my-form {
        display: block;

        .my-input-div {
          position: relative;
          width: 100%;
          margin: 2rem auto;
          // border: 1px solid rgba(29, 37, 59, 0.5);
          margin-bottom: 0.6rem;
          // border-radius: 4px;
        }
        ::placeholder {
          color: rgba(37, 35, 35, 0.472);
        }
        .my-input {
          caret-color: #1d253b;
          background-color: rgba(126, 125, 125, 0.178);
          display: block;
          position: relative;
          width: 90%;
          height: 50%;
          // margin: 0.6rem 0.9rem;
          margin-top: -0.2rem;
          padding: 12px 0px 12px 18px;
          border: none;
          // font-weight: 300;
          font-family: "OpenSans-Regular", sans-serif;
          border-radius: 4px;

          animation: blink-caret 0.75s step-end infinite;
        }
        .my-input:focus {
          outline: none;
          border: none;
        }

        // .my-input-div::after {
        //   position: absolute;
        //   left: 0;
        //   bottom: 10%;
        //   content: '';
        //   width: 100%;
        // }

        // .password-input::before {
        //   content: ' Senha ';
        //   position: relative;
        //   display: inline-block;
        //   top: -7px;
        //   left: 3%;
        //   height: 0%;
        //   padding: 0 3px;
        //   color: #1d253b;
        //   background-color: white;
        //   font-family: 'OpenSans-Regular', sans-serif;
        //   font-weight: 200;
        //   font-size: 13px;
        // }

        // .email-input {
        //   // content: ' Email ';
        //   position: relative;
        //   display: inline-block;
        //   border: none;
        //   top: -7px;
        //   // left: 3%;
        //   height: 0%;
        //   // padding: 0 3px;
        //   color: #1d253b;
        //   // background-color: white;
        //   // font-family: 'OpenSans-Regular', sans-serif;
        //   font-weight: 200;
        //   font-size: 13px;
        // }

        .opcoes {
          text-align: center;
          // font-family: 'OpenSans-Regular', sans-serif;
          font-size: 80%;
          font-weight: 300;
          margin: 1rem 0;
          // letter-spacing: 0rem;
        }
        .button-cadastrar {
          background-color: transparent;
          border-color: #2a3550;
          border: 1px solid #2a3550;
          color: #2a3550;
          margin-left: 8px;
          border-radius: 4px;
          padding: 0.6rem 0.3rem;
          // width: 25%;
          width: 6rem;
          font-family: "OpenSans-Regular", sans-serif;
          cursor: pointer;
        }
        .button-login {
          background-color: #009aa0;
          // margin: 10px auto;
          cursor: pointer;
          width: 6rem;
          margin-right: 8px;
          // border: none;
          padding: 0.6rem 0.3rem;
          border: 1px solid #009aa0;
          font-family: "OpenSans-Regular", sans-serif;
          text-align: center;
          // float: r;
          border-radius: 4px;
          color: white;
          vertical-align: middle;
        }
        .button-icon {
          vertical-align: middle;
          position: relative;
          height: 1.2rem;
          filter: invert(100%) sepia(12%) saturate(7471%) hue-rotate(183deg)
            brightness(118%) contrast(100%);
        }
        .button-login:hover {
          transform: translate(0px, -1px);
          cursor: pointer;
          box-shadow: 0px 1px 10px 0px #00000011;
          transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
        }
        .button-cadastrar:hover {
          transform: translate(0px, -1px);
          cursor: pointer;
          box-shadow: 0px 1px 10px 0px #00000011;
          transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
        }

        .button-login:focus {
          outline: none;
        }
        .button-cadastrar:focus {
          outline: none;
        }
        .button-cadastrar:active {
          outline: none;
          transform: translate(0px, 1px);
          transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
        }
        .button-login:active {
          outline: none;
          transform: translate(0px, 1px);
          transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
        }

        .myDivisor {
          margin: 0.3rem auto;
          border: none;
          border-bottom: 1px solid #cccccc;
        }

        .myCaptcha {
          margin: 0 auto;
          display: flex;
          text-align: center;
          justify-content: center;
          width: 50%;

          .captcha {
            @media only screen and (max-width: 600px) {
              transform: scale(0.2);
              transform-origin: 50% 50%;
              -webkit-transform: scale(0.77);

              padding: 0;
            }
          }
        }
      }
    }
  }
}

.signup {
  width: 50%;
  border-radius: 1rem;
  background-color: #39b935;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  padding: 0.3rem 0.6rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  margin-left: 5px;
  cursor: pointer;
  color: white;
  vertical-align: middle;
}

.esqueci {
  background-color: transparent;
  margin: 0.6rem auto;
  display: flex;
  align-self: center;
  font-family: "OpenSans-Regular", sans-serif;
  // line-height: 2.5;
  cursor: pointer;
  // padding: -0.7rem 0.6rem;
  border: 0;
  // border-radius: 0.5rem;
  // font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: #009aa0;
  text-decoration: underline;
  vertical-align: middle;
  outline: none;
}
.remover-icon {
  display: none;
  @media only screen and (max-width: 500px) {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    padding: 0 0.25rem;
    filter: invert(100%) sepia(1%) saturate(7472%) hue-rotate(145deg)
      brightness(100%) contrast(108%);
  }
}

@media only screen and (max-width: 380px) {
  .opcoes {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .label-cadastro {
    margin-bottom: 5px;
  }
  .signup {
    margin: 0 auto;
    width: 70%;
  }
}
/* The typewriter cursor effect */
// @keyframes blink-caret {
//   from,
//   to {
//     border-color: transparent;
//   }
//   50% {
//     border-color: orange;
//   }
// }
</style>
