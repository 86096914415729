<template>
  <div class="entire-div">
    <h3 class="component-title">Perfil à adicionar</h3>
    <div class="component-body">
      <div class="logo-user">
        <img class="logo" :src="IconUser" />
      </div>

      <div class="form-pessoal">
        <div class="group group-basico">
          <div class="form-username">
            <span class="label">Nome:</span>
            <input
              v-model="model.username"
              type="name"
              required
              placeholder="Nome"
            />
          </div>
          <div class="form-sexo">
            <span class="label">Sexo:</span>
            <select class="form-sexo" v-model="model.sexo">
              <option value>Selecione</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
              <option value="outros">Outros</option>
            </select>
          </div>
        </div>
        <div class="group group-contato">
          <div class="form-telefone">
            <span class="label">Telefone:</span>
            <input
              v-model="model.telefone"
              required
              type="phone"
              placeholder="(12) 34567-8900"
              v-mask="'(##) #####-####'"
            />
          </div>
          <div class="form-nacionalidade">
            <span class="label">Nacionalidade:</span>
            <input
              class="form-control"
              label="Nacionalidade"
              v-model="model.nacionalidade"
              required
              placeholder="Brasileiro"
            />
          </div>
        </div>

        <div class="group group-dados">
          <div class="form-rg">
            <span class="label">Rg:</span>
            <input
              id="input-5"
              v-model="model.rg"
              required
              placeholder="1234567-8"
              v-mask="'#######-#'"
            />
          </div>
          <div class="form-orgao">
            <span class="label">Emissor:</span>
            <input placeholder="SSP" v-model="model.orgao_emissor" />
          </div>
          <div class="form-nascimento">
            <span class="label">Nascimento:</span>
            <input
              class="form-control"
              label="Data de Nascimento"
              v-mask="'##/##/####'"
              v-model="model.data_nascimento"
              required
              placeholder="dd/mm/aaaa"
            />
          </div>
          <div class="form-cpf">
            <span class="label">CPF:</span>
            <input
              class="form-control"
              label="CPF"
              v-model="model.cpf"
              required
              v-mask="'###.###.###-##'"
              placeholder="CPF"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="div-button">
      <button class="button-adicionar" @click="adicionar" type="info" id="flag">
        Comprar passagem para {{ model.username.split(" ")[0] }}
      </button>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import api from "../../api";

import IconUser from "@/assets/icons/user.svg";

export default {
  components: {
    Multiselect
  },
  props: ["dados", "agencias"],
  data() {
    return {
      //Icons
      IconUser,

      valorTotalAux: 0,
      valorTotal: 0,
      flag: false,
      selected: null,
      value: "",
      nome: "gabriel",
      userArray: [],
      auxString: "",
      passageiros: [],
      // arrayAgencia: this.agencias,
      passengerInstance: null,
      options: [],
      descontos: [],
      dadosViagem: this.dados,
      model: {
        username: "",
        telefone: "",
        rg: "",
        sexo: "",
        nacionalidade: "",
        orgao_emissor: "",
        data_nascimento: "",
        cpf: ""
      }
    };
  },
  mounted() {
    var vm = this;

    if (this.$store.state.session.auth == false) this.$router.push("/search");
    //     fetch(`${baseURL}/embarcacoes/${infoPassenger.embarcacao_id}/descontos`,
    const config2 = {
      headers: {
        Authorization: `Bearer ${this.$store.state.session.token}`
      }
    };

    this.axios
      .get(`${api}/embarcacoes/${this.dados.embarcacao_id}`, config2)
      .then(response => {
        this.descontos.push(response.data);
      })
      .catch(err => {
        if (err.response.data.message == "Auth failed")
          this.$store.dispatch("logout");
      });
  },
  methods: {
    retornaGenero(letra) {
      return (
        {
          M: "masculino",
          F: "feminino",
          O: "outros"
        }[letra.toLocaleUpperCase()] || "Escolha"
      );
    },

    formataData(data) {
      if (data) {
        const dia = data.substring(0, 2);
        const mes = data.substring(3, 5);
        const ano = data.substring(6, 10);

        data = `${ano}-${mes}-${dia}`;
      }
      return data;
    },

    obtemCPF(cpf) {
      if (cpf) {
        cpf = cpf
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");
      }

      return cpf;
    },

    formatarTelefone(tel) {
      if (tel)
        tel = tel
          .replace("-", "")
          .replace(" ", "")
          .replace("(", "")
          .replace(")", "");

      return tel;
    },

    remove() {
      const valorTransporte = this.agencias.valor_transporte - 0;
      const valorAlimentacao = this.agencias.valor_alimentacao - 0;
      const valorAcomodacao = this.agencias.valor_acomodacao - 0;
      this.valorTotalAux = valorTransporte + valorAlimentacao + valorAcomodacao;
      this.$store.dispatch("pop_valorTotal", this.valorTotalAux);
      this.$store.dispatch("pop_passenger");
    },

    calculateAgeInDays(date) {
      const now = new Date();
      const birth = new Date(date);
      const days = (now.getTime() - birth.getTime()) / (1000 * 3600 * 24);
      return days;
    },

    calculateDiscounts(days) {
      for (var i = 0; i < this.descontos.length; i++) {
        const discount = this.descontos[i];
        if (days >= discount.dias_minimo && days <= discount.dias_maximo) {
          return discount;
        }
      }
      return null;
    },
    adicionar() {
      var passengerInstance = {
        nome: this.model.username,
        sexo: this.model.sexo,
        nacionalidade: this.model.nacionalidade,
        dt_nascimento: this.formataData(this.model.data_nascimento),
        telefone: this.formatarTelefone(this.model.telefone),
        rg: this.model.rg,
        cpf: this.obtemCPF(this.model.cpf),
        orgao_emissor: this.model.orgao_emissor
      };

      let valido = true;
      let erroMessage = "Problemas ao salvar, tente novamente";
      for (var key in passengerInstance) {
        if (passengerInstance[key] == null || passengerInstance[key] == "") {
          valido = false;
          erroMessage = "Preencha todos os campos!";
        }

        //  let passageiros = this.$store.state.passageiros;
        //for (const key2 in passageiros) {
        //if (passengerInstance['email'] == passageiros[key2]['email']) {
        //valido = false;
        //erroMessage = 'Email já informado, tente outro';
        // }
        //}
      }

      if (valido) {
        const desc = this.calculateDiscounts(
          this.calculateAgeInDays(this.model.data_nascimento)
        );

        if (desc != null) {
          const valorTransporte =
            this.agencias.valor_transporte -
            this.agencias.valor_transporte * desc.percentual_transporte;
          const valorAlimentacao =
            this.agencias.valor_alimentacao -
            this.agencias.valor_alimentacao * desc.percentual_alimentacao;
          const valorAcomodacao =
            this.agencias.valor_acomodacao -
            this.agencias.valor_acomodacao * desc.percentual_acomodacao;
          this.valorTotal +=
            valorTransporte + valorAlimentacao + valorAcomodacao;
        } else {
          const valorTransporte = this.agencias.valor_transporte - 0;
          const valorAlimentacao = this.agencias.valor_alimentacao - 0;
          const valorAcomodacao = this.agencias.valor_acomodacao - 0;
          this.valorTotal +=
            valorTransporte + valorAlimentacao + valorAcomodacao;
        }

        passengerInstance["valor_total"] = this.valorTotal;

        var payloadInside = {
          valor: this.valorTotal
        };

        var flagUser = 0;
        if (this.$store.state.passageiros.length != null) {
          for (let i = 0; i < this.$store.state.passageiros.length; i++) {
            if (
              this.obtemCPF(this.model.cpf) ==
                this.$store.state.passageiros[i].cpf ||
              this.model.rg == this.$store.state.passageiros[i].rg
            ) {
              flagUser = 1;
              this.$notify({
                message: "Passageiro já foi incluso!",
                type: "danger",
                timeout: 2000,
                horizontalAlign: "center",
                verticalAlign: "top",
                showClose: false,
                closeOnClick: false
              });
            }
          }
          if (flagUser == 0) {
            this.$notify({
              message: "Passageiro adicionado!",
              type: "success",
              timeout: 4000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
            this.$store.dispatch("updatedValorTotal", payloadInside);
            this.$store.dispatch("add_passenger", passengerInstance);
          }
        } else {
          this.$store.dispatch("updatedValorTotal", payloadInside);
          this.$store.dispatch("add_passenger", passengerInstance);
        }

        this.valorTotal = 0;
      } else {
        this.$notify({
          message: erroMessage,
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.entire-div {
  background-color: white;
  width: 40%;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem auto;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }

  .component-title {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: gray;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 2px dashed #cccccc;
    padding-bottom: 0.5rem;
    margin-bottom: 0.72rem;
  }

  .component-body {
    border: 8px solid #cccccc;
    padding: 1%;

    .logo-user {
      display: inline-block;
      height: 100%;
      width: 40%;
      border-right: 2px dashed #eeeeee;
      margin-right: 1%;
      // @media only screen and (max-width: 580px) {
      display: none;
      // }

      .logo {
        display: inline-block;
        position: relative;
        height: 90%;
        width: 90%;
        opacity: 0.15;
        padding: 5%;
        max-width: 20rem;
      }
      vertical-align: middle;
    }

    .form-pessoal {
      display: inline-block;
      width: 55%;
      margin: auto;
      vertical-align: middle;
      // @media only screen and (max-width: 580px) {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      // }
    }
  }
}

.group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  vertical-align: middle;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
  margin-bottom: 1rem;
  padding: 0.25rem;
  // @media only screen and (max-width: 580px) {
  padding: 0.25rem 0;
  // }

  div {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    width: 50%;

    input,
    select {
      width: 80%;
      background-color: white;
    }
  }

  .label {
    display: block;
    margin-bottom: 0.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 200;
    color: #344675;
  }
}

.group-dados {
  margin-bottom: 0;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

.div-button {
  display: flex;
  width: 100%;
  margin: 0.25rem auto;
  .button-adicionar {
    position: relative;
    color: white;
    background-color: #3fb935;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 0.4rem 2rem;
    border: 0;
    border-radius: 0.5rem;
    font-size: 100%;
    text-align: center;
    margin: 0.3rem auto;
    margin-bottom: 0;
    cursor: pointer;
  }
}

input,
select {
  border-radius: 0.25rem;
  border: 2px dashed gray;
  padding: 0.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 200;
}

select:focus,
select:active,
input:focus,
input:active {
  outline: none;
  border-color: #344675;
}
</style>
