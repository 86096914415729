<template>
  <div class="entire2-div">
    <h1 class="header">Termos de Uso da Plataforma Navegam</h1>
    <div class="central2-div">
      <p class="text2">
        Esta aplicação e seu conteúdo ("https://www.navegam.com.br/") são
        controlados e operados pela própria empresa como todos os direitos
        reservados.
      </p>
      <p class="text2">
        Estes termos de uso têm por objetivo definir as regras a serem seguidas
        para a utilização da Plataforma NAVEGAM ("Termos de Uso"), sem prejuízo
        da aplicação da legislação vigente.
      </p>
      <p class="text2">
        <span class="highlight-text">
          AO UTILIZAR A NAVEGAM, VOCÊ AUTOMATICAMENTE CONCORDA COM ESTES TERMOS
          DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS
          PRATICADOS POR VOCÊ NA NAVEGAM OU EM SERVIÇOS A ELA RELACIONADOS. CASO
          VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO
          ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR A NAVEGAM. VOCÊ TAMBÉM CONCORDA
          COM OS TERMOS DESCRITOS EM NOSSA POLÍTICA DE PRIVACIDADE. PARA
          ACESSÁ-LA, CLIQUE AQUI:
          <a class="anchor" @click="goTo('politicaprivacidade')"
            >https://navegam.com.br/#/politicaprivacidade</a
          >.
        </span>
      </p>
      <p class="text2">
        Caso queira nos dar algum feedback sobre a NAVEGAM, tenha dúvidas ou
        precise tratar de qualquer assunto relacionado a estes Termos de Uso,
        entre em contato conosco através do e-mail:
        <a class="anchor" href="mailto:passagens@navegam.com.br"
          >passagens@navegam.com.br</a
        >.
      </p>
      <p class="text2">
        Com efeito, preocupada com a segurança e privacidade dos dados que
        trafegam no Website, a PLATAFORMA NAVEGAM declara que a senha de acesso
        e todos os dados que o COMPRADOR digitar na página do Website são
        protegidos. A PLATAFORMA NAVEGAM recomenda que o COMPRADOR nunca forneça
        sua senha a terceiros. O COMPRADOR é integralmente responsável pelo
        sigilo e utilização de sua senha e identidade.
      </p>
      <h3 class="subtitle">1. O que é a Navegam?</h3>
      <p class="text2">
        <strong>Serviços.</strong> A NAVEGAM é uma plataforma que oferece os
        seguintes serviços: E-Commerce - Intermediação na venda de passagens
        online; FEPS - Sistema de venda de passagens nas embarcações; Navegam
        LOG - Sistema de logística; Sentinela - Sistema de monitoramento de
        embarcação.
      </p>
      <p class="text2">
        <strong>Ressalvas.</strong> Ao utilizar a NAVEGAM, você reconhece que: A
        NAVEGAM atua apenas como intermediadora na venda de passagens de barcos.
        A NAVEGAM não é uma agência de viagens, portanto, não é responsável por
        qualquer falha na prestação de serviços prestados pelos Fornecedores, ou
        quaisquer danos causados por culpa exclusiva dos Fornecedores. Quando
        Você contrata Serviços através da NAVEGAM, está celebrando um contrato
        diretamente com a EMBARCAÇÃO que oferece os seus Serviços através da
        nossa Plataforma.
      </p>
      <p class="text2">
        <strong>Suspensão. </strong> Nós nos reservamos o direito de suspender
        ou cancelar, a qualquer momento, o seu acesso à aplicação em caso de
        suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita,
        ou pelo não cumprimento de quaisquer condições previstas nestes Termos
        de Uso, na Política de Privacidade ou na legislação aplicável. Nesses
        casos, não será devida qualquer indenização a você, e a NAVEGAM poderá
        promover a competente ação de regresso, se necessário, bem como tomar
        quaisquer outras medidas necessárias para perseguir e resguardar seus
        interesses.
      </p>
      <p class="subtitle">2. Como acesso a Navegam?</p>
      <p class="text2">
        <strong>Acesso.</strong> Para acessar o E-COMMERCE NAVEGAM é necessário
        efetuar um cadastro. Para cadastrar-se, você nos fornecerá informações
        pessoais, conforme descrito em nossa Política de Privacidade. Para saber
        mais sobre a privacidade de suas informações pessoais na NAVEGAM, acesse
        nossa Política de Privacidade.
      </p>
      <p class="text2">
        <strong>Utilização.</strong> Para utilizar a NAVEGAM, você: (i) deve ter
        pelo menos 18 (dezoito) anos; (ii) ser civilmente capaz; (iii) aceitar
        expressamente os Termos e Condições; (iv) só utilizar a Plataforma da
        NAVEGAM para reservar ou contratar Serviços Turísticos para Você e/ou
        para outra pessoa em nome da qual Você tenha autorização para atuar;(v)
        toda informação que Você fornece à NAVEGAM é verídica, exata, atual e
        completa.
      </p>
      <p class="text2">
        <strong>Titularidade.</strong> A partir do cadastro, você será titular
        de uma conta que somente poderá ser acessada por você. Caso a NAVEGAM
        detecte alguma conta feita a partir de informações falsas, por usuários
        que, por exemplo, não possuem a idade mínima permitida, essa conta será
        automaticamente deletada;
      </p>
      <p class="text2">
        <strong>Atualização das Informações.</strong> Desde já, você se
        compromete a manter as suas informações pessoais atualizadas. Você
        também concorda que irá manter o seu login e senha seguros e fora do
        alcance de terceiros, e não permitirá que a sua conta na NAVEGAM seja
        usada por outras pessoas. Dessa forma, o usuário responsabiliza-se por
        todas as ações realizadas em sua conta.
      </p>
      <p class="text2">
        <strong>Conexão via Terceiros.</strong> Alternativamente, a NAVEGAM
        poderá te oferecer a possibilidade de realizar seu cadastro por meio de
        sua conta de serviços de terceiros como FACEBOOK/GOOGLE, mas não apenas
        estes. Nessa hipótese, você autoriza a NAVEGAM a acessar, armazenar e
        utilizar as informações fornecidas por terceiros a fim de criar a sua
        conta na NAVEGAM. a NAVEGAM reafirma que NÃO tem qualquer ligação com
        esses terceiros, não possuindo qualquer responsabilidade, tampouco
        garantindo, em qualquer hipótese, os produtos ou serviços fornecidos por
        eles.
      </p>
      <h3 class="subtitle">
        3. Como são feitos os pagamentos na plataforma Navegam?
      </h3>
      <p class="text2">
        <strong>Meio de Pagamento.</strong> A solicitação de Compra por meio do
        E-commerce NAVEGAM, autoriza o gerenciamento de compra e cobrança do(s)
        respectivo(s) encargo(s) na sua conta ou no seu cartão. Sendo
        encaminhadas via e-mail as informações sobre as etapas e situação do seu
        Pedido. A conclusão da confirmação da compra depende do pagamento
        integral e oportuno do preço e/ou tarifa de todos os Serviços, passível
        de suspensão ou cancelamento pelo Fornecedor, nos casos de ausência de
        validação. Após a identificação do pagamento integral, ocorrerá envio de
        Voucher, mediante e-mail que for aplicável, e sua fatura. Além disso,
        nas operações de crédito, deverão ser preenchidos os requisitos próprios
        fixados pelo seu banco emissor.
      </p>
      <p class="text2">
        <strong>Taxa de conveniência.</strong> A referida taxa é inerente aos
        custos de intermediação, portanto, sendo cobrada pela E-COMMERCE
        NAVEGAM, no momento da compra da passagem, no valor equivalente a 2%
        (dois por cento) da passagem comprada. Por se tratar de repasse de
        custos, não será devolvida ao COMPRADOR em caso de cancelamento,
        devolução, remarcação ou arrependimento.
      </p>
      <p class="text2">
        <strong>Preço Final.</strong> O preço pago ao fim da contratação inclui
        valor da passagem, taxa fixada e impostos (se houver incidência), sendo
        previamente informado no processo de compra. Sendo vedada a alteração do
        valor da sua subscrição sem aviso com antecedência ao consumidor.
        Lembre-se de que o E-commerce NAVEGAM atua como mero intermediador e que
        os valores correspondentes ao preço ou tarifa dos Serviços são
        transferidos (endossados) ao Fornecedor.
      </p>
      <p class="text2">
        <strong>Confirmação.</strong> Ressalte-se que a confirmação do pagamento
        por meio da aplicação ocorrerá em até 3 (três) dias úteis. O
        processamento das informações de pagamento e a confirmação do pagamento
        serão realizados por sistemas de terceiros (ex. instituição financeira
        ou administradora do cartão de crédito), sendo o aplicativo uma mera
        interface entre o cliente e esses sistemas.
      </p>
      <p class="text2">
        <strong>Código Promocional.</strong> Caso a NAVEGAM crie algum código
        promocional (por exemplo, cupom de desconto), este deve ser usado de
        forma legal para a finalidade e o público ou usuário específico a que se
        destina, seguindo todas suas condições. O código promocional pode ser
        cancelado caso se verifique que foi transferido, vendido ou utilizado
        com erro, fraude, ilegalidade ou violação às condições do respectivo
        código.
      </p>
      <p class="text2">
        <strong>Direito de Arrependimento.</strong> Fica assegurado ao
        Comprador, o direito de desistência em até 24 horas contadas do
        recebimento do seu comprovante de compra, com reembolso integral no
        prazo de 7 dias. Essa regra se aplica às compras realizadas com
        antecedência mínima de 7 dias contados da data de embarque, segundo os
        critérios da Agência Nacional de Transporte Aquaviário- ANTAC.
        <br />
        §1º. O pedido de desistência somente será processado após manifestação
        expressa ao e-mail da NAVEGAM, com assunto denominado Termo de
        Cancelamento, com indicação do Voucher específico.
      </p>
      <p class="text2">
        <strong>Cancelamento, Remarcação e Devolução.</strong> Para os casos de
        expressa manifestação de vontade em cancelar ou remarcar Voucher, a
        NAVEGAM realizará as gestões de apresentação e acompanhamento da sua
        solicitação, mas, tanto os prazos de resposta como a decisão final,
        serão do Fornecedor. A maneira de proceder e as condições especiais
        vigentes para esses casos de trocas ou cancelamentos em uma compra
        confirmada por Você são determinadas pelos Fornecedores dos Serviços,
        que poderão estabelecer multas ou penalidades aplicáveis quanto à
        devolução parcial ou total para as alterações solicitadas.
      </p>
      <h3 class="subtitle">
        4. Quais são os direitos da Navegam sobre a plataforma?
      </h3>
      <p class="text2">
        <strong>Nossos Direitos.</strong> Todos os direitos relativos à NAVEGAM
        e suas funcionalidades são de propriedade exclusiva da NAVEGAM SOLUÇÕES
        EM MOBILIDADE, inclusive no que diz respeito aos seus textos, imagens,
        layouts, software, códigos, bases de dados, gráficos, artigos,
        fotografias e demais conteúdos produzidos direta ou indiretamente pela
        NAVEGAM SOLUÇÕES EM MOBILIDADE. O Conteúdo da NAVEGAM é protegido pela
        lei de direitos autorais e de propriedade intelectual. É proibido usar,
        copiar, reproduzir, modificar, traduzir, publicar, transmitir,
        distribuir, executar, fazer o upload, exibir, licenciar, vender ou
        explorar e fazer engenharia reversa do Conteúdo do E-commerce NAVEGAM,
        para qualquer finalidade, sem o consentimento prévio e expresso da
        NAVEGAM SOLUÇÕES EM MOBILIDADE. Qualquer uso não autorizado do Conteúdo
        será considerado como violação dos direitos autorais e de propriedade
        intelectual da NAVEGAM.
      </p>
      <p class="text2">
        <strong>Propriedade Intelectual.</strong> Para nós da NAVEGAM, a
        qualidade dos materiais disponibilizados ao usuário é de suma
        importância. A criação deles é fruto de muito trabalho e dedicação de
        nossos desenvolvedores. Por isso, reafirmamos que a NAVEGAM garante que
        todos os direitos, título e interesse (incluindo, mas não apenas, os
        direitos autorais, marcários e outros de propriedade intelectual) sobre
        o serviço disponibilizado por nós permanecerão sob a titularidade da
        NAVEGAM SOLUÇÕES EM MOBILIDADE
      </p>
      <p class="subtitle">
        5. Responsabilidades do usuário e do E-COMMERCE NAVEGAM
      </p>
      <p class="text2">
        <strong>Responsabilidade pelo Uso.</strong> Você é exclusivamente
        responsável pelo uso do E-commerce NAVEGAM e deverá respeitar as regras
        destes Termos de Uso, bem como a legislação aplicável ao Código do
        Consumidor e resoluções da Agência Nacional de Transportes Aquaviários
        (ANTAC).
      </p>
      <p class="text2">
        <strong>Responsabilização por Eventuais Danos.</strong> A Plataforma
        NAVEGAM, seu controlador, suas afiliadas, parceiras ou funcionários não
        serão, em hipótese alguma, responsabilizados por danos diretos ou
        indiretos que resultem de, ou que tenham relação com o acesso, uso ou a
        incapacidade de acessar ou utilizar o sistema.
      </p>
      <p class="text2">
        <strong>Não-Responsabilização.</strong>
        <span class="highlight-text">
          TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA INTERNET, A
          NAVEGAM NÃO SE RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE
          CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM
          COMO POR FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE
          LIMITANDO, AO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE
          OU SOFTWARE. A INDISPONIBILIDADE DE ACESSO À INTERNET. ASSIM COMO
          QUALQUER INFORMAÇÃO INCORRETA OU INCOMPLETA E QUALQUER FALHA HUMANA,
          TÉCNICA OU DE QUALQUER OUTRO TIPO NO PROCESSAMENTO DAS INFORMAÇÕES NÃO
          SERÃO CONSIDERADAS RESPONSABILIDADE DA NAVEGAM.</span
        >
      </p>
      <h3 class="subtitle">
        6. A NAVEGAM se exime de qualquer responsabilidade proveniente de tais
        fatos e/ou atos.
      </h3>
      <p class="text2">
        <strong>Perda de Lucros.</strong> Quando permitido por lei, a NAVEGAM e
        os fornecedores ou distribuidores não serão responsáveis por perda de
        lucros, perda de receita, perda de dados, perdas financeiras ou por
        danos indiretos, especiais, consequenciais, exemplares ou punitivos.
      </p>
      <p class="text2">
        <strong>Manutenção.</strong> É de sua inteira responsabilidade manter o
        ambiente de seu dispositivo (computador, celular, tablet, entre outros)
        seguro, com o uso de ferramentas disponíveis, como antivírus, firewall,
        entre outras, de modo a contribuir na prevenção de riscos eletrônicos.
      </p>
      <p class="text2">
        <strong>Links Externos.</strong> É possível que a PLATAFORMA NAVEGAM
        possa conter links para sites e aplicativos de terceiros, assim como ter
        tecnologias integradas. Isso não implica, de maneira alguma, que a
        NAVEGAM endossa, verifica, garante ou possui qualquer ligação com os
        proprietários desses sites ou aplicativos, não sendo responsável pelo
        seu conteúdo, precisão, políticas, práticas ou opiniões. A NAVEGAM
        recomenda que você leia os termos de uso e políticas de privacidade de
        cada site de terceiros ou serviço que o usuário vier a visitar ou
        utilizar.
      </p>
      <p class="text2">
        <strong>Exclusão de Responsabilidade.</strong> Quando o E-commerce
        NAVEGAM facilita a interação de você com prestadores terceiros
        independentes, isso não significa que a NAVEGAM endossa, verifica,
        garante, possui qualquer ligação ou recomenda esses terceiros. NESSAS
        SITUAÇÕES, A NAVEGAM ATUA COMO MERA FACILITADORA DA TRANSAÇÃO ENTRE VOCÊ
        E TAIS TERCEIROS, DE MODO QUE O E-COMMERCE NAVEGAM NÃO É FORNECEDORA DE
        BENS OU SERVIÇOS, OS QUAIS SÃO PRESTADOS DIRETAMENTE POR TERCEIROS
        PRESTADORES INDEPENDENTES. A NAVEGAM NÃO SERÁ, EM HIPÓTESE ALGUMA,
        RESPONSÁVEL POR TAIS PRODUTOS OU SERVIÇOS DE TERCEIROS FORNECEDORES E
        REITERAMOS A NECESSIDADE DO USUÁRIO LER, ANALISAR E ACEITAR OS TERMOS DE
        USO DAS PLATAFORMAS QUE POSSAM TER ALGUMA INFERFACE CONOSCO.
      </p>
      <h3 class="subtitle">
        7. O que mais eu preciso saber sobre estes termos de uso?
      </h3>
      <p class="text2">
        <strong>Alterações.</strong> Para melhorar sua experiência, a NAVEGAM
        está sempre sendo atualizada. Por esse motivo, estes Termos de Uso podem
        ser alterados, a qualquer tempo, a fim de refletir os ajustes
        realizados. No entanto, sempre que ocorrer qualquer modificação, você
        será previamente informando pelo endereço de e-mail fornecido por você
        no momento do cadastro ou por um aviso em destaque na aplicação. Caso
        você não concorde com os novos Termos de Uso, você poderá rejeitá-los,
        mas infelizmente, isso significa que você não poderá mais ter acesso e
        fazer uso da PLATAFORMA NAVEGAM. Se de qualquer maneira você utilizar a
        NAVEGAM mesmo após a alteração destes Termos de Uso, isso significa que
        você concorda com todas as modificações.
      </p>
      <p class="text2">
        <strong>Conflito entre Disposições.</strong> Em caso de conflito entre
        estes termos e os termos modificador, os termos posteriores prevalecerão
        com relação a esse conflito.
      </p>
      <p class="text2">
        <strong>Lei e Foro.</strong> Estes Termos de Uso são regidos pelas leis
        da República Federativa do Brasil. Quaisquer dúvidas e situações não
        previstas nestes Termos de Uso serão primeiramente resolvidas pela
        Câmara Arbitral de Manaus e, caso persistam, deverão ser solucionadas
        pelo Foro da Comarca de Manaus, Estado do Amazonas, com exclusão de
        qualquer outro, por mais privilegiado que seja ou venha a ser.
      </p>
      <p class="text2">
        <strong>Dúvidas.</strong> Caso você tenha alguma dúvida, comentário ou
        sugestão, por favor, entre em contato conosco por meio do e-mail
        <a class="anchor" href="mailto:passagens@navegam.com.br"
          >passagens@navegam.com.br</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push("/");
    },
    goTo(rota) {
      let routeData = this.$router.resolve({ name: rota });
      window.open(routeData.href, "_top");
    }
  }
};
</script>

<style scoped lang="scss">
/* @font-face {
  
} */

template {
  font-family: "Roboto", sans-serif;
}

.entire2-div {
  height: 100%;
  min-height: 78vh;
  padding-bottom: 10vh;
}
.central2-div {
  margin: 0 auto;
  /* margin-left: 2rem; */
  margin-top: 3vh;
  width: 75%;
  background-color: transparent;
  padding: 1rem;
  border-radius: 6px;
}
.header {
  text-align: center;
  color: #2a3550;
  margin-top: 15vh;
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.subtitle {
  color: #2a3550;
  margin-top: 6vh;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.text2 {
  margin-top: 1rem;
  color: #626262;
  font-family: "Roboto", sans-serif;
  text-align: justify;
  line-height: 1.5rem;
  letter-spacing: 0.8px;

  .highlight-text {
    font-weight: bold;
    color: #2a3550;
  }
  strong {
    font-weight: bold;
    color: #2a3550;
  }
  .anchor {
    cursor: pointer;
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
.btn2-central2 {
  /* margin-right: 8px; */
  background-color: #009aa0;
  border: none;
  outline: none;
  padding: 8px 12px;
  color: white;
  border-radius: 4px;
  width: 150px;
  position: absolute;
  left: 42%;
  cursor: pointer;

  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:active {
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
}
</style>

