<template>
  <div class="div-margin">
    <p class="text-header">Cadastre-se ou faça seu login para continuar.</p>
    <div class="card">
      <img :src="logoNavegam" class="logo-navegam" />
      <h5 class="card-title">Cadastro</h5>
      <div class="nome">
        <input
          autocomplete="off"
          class="input-nome"
          label="Nome"
          ref="nome_input"
          v-model="model.nome"
          placeholder="Nome"
          v-on:input="onChangeNome"
        />
      </div>
      <p class="text-form" v-if="nome_flag">
        Este campo é obrigatório.
        <sup>*</sup>
      </p>
      <div class="email">
        <input
          autocomplete="off"
          class="input-email"
          label="Email"
          ref="email_input"
          v-model="model.email"
          placeholder="Email"
          v-on:input="onChangeEmail"
        />
      </div>
      <p class="text-form" v-if="email_flag">
        Este campo é obrigatório.
        <sup>*</sup>
      </p>
      <p class="text-form" v-if="email_flag2">
        Nesse formato: exemplo@gmail.com.
        <sup>*</sup>
      </p>

      <div class="senha">
        <input
          autocomplete="off"
          class="input-senha"
          label="Senha"
          ref="senha1_input"
          v-model="model.senha_1"
          :type="isVisible ? 'text' : 'password'"
          placeholder="Senha"
          v-on:input="onChangeSenha1"
        />
        <b-icon
          class="iconVisible"
          :icon="this.isVisible ? 'eye' : 'eye-slash'"
          @click="
            () => {
              this.isVisible = !this.isVisible;
            }
          "
        ></b-icon>
      </div>
      <p class="text-form" v-if="senha1_flag">
        Este campo é obrigatório.
        <sup>*</sup>
      </p>
      <p class="text-form" v-if="senha1_flag2">
        A senha deve ter no mínimo 6 dígitos.
        <sup>*</sup>
      </p>
      <div class="confirmar-senha">
        <input
          autocomplete="off"
          class="input-confirmar-senha"
          label="Confirmar senha"
          ref="senha2_input"
          v-model="model.senha_2"
          :type="isVisible2 ? 'text' : 'password'"
          placeholder="Confirme sua senha"
          v-on:input="onChangeSenha2"
        />
        <b-icon
          class="iconVisible"
          :icon="this.isVisible2 ? 'eye' : 'eye-slash'"
          @click="
            () => {
              this.isVisible2 = !this.isVisible2;
            }
          "
        ></b-icon>
      </div>
      <p class="text-form" v-if="senha2_flag">
        Este campo é obrigatório
        <sup>*</sup>
      </p>
      <p class="text-form" v-if="senha2_flag2">
        As senhas não são iguais. Tente novamente.
        <sup>*</sup>
      </p>
      <div class="opcoes">
        <button @click="Modal()" class="esqueci">Termos de uso</button>
        <!-- <button @click="rotas('signup')" class="signup" variant="success">Cadastrar</button> -->
      </div>
      <div class="termos-uso">
        <input
          type="checkbox"
          name="checkbox"
          ref="checkbox"
          @click="checkBox()"
        />
        <label>Li e concordo com os termos de uso.</label>
      </div>
      <div class="buttons">
        <button class="cancel-button" @click="goBack()">CANCELAR</button>
        <button class="button-cadastrar" type="info" @click="cadastro" fill>
          CADASTRAR
        </button>
      </div>
      <p
        @click="voltar"
        class="esqueci"
        style="
          color: #009aa0;
          margin-top: 2rem;
          font-size: 13px;
          font-family: 'Roboto', sans-serif;
          text-decoration: underline;
        "
      >
        Já tem cadastro? Faça login
      </p>
    </div>
    <!-- <img
      :src="IconWpp"
      style="
        float: right;
        width: 45px;
        cursor: pointer;
        position: absolute;
        right: 2rem;
        bottom: 6rem;
      "
      alt="Suporte Navegam"
      class="IconWpp"
      @click="openWpp()"
    /> -->
    <modal name="termosUso" @opened="afterOpen()" height="auto">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p
          style="
            font-family: 'OpenSans-Bold', sans-serif;
            text-align: center;
            font-size: 20px;
            color: #2a3550;
            margin: 0.8rem 0rem 0rem 1rem;
          "
        >
          Termos de Uso Navegam
        </p>
        <label
          @click="hiddeModal()"
          style="
            margin: 0.8rem 0.8rem 0rem 0rem;
            cursor: pointer;
            font-family: 'OpenSans-Regular', sans-serif;
          "
          >X</label
        >
      </div>
      <p
        style="
          font-family: 'OpenSans-Regular', sans-serif;
          text-align: justify;
          text-indent: 2em;
          margin: 1rem 1rem 1rem;
          color: #2a3550;
        "
      >
        O presente instrumento de Termo de Acesso e Uso - Site Navegam, tem o
        objetivo de regular as condições de acesso e uso do serviço de
        compra/consulta de passagem, oferecido por Navegam Sistemas LTDA, com
        sede na cidade de Manaus, Estado do Amazonas, na Rua do Sol, 300,
        Aleixo, inscrita no CNPJ/MF 33.011.601/0001-59 na Internet através do
        endereço
        <a href="https://www.navegam.com.br/" target="_blank"
          >https://www.navegam.com.br/</a
        >.
      </p>
    </modal>
    <p
      style="
        color: white;
        text-align: center;
        font-family: 'OpenSans-Regular', sans-serif;
      "
    >
      O site se adapta melhor ao modelo desktop.
    </p>
  </div>
</template>
<script>
import api from "../../api";
import Swal from "sweetalert2";
import logoNavegam from "@/assets/icons/logo_navegam.svg";
import PoliticaPrivacidade from "../PoliticaPrivacidade";
import TermosUso from "../TermosUso";
// import IconWpp from '@/assets/icons/iconsWhatsapp.svg';

export default {
  props: {},
  components: {
    PoliticaPrivacidade,
    TermosUso
  },
  data() {
    return {
      model: {
        nome: "",
        email: "",
        senha_1: "",
        senha_2: ""
      },
      errors: [],
      email_flag: false,
      email_flag2: false,
      nome_flag: false,
      senha1_flag: false,
      senha1_flag2: false,
      senha2_flag: false,
      senha2_flag2: false,
      logoNavegam,
      flag: false,
      isVisible: false,
      isVisible2: false
      // IconWpp
    };
  },
  methods: {
    formataTelefone(telefone) {
      if (telefone) {
        telefone = telefone
          .split("")
          .filter(Number)
          .join("");
      }

      return telefone;
    },
    openWpp() {
      window.open("https://api.whatsapp.com/send?phone=+559288552157");
    },
    goBack() {
      history.back();
    },
    voltar() {
      this.$router.push("/login");
    },
    hiddeModal() {
      this.$modal.hide("termosUso");
    },
    Modal() {
      this.$modal.show("termosUso");
    },
    afterOpen() {
      var x = document.getElementsByClassName("v--modal-box v--modal")[0];
      if (document.body.offsetWidth < 768) {
        x.style.width = "100%";
        x.style.left = "auto";
      }
    },
    cadastro() {
      if (
        this.$refs.email_input.style.borderColor == "green" &&
        this.$refs.nome_input.style.borderColor == "green" &&
        this.$refs.senha1_input.style.borderColor == "green" &&
        this.$refs.senha2_input.style.borderColor == "green" &&
        this.flag
      ) {
        const config2 = {
          headers: {
            "Content-Type": "application/json"
          }
        };
        const body = JSON.stringify({
          email: this.model.email,
          nome: this.model.nome,
          senha: this.model.senha_1,
          senhaConf: this.model.senha_2
        });

        this.axios
          .post(`${api}/usuarios/cadastro`, body, config2)
          .then(statusCode => {
            if (statusCode.message == "Duplicated values") {
              Swal.fire({
                icon: "error",
                title: "Usuário já está cadastrado !",
                showConfirmButton: false,
                timer: 1500
              });
            }
            if (statusCode.data.message == "Successfully created") {
              Swal.fire({
                icon: "success",
                title: "Cadastrado com sucesso !",
                showConfirmButton: false,
                timer: 1500
              });
              this.$router.push("/login");
            } else {
              Swal.fire({
                icon: "error",
                title: "Erro ao cadastrar !",
                showConfirmButton: false,
                timer: 1500
              });
            }
          })
          .catch(err => {
            if (err.response.data.message == "Duplicated values") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Usuário já está cadastrado !"
              });
            }
          });
      } else {
        if (!this.flag) {
          this.$notify({
            message: "É necessário concordar com os termos de uso.",
            type: "danger",
            timeout: 2000,
            horizontalAlign: "center",
            verticalAlign: "top",
            showClose: false,
            closeOnClick: false
          });
        }
        this.model.email == "" || this.model.email == null
          ? ((this.email_flag = true),
            (this.$refs.email_input.style.borderColor = "red"))
          : (this.email_flag = false);

        this.model.telefone == "" || this.model.telefone == null
          ? ((this.nome_flag = true),
            (this.$refs.nome_input.style.borderColor = "red"))
          : (this.nome_flag = false);

        this.model.senha_1 == "" || this.model.senha_1 == null
          ? ((this.senha1_flag = true),
            (this.$refs.senha1_input.style.borderColor = "red"))
          : (this.senha1_flag = false);

        this.model.senha_2 == "" || this.model.senha_2 == null
          ? ((this.senha2_flag = true),
            (this.$refs.senha2_input.style.borderColor = "red"))
          : (this.senha2_flag = false);
      }
    },
    rotas(rota) {
      this.$router.push(`/${rota}`);
    },
    checkBox() {
      if (this.$refs.checkbox.checked) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    onChangeEmail() {
      this.email_flag = false;
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.$refs.email_input.value
        )
      ) {
        this.$refs.email_input.style.borderColor = "green";
        this.email_flag = false;
        this.email_flag2 = false;
      } else {
        this.$refs.email_input.style.borderColor = "red";
        this.email_flag2 = true;
      }
    },
    onChangeNome() {
      this.nome_flag = false;
      this.model.nome.length > 3
        ? ((this.$refs.nome_input.style.borderColor = "green"),
          (this.nome_flag = false))
        : ((this.$refs.nome_input.style.borderColor = "red"),
          (this.nome_flag = true));
    },
    onChangeSenha1() {
      this.senha1_flag = false;
      if (this.model.senha_1.length < 6) {
        this.senha1_flag2 = true;
      } else if (this.model.senha_1.length >= 6) {
        this.$refs.senha1_input.style.borderColor = "green";
        this.senha1_flag2 = false;
      }
    },
    onChangeSenha2() {
      this.senha2_flag = false;
      this.model.senha_1 != this.model.senha_2
        ? ((this.senha2_flag2 = true),
          (this.$refs.senha2_input.style.borderColor = "red"))
        : ((this.senha2_flag2 = false),
          (this.$refs.senha2_input.style.borderColor = "green"));
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}
.opcoes {
  text-align: center;
  margin-top: 1rem;
  // font-family: 'OpenSans-Regular', sans-serif;
}
.esqueci {
  background-color: transparent;
  margin: 0 auto;
  font-family: "OpenSans-Regular", sans-serif;
  // line-height: 2.5;
  cursor: pointer;
  // padding: -0.7rem 0.6rem;
  border: 0;
  // border-radius: 0.5rem;
  // font-size: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  color: gray;
  vertical-align: middle;
  outline: none;
}
.termos-uso {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5px;
  input {
    width: 20px;
    margin: 0;
    padding: 0;
  }
  label {
    font-family: "OpenSans-Regular", sans-serif;
    font-size: 14px;
  }
}
.nome,
.email,
.telefone,
.senha,
.confirmar-senha {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.iconVisible {
  position: absolute;
  color: #747474;
  font-size: 24px;
  bottom: 0.7rem;
  right: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.logo-navegam {
  display: block;
  width: 160px;
  // padding-bottom: 2px;
  margin-left: 0;
  margin-right: 0;
  margin: auto;
  margin-top: 15px;
}
.buttons {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  margin-top: 1rem;
  font-size: 14px;
  button:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }

  button:active {
    outline: none;
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }

  button:focus {
    outline: none;
  }
}

.cancel-button {
  background-color: transparent;
  border-color: #2a3550;
  border: 1px solid #2a3550;
  color: #2a3550;
  margin-left: 8px;
  border-radius: 4px;
  padding: 0.6rem 0.3rem;
  // width: 25%;
  width: 6rem;
  font-family: "OpenSans-Regular", sans-serif;
  cursor: pointer;
}
.text-header {
  color: white;
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 30px;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.input-email {
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 90%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  /* margin-top: 0.8rem;   */
  padding: 12px 0px 12px 18px;
  border: 1px solid transparent;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}
.input-nome {
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 90%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  margin-top: -0.2rem;
  /* margin-bottom: 1rem; */
  padding: 12px 0px 12px 18px;
  border: 1px solid transparent;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}
.input-senha {
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 90%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  margin-top: -0.2rem;
  padding: 12px 0px 12px 18px;
  border: 1px solid transparent;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}
.input-confirmar-senha {
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 90%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  margin-top: -0.2rem;
  padding: 12px 0px 12px 18px;
  border: 1px solid transparent;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}

.buttons {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.text-form {
  margin-bottom: 0.4rem auto;
  // padding-bottom: 3px;
  // left: 12px;
  padding-bottom: 0.2rem;
  font-size: 0.7em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: red;
}
.div-margin {
  background-color: #2a3550 !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  .card {
    background-color: white;
    width: fit-content;
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 7px;
    margin-bottom: 1rem;

    .card-title {
      text-transform: inherit;
      font-weight: 300;
      // margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 1.5rem;
      font-family: "OpenSans-Regular", sans-serif;
      text-align: center;
      color: #2a3550;
      padding-bottom: 0.2rem;
    }
  }
}

input {
  outline: none;
  // border: 1px solid #1d253b50;
  padding: 0.2rem;
  width: 70%;
}

.button-cadastrar {
  background-color: #009aa0;
  // margin: 10px auto;
  cursor: pointer;
  width: 6rem;
  margin-right: 8px;
  // border: 1px solid transparent;
  padding: 0.6rem 0.3rem;
  border: 1px solid #009aa0;
  font-family: "OpenSans-Regular", sans-serif;
  text-align: center;
  // float: r;
  border-radius: 4px;
  color: white;
  vertical-align: middle;
}

@media only screen and (max-width: 900px) {
  /* .IconWpp {
    display: none;
  } */
  .text-header {
    font-size: 1.4em;
  }
}
</style>