<template>
  <div class="div-margin">
    <p class="text-header">Não se preocupe, ajudaremos você!</p>
    <div class="card">
      <img :src="logoNavegam" class="logo-navegam" />
      <h5 class="card-title">Recuperar senha</h5>
      <div class="row">
        <div class="email">
          <input
            autocomplete="off"
            class="input-email"
            label="Email"
            v-model="model.email"
            placeholder="Email"
          />
        </div>
      </div>
      <div class="buttons">
        <button class="cancel-button" @click="voltar">CANCELAR</button>
        <button class="button-cadastrar" type="info" @click="enviar" fill>
          ENVIAR
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import Swal from "sweetalert2";
import logoNavegam from "@/assets/icons/logo_navegam.svg";

export default {
  props: {},
  data() {
    return {
      model: {
        email: ""
      },
      logoNavegam
    };
  },
  methods: {
    voltar() {
      this.$router.push("/login");
    },
    enviar() {
      if (!(this.model.email.length < 1)) {
        const body = JSON.stringify({
          email: this.model.email
        });
        const config2 = {
          headers: {
            "Content-Type": "application/json"
          }
        };

        this.axios
          .post(`${api}/usuarios/recovery`, body, config2)
          .then(statusCode => {
            if (statusCode.data.success) {
              this.$notify({
                message: "Enviamos uma nova senha para o email",
                type: "success",
                timeout: 4000,
                horizontalAlign: "center",
                verticalAlign: "top",
                showClose: false,
                closeOnClick: false
              });
              this.$router.push("/login");
            } else {
              this.$notify({
                message:
                  "Usuário não encontrado! Verifique se o email está correto.",
                type: "danger",
                timeout: 4000,
                horizontalAlign: "center",
                verticalAlign: "top",
                showClose: false,
                closeOnClick: false
              });
            }
          })
          .catch(error => {
            this.$notify({
              message:
                "Usuário não encontrado! Verifique se o email está correto.",
              type: "danger",
              timeout: 4000,
              horizontalAlign: "center",
              verticalAlign: "top",
              showClose: false,
              closeOnClick: false
            });
          });
      } else {
        this.$notify({
          message:
            "Por favor, informe o email!",
          type: "danger",
          timeout: 4000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

.logo-navegam {
  display: block;
  width: 160px;
  // padding-bottom: 2px;
  margin-left: 0;
  margin-right: 0;
  margin: auto;
  /* margin-top: 15px; */
}

.buttons {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.text-header {
  color: #2a3550;
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 30px;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2vh;
  @media only screen and (max-width: 900px) {
    font-size: 1.5em;
  }
}
.input-email {
  ::placeholder {
    color: rgba(37, 35, 35, 0.472);
  }
  caret-color: #1d253b;
  background-color: rgba(126, 125, 125, 0.178);
  display: block;
  position: relative;
  width: 90%;
  height: 50%;
  // margin: 0.6rem 0.9rem;
  margin-top: -0.2rem;
  padding: 12px 0px 12px 18px;
  border: none;
  // font-weight: 300;
  font-family: "OpenSans-Regular", sans-serif;
  border-radius: 4px;

  animation: blink-caret 0.75s step-end infinite;
}
.div-margin {
  padding-top: 15vh;
  .card {
    background-color: white;
    width: 20%;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1100px) {
      width: 50%;
    }

    @media only screen and (max-width: 550px) {
      width: 80%;
    }

    .card-title {
      text-transform: inherit;
      font-weight: 300;
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 1.5rem;
      font-family: "OpenSans-Regular", sans-serif;
      text-align: center;
      color: #2a3550;
      padding-bottom: 1rem;
    }
  }
}

// input {
//   caret-color: #1d253b;
//   font-family: 'Poppins', sans-serif;

//   display: block;
//   width: 100%;
//   height: 50%;
//   margin: 0.6rem 0.9rem;
//   margin-top: -0.2rem;
//   border: none;
//   font-weight: 300;
//   font-family: Poppins, sans-serif !important;
//   border-radius: 0.25rem;

//   animation: blink-caret 0.75s step-end infinite;
// }
.button-cadastrar {
  background-color: #009aa0;
  // margin: 10px auto;
  cursor: pointer;
  width: 6rem;
  margin-right: 8px;
  // border: none;
  padding: 0.6rem 0.3rem;
  border: 1px solid #009aa0;
  font-family: "OpenSans-Regular", sans-serif;
  text-align: center;
  // float: r;
  border-radius: 4px;
  color: white;
  vertical-align: middle;
}
.cancel-button {
  background-color: transparent;
  border-color: #2a3550;
  border: 1px solid #2a3550;
  color: #2a3550;
  margin-left: 8px;
  border-radius: 4px;
  padding: 0.6rem 0.3rem;
  // width: 25%;
  width: 6rem;
  font-family: "OpenSans-Regular", sans-serif;
  cursor: pointer;
}
input {
  outline: none;
  border: 1px solid #1d253b50;
  padding: 0.2rem;
  width: 70%;
  margin: 0.4rem auto;
}

// .button-cadastrar {
//   display: block;
//   margin: 0 auto;
//   background-color: #1d253b;
//   font-family: 'Poppins', sans-serif;
//   font-weight: 400;
//   padding: 0.3rem 0.6rem;
//   border: 0;
//   border-radius: 0.5rem;
//   font-size: 100%;
//   text-align: center;
//   margin-bottom: 0;
//   cursor: pointer;
//   color: white;
// }

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}
</style>
