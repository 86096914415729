<template>
  <div>
    <div class="entire-div">
      <div class="central-div">
        <div class="central-header">
          <h1 class="titulo">Política de Privacidade - Plataforma NAVEGAM</h1>
        </div>
        <div class="central-body">
          <p>
            A navegam.com.br (doravante "PLATAFORMA NAVEGAM", “NAVEGAM” ou
            "Nós") valoriza seus usuários e está comprometida com a proteção da
            sua privacidade. Para o cumprimento desse compromisso, a NAVEGAM
            desenvolveu essa política de privacidade (doravante, a "Política de
            Privacidade", ou "Política") que descreve as políticas e práticas da
            NAVEGAM no que se refere à recolha, uso, conservação e divulgação
            das informações pessoais compiladas.
          </p>
          <p>
            Quando Você utiliza os serviços da NAVEGAM, como por exemplo para
            realizar buscas, registrar-se para receber ofertas e novidades ou
            contratar algum serviço através de quaisquer de seus canais de venda
            – Centro de Atenção Telefônica, site (doravante, o "Site") ou
            Aplicativo para telefones celulares e tablets (doravante, o
            "Aplicativo") – está confiando-nos informações. Essa Política de
            Privacidade destina-se a ajudar-lhe a entender quais dados
            recolhemos, porque os recolhemos e o que fazemos com eles.
          </p>
          <p>
            Você, enquanto usuário dos serviços, aceita as práticas que se
            detalham a seguir, pelo que recomendamos a leitura atenta das
            informações que vamos proporcionar-lhe.
          </p>
          <p>
            O responsável da base de dados/arquivos no Brasil é a
            Navegam.com.br, domicipado na Rua do Sol, 315, Aleixo, Manaus -
            Amazonas.
          </p>
          <h2 class="subtitle">1. Que informações recolhemos?</h2>
          <p>
            A Navegam recebe e armazena a) informações que Você proporciona, b)
            informações obtidas de terceiros, c) informações públicas, e d)
            informações recolhidas pelos nossos sistemas, conforme descrito a
            seguir:
          </p>
          <p>a) Informações proporcionadas por Você:</p>
          <p>
            Para poder fazer a compra de passagem na Navegam, Você deve
            proporcionar-nos determinadas informações pessoais (doravante, as
            "Informações Pessoais") que incluem nome e sobrenome, número de
            documento ou identificação válida, nacionalidade, informações de
            identificação tributária e informações físicas de contato (como
            número de telefone, domicípo ou endereço de e-mail). Visando
            formalizar a compra de passagem, Você deve também fornecer-nos
            informações sobre o seu cartão de débito ou crédito (tais como o
            número do referido cartão de crédito, o código de segurança, o nome
            do titular do cartão e a data de vencimento). A Navegam poderá
            confirmar as Informações Pessoais proporcionadas, a critério
            exclusivo, através de entidades públicas, empresas especiapzadas ou
            centrais de risco, para o qual Você autoriza-nos expressamente. As
            informações obtidas pela Navegam junto a estas entidades serão
            tratadas de modo confidencial
          </p>
          <p>
            Você sempre poderá decidir não nos fornecer informações, mas em
            geral são necessárias determinadas informações suas para comprar ou
            realizar transações na Navegam. Caso decida não nos fornecer
            determinadas informações, é possível que Você não possa contratar
            nossos serviços.
          </p>
          <p>
            Caso faça uma compra em nome de um terceiro, deverá obter
            previamente o consentimento deste terceiro antes de fornecer-nos
            suas Informações Pessoais. Caso tal terceiro for menor de idade, de
            acordo com a legislação aplicável, Você declara dispor das
            faculdades suficientes para proporcionar tais Informações Pessoais e
            aceitar esta Política de Privacidade
          </p>
          <p>
            Você também pode enviar opiniões, comentários e sugestões
            participando voluntariamente de pesquisas de opinião e/ou
            respondendo às perguntas enviadas através de e-mails, bem como
            através do Site ou do Aplicativo (as "Opiniões"). Nesse caso,
            visando oferecer a outros usuários depoimentos reais sobre
            experiências de serviços, Você aceita e concorda que todas as
            Opiniões que nos envie poderão ser publicadas pelo Site e/ou
            Aplicativo da Navegam, ou em outros Sites e/ou Aplicativos com os
            quais a Navegam tenha relações contratuais. Nesse sentido, Você
            poderá ser identificado através do seu nome completo e/ou apelido,
            foto, comentário e nacionalidade. Ao enviar-nos sua Opinião, ou
            subir fotos reais através do Site, Você cede de modo irrevogável à
            Navegam todos os direitos de propriedade sobre tais fotografias e
            Opiniões. Lembre-se que Você pode enviar sua opinião de maneira
            anônima ou epminá-la, configurando suas preferências nas opções
            "Minha Conta" ou "Minha Reserva".
          </p>
          <p>
            Sempre esteja ciente de que, quando colocar informações em uma área
            pública deste Site (incluindo, sem pmitar-se a, avisos, grupos de
            chats, álbuns de fotografias eletrônicos e comentários sobre os
            produtos e serviços), estará disponibilizando-as para outros
            membros, usuários do Site e público em geral; o que se encontra fora
            do âmbito de controle da Navegam. Lembre-se do mencionado acima e
            tome cuidado com as informações que decidir divulgar.
          </p>
          <p>b) Informações proporcionadas por terceiros</p>
          <p>
            Na extensão permitida por Lei, a Navegam também poderá obter
            Informações Pessoais sobre Você e acrescentá-las às Informações
            Pessoais que Você nos proporcione, tanto de entidades afipadas,
            parceiros comerciais e outras fontes de terceiros independentes,
            quanto de bases de dados públicas, informações recolhidas durante
            uma conversa telefônica junto ao Centro de Atenção ao Cliente e/ou
            através de aplcativos interativos. Esteja ciente de que todas as
            informações que recolhemos sobre Você podem ser combinadas com as
            Informações Pessoais. Todas e quaisquer Informações Pessoais obtidas
            pela Navegam através dos meios aqui descritos serão tratadas em
            conformidade com as disposições desta Política de Privacidade
          </p>
          <p>
            Se Você se registra no Site da Navegam através da sua conta pessoal
            de uma rede social (a "Conta Pessoal"), consente expressamente que a
            Navegam:
          </p>
          <p>
            Tenha acesso, a qualquer momento, à totalidade das informações
            contidas na sua Conta Pessoal, incluindo, particularmente mas sem
            limitar-se à, suas Informações Pessoais, informações sobre seus
            interesses, preferências, contatos e quaisquer conteúdos disponíveis
            na sua Conta Pessoal, como mensagens, fotografias, vídeos e qualquer
            outro tipo de conteúdo. Envie ao e-mail do Usuário vinculado à Conta
            Pessoal ou ao e-mail utilizado para fazer a reserva na Navegam,
            informações ou mensagens com a finalidade descrita na seção 3: "Para
            que utilizamos as informações que recolhemos?", que se encontra
            nesta Política.
          </p>
          <p>c) Informações recolhidas pelos nossos sistemas</p>
          <p>
            A Navegam recolhe e armazena automaticamente determinadas
            informações sobre a atividade dos seus Usuários. Tais informações
            podem incluir a URL da qual provêm (estejam ou não no Site da
            Navegam), a URL que acessam frequentemente, o navegador que utilizam
            e seus endereços IP, bem como os sites visitados e as buscas
            realizadas
          </p>
          <p>
            Em qualquer momento, Você poderá escolher não receber arquivos de
            cookies habilitando seu navegador web para que os recuse ou lhe
            informe antes de aceitá-los. Esteja ciente de que ao recusar-se a
            aceitar cookies, poderá não ter acesso a muitos serviços de viagens
            e ferramentas de planificação oferecidos por este Site.
          </p>
          <h2 class="subtitle">
            2. Qual é a nossa política de cookies e web beacons?
          </h2>
          <p>
            A Navegam informa que o Site ou o Aplicativo utilizam "cookies",
            relacionados a determinadas características ou funções. Os cookies
            são tipos específicos de informações que um site transmite ao disco
            rígido do computador do Usuário, com o objetivo de manter os
            registros. Os cookies podem servir para facilitar o uso de um site,
            ao guardar senhas e preferências enquanto o Usuário navega na
            Internet.
          </p>
          <p>
            Por outro lado, as "web beacons" são imagens inseridas em um site da
            Internet ou e-mail, que podem ser utilizadas para monitorar o
            comportamento de um visitante, assim como armazenar informações
            sobre o endereço IP do usuário, a duração do tempo de interação em
            tal site e o tipo de navegador utilizado, entre outros.
          </p>
          <p>
            Embora a maioria dos navegadores aceitem "cookies" e "web beacons"
            automaticamente, Você pode configurar seu navegador para que não os
            aceite.
          </p>
          <br />
          <p>
            Para desativar os "cookies", deve seguir as seguintes instruções:
          </p>
          <p>
            Internet Explorer:
            <a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">clique aqui</a>
          </p>
          <p>
            Firefox:
            <a
              href="https://support.mozilla.org/pt-BR/kb/impeca-que-sites-armazenem-cookies-e-dados-no-fire"
              >clique aqui</a
            >
          </p>
          <p>
            Opera:
            <a href="http://help.opera.com/Windows/11.60/pt-br/cookies.html"
              >clique aqui</a
            >
          </p>
          <p>
            Safari IPAD:
            <a
              href="https://support.apple.com/pt-br/HT201265"
              >clique aqui</a
            >
          </p>
          <p>
            Safari IPHONE:
            <a
              href="https://support.apple.com/pt-br/HT201265"
            >
              clique aqui</a
            >
          </p>
          <p>
            Chrome:
            <a
              href="https://support.google.com/chrome/answer/95647?hl=pt-br"
              >clique aqui</a
            >
          </p>
          <br />
          <h2 class="subtitle">
            3. Para que utilizamos as informações que recolhemos?
          </h2>
          <p>
            Gerenciar sua compra de passagem, realizar as transações que houver
            iniciado e processar faturas ou demais documentos fiscais;
          </p>
          <p>
            Oferecer-lhe produtos e serviços e responder suas perguntas e
            comentários;
          </p>
          <p>
            Enviar-lhe confirmações e atualizações sobre sua viagem, bem como
            quaisquer informações respectivas relevantes. Estas informações
            poderão ser enviadas via e-mail, SMS ou WhatsApp ao telefone celular
            que Você tiver informado. Estas mensagens poderão conter (sem
            limitar-se a) informações sobre os serviços da Navegam, bem como
            dados de contato de prestadores de serviços que poderiam auxiliar a
            sua experiência de viagem no destino.
          </p>
          <p>
            Entrar em contato com Você para fins de prestar serviços ao cliente,
            realizar pesquisas de opinião, estatísticas ou análises sobre
            hábitos de consumo ou preferências, notificar-lhe por e-mail sobre
            as ofertas especiais e os produtos e serviços relacionados às
            viagens que poderiam ser de seu interesse, exceto que você não
            queira.
          </p>
          <p>
            Com o propósito de oferecer melhores serviços ou proporcionar aos
            Usuários informações relativas às suas preferências, a Navegam
            também poderá desenvolver estudos internos sobre os interesses,
            comportamentos e demografia dos usuários, para compreender melhor
            suas necessidades e interesses, melhorar nossas iniciativas
            comerciais e promocionais, personalizar seus conteúdos, sua
            apresentação e serviços, exibir publicidade ou promoções e banners
            que sejam interessantes para os nossos usuários.
          </p>
          <p>
            Do mesmo modo, a Navegam utiliza as informações dos cartões de
            débito ou crédito (tais como o nome do titular do cartão, número do
            cartão e data de vencimento) somente com o propósito de completar as
            compra das passagens que Você fizer, incluindo o envio dos seus
            dados aos prestadores finais de serviços, para gerenciar suas
            reservas e/ou solicitações de compra. Você também pode escolher que
            a Navegam armazene determinados dados do seu cartão e faturas, que
            aparecerão automaticamente ao entrar no Site e/ou no Aplicativo da
            Navegam.
          </p>
          <h2 class="subtitle">
            4. Com quem compartilhamos as informações recolhidas?
          </h2>
          <p>
            Você expressa seu consentimento inequívoco para que a Navegam possa
            compartilhar as Informações Pessoais relevantes dos usuários com
            prestadores de serviços para a gestão das suas reservas e/ou
            solicitações de compra, tais como pnhas aéreas, hotéis, empresas
            turísticas que prestam serviços no destino, empresas de aluguel de
            veículos, distribuidores e outros prestadores de produtos e serviços
            que Você contratar através do nosso Site e/ou nosso Aplicativo. Do
            mesmo modo, Você aceita compartilhar suas Informações Pessoais com
            prestadores de serviços para a Navegam, incluindo-se cartões de
            crédito, analítica comercial, serviço de atenção ao cliente,
            marketing e prevenção de fraudes.
          </p>
          <p>
            A Navegam poderia autorizar prestadores de serviços a recolher
            Informações Pessoais em nosso nome, incluindo, conforme necessário
            para operar determinados elementos do nosso Site e/ou Aplicativo, ou
            para facilitar a divulgação de publicidade on-line adaptada aos seus
            interesses. Além disso, a Navegam poderá compartilhar Informações
            Pessoais com parceiros comerciais, com os quais poderíamos oferecer
            produtos ou serviços em conjunto. Estes prestadores de serviços e
            parceiros comerciais estão sujeitos a rigorosos contratos de
            confidencialidade que proíbem a utilização ou divulgação não
            autorizada das Informações Pessoais às quais têm acesso. A Navegam
            também poderá compartilhar suas Informações Pessoais com a
            finalidade de cumprir a normativa aplicável e cooperar com as
            autoridades competentes.
          </p>
          <p>
            Além disso, a Navegam poderá compartilhar informações pessoais com
            parceiros comerciais, com os quais poderá oferecer produtos ou
            serviços em conjunto. Neste sentido, ao aceitar os termos desta
            política de privacidade, você reconhece e concorda que a Navegam, na
            condição de controlador de dados nos termos da legislação aplicável,
            poderá compartilhar com instituições financeiras e adquirentes, seus
            dados pessoais e transacionais, incluindo seus dados cadastrais e os
            dados de suas reservas e/ou solicitações de compra para que,
            conjuntamente, possam oferecer produtos e serviços adequados ao seu
            perfil e, ainda possam atribuir maior segurança e prevenir fraudes e
            realizar análises de risco de crédito e aperfeiçoar o atendimento e
            os produtos e serviços prestados, sempre com a estrita observância
            dos princípios e finalidades legais.
          </p>
          <p>
            Finalmente, a Navegam poderá igualmente compartilhar suas
            Informações Pessoais com as empresas pertencentes ao grupo
            Navegam.com.br. Caso houver transferência de dados pessoais,
            informamos que o receptor de tais dados pessoais assumirá as mesmas
            obrigações correspondentes ao responsável que transfere esses dados
            pessoais.
          </p>
          <p>
            Esteja ciente de que, caso Você proporcionar, de maneira livre e
            voluntária e por iniciativa própria, informações diretamente aos
            prestadores de serviços (sejam ou não prestadores de serviços para a
            Navegam), tais prestadores recolherão, tratarão e conservarão estas
            informações de acordo com suas próprias Políticas de Privacidade. A
            Navegam não é responsável pelo eventual uso indevido das Informações
            Pessoais de parte de empresas externas fora do site ou do
            aplicativo.
          </p>
          <h2 class="subtitle">
            5. Onde armazenamos e como protegemos as informações recolhidas?
          </h2>
          <p>
            Todas a Informações Pessoais são recolhidas e armazenadas em
            servidores localizados fisicamente no Brasil. A Navegam poderá
            futuramente realocar tais servidores em qualquer outro país, e
            poderá armazenar as Informações Pessoais no Brasil ou em outros
            países, com fins de respaldo ou back-up. Você expressa seu
            consentimento inequívoco para que a Navegam possa transferir seus
            dados com destino a qualquer país do mundo. Em qualquer caso, a
            Navegam compromete-se a garantir que se cumpram as normas legalmente
            exigíveis para a proteção e resguardo das suas Informações Pessoais,
            mediante a assinatura de acordos ou convênios cujo objeto seja a
            privacidade dos seus dados pessoais.
          </p>
          <p>
            As Informações Pessoais serão tratadas com o nível de proteção
            legalmente exigível para garantir sua segurança e evitar alterações,
            perdas, tratamentos ou acessos não autorizados
          </p>
          <p>
            A Navegam resguarda suas Informações Pessoais de acordo com os
            padrões e procedimentos de segurança e confidencialidade vigentes no
            Brasil conforme inciso X do artigo 5º da Constituição Federal,
            artigo 43 da Lei 8078/90, Lei 12.737/2012 e legislação correlata. A
            Navega, não transmite, divulga ou proporciona as Informações
            Pessoais recolhidas a terceiros que não forem o titular de tais
            Informações Pessoais e/ou os terceiros descritos na presente
            Política. Nesse sentido, este Site e o Aplicativo tomam as medidas
            necessárias para proteger as Informações Pessoais dos usuários
          </p>
          <h2 class="subtitle">
            6. Como pode ser feito o acesso, eliminação e/ou atualização das
            informações recolhidas?
          </h2>
          <p class="subtopic">6.1. Política de Renúncia (opt-out)</p>
          <p>
            Quando Você realizar transações ou registrar-se como usuário no Site
            da Navegam, lhe daremos a opção de receber boletins promocionais,
            mensagens ou alertas de e-mail sobre ofertas. Em cada mensagem de
            e-mail enviada, também lhe daremos a oportunidade de cancelar sua
            subscrição. Você poderá modificar suas escolhas a qualquer momento,
            seguindo qualquer das alternativas explicadas posteriormente
            (Direitos de Acesso, cancelamento, retificação e oposição das
            Informações Pessoais).
          </p>
          <p>
            Esteja ciente de que o exercício do direito de opt-out refere-se aos
            boletins promocionais, mensagens ou alertas de e-mail sobre ofertas.
            Nesse sentido, Você continuará recebendo comunicações referentes ao
            estado das suas reservas ativas. Em determinados casos, inclusive, a
            Navegam deverá manter nos seus arquivos determinadas Informações
            Pessoais, com a finalidade de resolver disputas ou reclamações,
            detectar problemas ou incidências e solucioná-los, e cumprir as
            disposições dos Termos e Condições Gerais por um período determinado
            pela legislação. Em qualquer caso, as Informações Pessoais de um
            usuário não serão retiradas imediatamente dos nossos arquivos por
            motivos legais e técnicos, incluindo-se os sistemas de suporte de
            segurança. Portanto, embora a Navegam comprometa-se a empregar seus
            melhores esforços considerando-se o estado da técnica, poderá
            ocorrer que nem todas as Informações Pessoais sejam definitivamente
            eliminadas.
          </p>
          <p class="subtopic">
            6.2. Direitos de acesso, cancelamento, retificação e oposição
            referentes às informações pessoais
          </p>
          <p>
            No Brasil, os usuários, titulares das Informações Pessoais têm
            reconhecidos e poderão exercer os direitos de acesso, cancelamento e
            atualização das suas Informações Pessoais, bem como a opor-se ao
            tratamento das mesmas e a ser informados sobre as cessões
            realizadas, de forma gratuita em intervalos não inferiores a seis
            meses, salvo que se comprove um interesse legítimo ao efeito
            conforme o estabelecido artigo 43 da Lei 8078/90
          </p>
          <p>
            Os usuários garantem e respondem, em qualquer caso, sobre a
            veracidade, exatidão, vigência e autenticidade das Informações
            Pessoais fornecidas, comprometendo-se a mantê-las devidamente
            atualizadas.
          </p>
          <p class="subtopic">
            6.3. Como exercer os direitos descritos anteriormente?
          </p>
          <p>Você pode exercê-los através de qualquer das opções a seguir:</p>
          <p>
            Apresentando a devida solicitação na Rua do Sol, 315, Aleixo, Manaus
            - Amazonas, ou enviando-a por e-mail ao seguinte endereço:
            navegam@navegam.com.br. Em sua solicitação, deverá informar seu nome
            completo e, conforme o caso, documentos que comprovem a
            representação legal do titular, assim como anexar uma cópia simples
            do seu documento nacional de identidade, informar seu e-mail e
            endereço postal para notificações e um número de telefone para
            contato; e descrever clara e precisamente os dados pessoais sobre os
            quais deseja exercer o direito de acesso, retificação e
            cancelamento, e os documentos que embasem tal alteração. Uma vez
            cumpridos os requisitos detalhados acima, e sempre que a solicitação
            proceda, a Navegam lhe comunicará se encaminhou a mesma ou se a
            nega. A Navegam dispõe de 10 (dez) dias úteis a partir do
            recebimento da solicitação para enviar uma resposta, caso trate-se
            de uma solicitação de acesso, e 5 (cinco) dias úteis se Você tiver
            solicitado a retificação, atualização ou eliminação das suas
            Informações Pessoais. Você também pode acessar, atualizar e corrigir
            suas informações de registro a qualquer momento entrando em "Meu
            Perfil", em "Meu Perfil", no Site da navegam.com.br Entrando em
            "E-mails e Alertas" em " Meu Perfil ", Você poderá escolher quais
            e-mails deseja receber.
          </p>
          <p>Como a Navegam vincula-se com as suas redes sociais?</p>
          <p>
            Ao registrar-se na Navegam, é necessária a escolha de uma
            identificação de usuário e uma senha (ou seja, acessar uma conta
            pessoal no Site). Do mesmo modo, a Navegam permite que Você acesse a
            seção "Meu perfil" através da sua conta de Facebook, de Google+ ou
            outras redes sociais (doravante, as "Redes Sociais", ou "Rede
            Social") que futuramente tenham compatibilidade com o acesso à "Meu
            perfil". Como parte da funcionalidade do Site, e para possibilitar
            uma melhor experiência personalizada, Você pode vincular a sua conta
            às Redes Sociais de duas formas:
          </p>
          <p>
            Proporcionando as informações de login da sua conta na Rede Social
            através do Site Web e/ou o Aplicativo; ou permitindo que a Navegam
            acesse a sua conta nas Redes Sociais, de acordo com o permitido nos
            termos e condições aplicáveis que regem o uso que Você faz de cada
            Rede Social. Você declara que tem direito de revelar as informações
            de login da sua Rede Social à Navegam e/ou conceder à Navegam o
            acesso à sua conta, sem que isso suponha um incumprimento da sua
            parte de nenhum dos termos e condições que regem o uso que Você faz
            da correspondente Rede Social, e sem que a Navegam esteja obrigada a
            pagar nenhuma taxa ou a respeitar nenhuma limitação de uso imposta
            pelos prestadores de serviços externos da Rede Social. Devido ao
            fato de conceder à Navegam o acesso a qualquer Rede Social, Você
            entende que a Navegam acessará, disponibilizará e armazenará (caso
            proceda) todos os conteúdos que Você houver proporcionado e
            armazenado na sua Rede Social, para que estejam disponíveis no Site
            e/ou o Aplicativo e através deles, bem como através da sua conta de
            Rede Social e da página de perfil de tal conta. Salvo que se
            especifique o contrário nestes Termos, todos os conteúdos das Redes
            Sociais, caso houvesse, serão considerados conteúdos de usuários
            para todos os efeitos dos presentes Termos.
          </p>
          <p>
            Em função das Redes Sociais que Você escolher, e sujeitas às
            configurações de privacidade que houver estabelecido em tais contas,
            as informações de identificação pessoal que pubpcar em suas Redes
            Sociais estarão disponíveis na sua conta da Navegam e através dela
            no Site e/ou o Aplicativo. Você deve estar ciente de que, se uma
            Rede Social deixar de estar disponível, ou se o prestador de
            serviços externo bloquear o acesso da Navegam a esta Rede Social, os
            conteúdos das Redes Sociais deixarão de estar disponíveis no Site
            e/ou o Aplicativo ou através deles.
          </p>
          <p>
            ESTEJA CIENTE DE QUE SUA RELAÇÃO COM OS PRESTADORES DE SERVIÇOS
            EXTERNOS REGE-SE EXCLUSIVAMENTE PELOS ACORDOS QUE TENHA SUBSCRITO
            COM TAIS PRESTADORES DE SERVIÇOS EXTERNOS. A Navegam não revisará os
            conteúdos das contas nas Redes Sociais, como a veracidade,
            legalidade ou inexistência de infrações, entre outros, por nenhum
            motivo, e não assume nenhuma responsabilidade por nenhum tipo de
            conteúdo apresentado nas Redes Sociais. Deste modo, é mais fácil
            personalizar nosso site de acordo com as suas preferências,
            conectar-se com seus amigos para intercambiar informações sobre os
            destinos e analisar e melhorar nossos serviços de viagens
          </p>
          <p>
            Se Você perder o controle da sua conta ou senha de acesso às Redes
            Sociais, poderá perder o controle sobre as suas Informações Pessoais
            e poderia estar sujeito a transações legalmente válidas realizadas
            em seu nome. Portanto, se por qualquer razão sua senha estivesse
            comprometida, Você deverá imediatamente: (i) trocá-la, modificando
            suas informações de registro proporcionadas a este Site, e (ii)
            entrar em contato conosco através de qualquer canal oferecido pela
            Navegam.
          </p>
          <p>
            Este Site pode conter vínculos (links) a outros sites que têm suas
            próprias políticas de privacidade e confidencialidade. Por esse
            motivo, recomendamos que, se Você acessar estes outros sites, revise
            cuidadosamente as respectivas práticas e políticas de
            confidencialidade, sempre que esta Política de Privacidade não
            englobar práticas ou políticas de terceiros, incluindo os que possam
            revelar e/ou compartilhar informações com a Navegam.
          </p>
          <p>
            Esta Política foi modificada e atualizada no dia 25 de maio de 2020.
            A Navegam poderá realizar futuras alterações nesta Política de
            Privacidade. Quaisquer modificações no modo através do qual a
            Navegam utiliza as Informações Pessoais serão incorporadas nas
            versões futuras desta "Política de Privacidade" e serão publicadas
            neste site, de modo que o aconselhamos a revisar periodicamente a
            Política de Privacidade.
          </p>
          <p>
            Para quaisquer perguntas sobre esta Política de Privacidade, as
            práticas da Navegam ou suas transações no Site da Navegam
            www.navegam.com.br, entre em contato conosco em "Minha Conta" ou
            "Minhas Passagens".
          </p>
        </div>
      </div>
      <div></div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import EsqueciSenha from "./Profile/ForgotPassword";
import navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default {
  components: {
    EsqueciSenha,
    navbar,
    Footer
  },
  data() {
    return {
      pubpcPath: process.env.VUE_APP_URL
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    }
  }
};
</script>

<!-- Add "scoped" attribute to pmit CSS to this component only -->
<style lang="scss" scoped>
.entire-div {
  position: relative;
  background-color: #f0f2f5;
  margin: 0 auto;
  width: 80%;
  height: 100%;
  overflow: auto;
  // min-height: auto;
  padding-bottom: 8vh; /* Footer height */
  display: block;
  justify-content: center;
  z-index: 0;
}
.poptica {
  text-align: center;
  margin: auto;
}
.btn2-central2 {
  // margin-right: 8px;
  background-color: #009aa0;
  border: none;
  outline: none;
  padding: 8px 12px;
  color: white;
  border-radius: 4px;
  width: 150px;
  position: absolute;
  left: 42%;
  cursor: pointer;

  &:hover {
    transform: translate(0px, -1px);
    box-shadow: 0px 1px 10px 0px #00000011;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
  &:active {
    transform: translate(0px, 1px);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  }
}
.central-body {
  background: transparent;
  padding: 20px;
  margin: auto;
  width: 90%;
  margin-bottom: 8px;
  border-radius: 5px;
}
.central-div {
  padding-top: 12vh;
  padding-bottom: 1rem;
  margin: 0 auto;
  display: block;
  align-items: center;
  font-family: "Roboto", sans-serif;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;

  .central-header {
    display: block;
    /* margin-bottom: 2rem; */
    margin-top: 2rem;
    overflow-y: auto;

    .titulo {
      text-align: center;
      margin-bottom: 0.5rem;
      color: #2a3550;
      font-size: 32px;
      font-family: "Roboto", sans-serif;
      font-weight: 700;
    }
  }
  p {
    margin-top: 1rem;
    color: #626262;
    text-align: justify;
    line-height: 1.5em;
    letter-spacing: 0.8px;
  }
  .subtitle {
    color: #2a3550;
    margin-top: 6vh;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  .subtopic {
    color: #2a3550;
    margin-top: 5vh;
    text-indent: initial;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
}
</style>
